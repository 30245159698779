import { createContext, FC, ReactElement } from 'react';
import { store } from '@/store';
import { Provider } from 'react-redux';

interface Props {
  children: ReactElement;
}

const ControlsContext = createContext<any>(null!);

const GlobalContext: FC<Props> = ({ children }) => {
  return (
    <Provider store={store}>
      <ControlsContext.Provider value={{ controls: null }}>{children}</ControlsContext.Provider>
    </Provider>
  );
};

export { GlobalContext, ControlsContext };

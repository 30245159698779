import { FC, useEffect, useState } from 'react';
import Popin from '@/components/Popin/Popin';
import { useNavigate } from 'react-router-dom';
import {useApi} from '@/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { setCurrentProjectCode, setCurrentProjectId } from '@/store/user.slice'
import * as S from '@/components';

const SaveProject: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const [description, setDescription] = useState<string>('');
  const { saveProject, createProject, fetchProjects } = useApi();
  const currentProjectId = useSelector((state: RootState) => state.user.currentProjectId);
  const config = useSelector((state: RootState) => state.config);
  const volume = useSelector((state: RootState) => state.volume);
  const projects = useSelector((state: RootState) => state.user.projects);
  const dispatch = useDispatch();

  const save = () => {
    const project = projects.find((project: any) => project.id === currentProjectId);
    if (project) {
      saveProject({
        description,
        id: currentProjectId,
        parameters: { config, volume },
      });
    } else {
      createProject({ description, parameters: { config, volume } }).then((res: any) => {
        dispatch(setCurrentProjectId(res.data.id));
        dispatch(setCurrentProjectCode(res.data.code));
        fetchProjects();
      });
    }
    setIsVisible(false);
  };

  useEffect(() => {
    const project = projects.find((project: any) => project.id === currentProjectId);
    if (project) {
      setDescription(project.description);
    }
  }, [projects, currentProjectId]);

  return (
    <Popin
      width={400}
      isShowing={isVisible}
      hide={() => setIsVisible(false)}
      onHide={() => navigate('/configurator')}
    >
      <div className={'px-6 pt-14 pb-8'}>
        <div className={'flex flex-col gap-y-lg'}>
          <div className={'flex items-center justify-center gap-x-md'}>
            <S.Heading Tag={'h3'} size={'xl'} weight={'bold'} color={'secondary'}>
              Save my project
            </S.Heading>
          </div>
          <textarea
            className={'h-[300px] border border-gray-dark p-sm text-md'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={"Your project's description"}
          />
          <div className={'flex justify-center'}>
            <S.Button variant={'primary'} fill content={'Save'} onClick={() => save()} />
          </div>
        </div>
      </div>
    </Popin>
  );
};

export default SaveProject;

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useContext, createContext } from 'react';
import { useGLTF, Merged, useAnimations } from '@react-three/drei';

const context = createContext();
export function Instances({ children, ...props }) {
  const { nodes } = useGLTF('/assets/models/MUB-E/800-114.3/E_BOT_A-800-114.3-transformed.glb');
  const instances = useMemo(
    () => ({
      Mesh: nodes.Mesh_14,
      Mesh1: nodes.Mesh_14_1,
      Mesh2: nodes.Mesh_14_2,
      Mesh3: nodes.Mesh_14_3,
      Mesh4: nodes.Mesh_14_4,
      Mesh5: nodes.Mesh_14_5,
      Mesh6: nodes.Mesh_14_6,
      Mesh7: nodes.Mesh_14_7,
      Mesh8: nodes.Mesh_14_8,
    }),
    [nodes],
  );
  return (
    <Merged meshes={instances} {...props}>
      {(instances) => <context.Provider value={instances} children={children} />}
    </Merged>
  );
}

export function Model(props) {
  const instances = useContext(context);
  const group = useRef();

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Corps019">
          <instances.Mesh name="Mesh_14" />
          <instances.Mesh1 name="Mesh_14_1" />
          <instances.Mesh2 name="Mesh_14_2" />
          <instances.Mesh3 name="Mesh_14_3" />
          <instances.Mesh4 name="Mesh_14_4" />
          <instances.Mesh5 name="Mesh_14_5" />
          <instances.Mesh6 name="Mesh_14_6" />
          <instances.Mesh7 name="Mesh_14_7" />
          <instances.Mesh8 name="Mesh_14_8" />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/assets/models/MUB-E/800-114.3/E_BOT_A-800-114.3-transformed.glb');

import { useApi, useLoadProject } from '@/hooks/index'
import { useEffect } from 'react'

export const useLoadConfigFromUrlQuery = () => {
    const { getProjectByCode } = useApi();
    const { loadProject } = useLoadProject()
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if(code && code !== 'null') {
            getProjectByCode({ code }).then((res) => {
                loadProject(res.data);
            })
        }
    })
}

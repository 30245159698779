import Module from '@/components/Sidebar/Modules/Module';
import { ChangeEvent, FC, useState } from 'react';
import Select from '@/components/Base/Select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { setHasChanged, setTrays } from '@/store/config.slice';
import { InputUnit } from '@/components/Base/Input';
import { number, object } from 'yup';
import { Formik } from 'formik';
import Popover from '@/components/Base/Popover';
import Icon from '@/components/Base/Icon';

const TraysModule: FC = () => {
  const dispatch = useDispatch();
  const [isValidated] = useState<boolean>(false);

  const { x, y } = useSelector((state: RootState) => state.config.trays);
  const hasChanged = useSelector((state: RootState) => state.config.hasChanged);

  return (
    <Module
      name={'Trays'}
      validated={isValidated}
      options={

        <div className={'relative mt-4 flex justify-center pt-[50px]'}>
          <Formik
            enableReinitialize
            initialValues={{
              x,
              y,
            }}
            validationSchema={object().shape({
              x: number().min(450).max(2400).required(),
              y: number().required(),
            })}
            onSubmit={(values) => {
              dispatch(setTrays({ x: values.x, y: values.y }));
            }}
          >
            {({ values, handleChange, submitForm, errors, validateForm }: any) => (
              <>
                <div className={`absolute -top-2 left-1/2 -translate-x-1/2 transform ${hasChanged ? 'animate-pulse' : ''}`}>
                  <InputUnit
                    customClassName={
                      `bg-misc-yellow-light 
                      ${errors.x ? 'outline outline-1 outline-red-600 text-red-600 ' : ''}
                      ${hasChanged ? 'outline outline-1 outline-yellow-600 text-yellow-600 ' : ''}
                    }`}
                    autoscaling={true}
                    value={values.x}
                    unit={'mm'}
                    small
                    min={450}
                    max={2400}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      dispatch(setHasChanged(false));
                      handleChange({
                        target: {
                          name: 'x',
                          value: e.target.value ? parseFloat(e.target.value) : 0,
                        },
                      });
                      validateForm().then(submitForm);
                    }}
                  />
                  {hasChanged &&
                    <div className="absolute -right-xs top-1/2 text-yellow-600 transform -translate-y-1/2 translate-x-full">
                      <Popover
                        customClassName={'bg-yellow-500 text-white p-sm text-md'}
                        placement={'right'}
                        content={'This value may have changed because of auto-fitting'}
                      >
                        <Icon name={'warning'} size={24}  />
                      </Popover>
                    </div>
                  }
                </div>

                <div className={`absolute right-0 top-[24px] z-10 transform`}>
                  <span className={'text-sm'}>up to</span>
                  <Select
                    value={values.y}
                    small
                    options={[
                      {
                        label: '800  mm',
                        value: 800,
                      },
                      {
                        label: '1330  mm',
                        value: 1330,
                      },
                    ]}
                    onChange={(option) => {
                      handleChange({
                        target: { name: 'y', value: option.value },
                      });
                      validateForm().then(submitForm);
                    }}
                  />
                </div>
              </>
            )}
          </Formik>
          <div className={'skew-x-[50deg] transform'}>
            <div className={'mb-2 h-[2px] w-full bg-yellow-300'} />
            <div className={'flex'}>
              <div
                className={
                  'h-[40px] w-[180px] border-2 border-dashed border-primary-base bg-gray-dark'
                }
              />
              <div className={'ml-4 w-[3px] flex-1 bg-gray-darker'} />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default TraysModule;

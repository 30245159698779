import { Box } from '@react-three/drei';
import { FC, Fragment, useMemo, useRef } from 'react';
import { Align, Element } from '@/components/Webgl/Utils';
import { Group, MathUtils, MeshStandardMaterial } from 'three';
import {usePainter} from '@/hooks/beautify/';

interface Props {
  length: number;
  width: number;
}

const SUPPORT: FC<Props> = ({ length, width }) => {
  const groupRef = useRef<Group>(null!);

  const whiteMaterial = useMemo(() => new MeshStandardMaterial({ color: 'white' }), []);

  const { paint } = usePainter();

  const config = useMemo(
    () => ({
      width: width - 0.15,
      length,
      pillars: 4,
      beams: 6,
    }),
    [length, width],
  );

  paint(groupRef, [
    {
      name: ['', 'Material_2.001'],
      material: new MeshStandardMaterial({
        color: '#808080',
        roughness: 0,
        metalness: 0.3,
      }),
    },
  ]);

  return (
    <group ref={groupRef}>
      <group position={[0.15, 0.8, -1.85]}>
        {[...Array(config.beams)].map((e, i) => (
          <Fragment key={i}>
            <Align alignement={[0, 0, -1]}>
              <group position={[(i * (config.length - 0.3)) / (config.beams - 1), -1.05, 2]}>
                <Box args={[0.15, 0.15, config.width - 0.3]} position={[0, 0, -0.15]} />
                <Align alignement={[0, 0, -1]}>
                  <group>
                    {[...Array(config.pillars)].map((e, i) => (
                      <Fragment key={i}>
                        <Box
                          position={[0, 0.2, (i * (config.width - 0.15)) / (config.pillars - 1)]}
                          args={[0.3, 0.01, 0.3]}
                        />
                        <Box
                          position={[0, 0, (i * (config.width - 0.15)) / (config.pillars - 1)]}
                          args={[0.15, 0.4, 0.15]}
                        />
                        <Box
                          position={[0, -0.2, (i * (config.width - 0.15)) / (config.pillars - 1)]}
                          args={[0.3, 0.01, 0.3]}
                        />
                      </Fragment>
                    ))}
                  </group>
                </Align>
              </group>
            </Align>
          </Fragment>
        ))}
      </group>
      <group position={[-0.75, 0.5, -0.7]} rotation={[0, MathUtils.degToRad(180), 0]}>
        <Element url={'/MUB-S/ENCEINTE/STAIRCASE'} />
      </group>
      <Align alignement={[1, 0, -1]}>
        <Box
          args={[config.length, 0.12, config.width + 0.15]}
          material={whiteMaterial}
        />
      </Align>
    </group>
  );
};

export default SUPPORT;

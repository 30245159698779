import * as S from '@/components';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFullscreen } from '@/store/ui.slice';
import { setIsVolumeVisible } from '@/store/volume.slice';
import { setIsDimensionsVisible, setIsEnclosureVisible } from '@/store/misc.slice';

import { FC, useContext, useEffect, useState } from 'react';
import Popover from '@/components/Base/Popover';
import { RootState } from '@/store';
import { ControlsContext } from '@/components/Utils/GlobalContext';

const Controls: FC = () => {
  const dispatch = useDispatch();

  const controlsContext = useContext(ControlsContext);

  const { isVisible, isOutOfBounds } = useSelector((state: RootState) => state.volume);
  const { isEnclosureVisible, isDimensionsVisible } = useSelector((state: RootState) => state.misc);
  const { process } = useSelector((state: RootState) => state.config);
  const {token, demo} = useSelector((state: RootState) => state.user);

  const [isWarningOpen, setIsWarningOpen] = useState(false);

  const resetControls = () => {
    controlsContext.resetCamera();
  };

  useEffect(() => {
    if (isOutOfBounds) {
      showOutOfBoundsWarning();
    }
  }, [isOutOfBounds]);

  const showOutOfBoundsWarning = () => {
    setIsWarningOpen(true);
    setTimeout(() => setIsWarningOpen(false), 3000);
  };

  return (
    <div className={'flex flex-col items-end gap-y-md'}>
      <Popover delay={500} placement={'left'} content={"Reset camera's position"}>
        <S.Button content={'Reset'} icon={'reset'} split={false} onClick={resetControls} shadow />
      </Popover>
      <div className={'hidden lg:block'}>
        <Popover delay={500} placement={'left'} content={'Go fullscreen'}>
          <S.Button
            onClick={() => dispatch(toggleFullscreen())}
            icon={'fullscreen'}
            rounded={true}
            shadow
          />
        </Popover>
      </div>

      {isOutOfBounds &&
        <Popover
          placement={'left'}
          content={'This configuration is too large for the current volume'}
          customClassName={'bg-red-600 p-xs text-white max-w-[180px]'}
          isOpen={isWarningOpen}
        >
          <div className={'animate-pulse'}>
            <S.Button
              variant={'danger'}
              onClick={() => dispatch(setIsVolumeVisible(!isVisible))}
              icon={'3d'}
              rounded={true}
              shadow
            />
          </div>
        </Popover>
      }
      {!isOutOfBounds &&
        <Popover
          delay={500}
          placement={'left'}
          content={'Show volume'}
        >
          <div >
            <S.Button
              onClick={() => dispatch(setIsVolumeVisible(!isVisible))}
              icon={'3d'}
              rounded={true}
              shadow
            />
          </div>
        </Popover>
      }

      {(token || demo) && (
        <Popover delay={500} placement={'left'} content={'Show dimensions'}>
          <S.Button
            onClick={() => dispatch(setIsDimensionsVisible(!isDimensionsVisible))}
            icon={'presentation'}
            rounded={true}
            shadow
          />
        </Popover>
      )}
      {process !== 'ambiant cooling' && (
        <Popover 
          delay={500} 
          placement={'left'} 
          content={isEnclosureVisible ? 'Hide enclosure' : 'Show enclosure'}
        >
          <S.Button
            onClick={() => dispatch(setIsEnclosureVisible(!isEnclosureVisible))}
            icon={isEnclosureVisible ? 'eye-closed' : 'eye'}
            rounded={true}
            shadow
          />
        </Popover>
      )}
    </div>
  );
};

export default Controls;

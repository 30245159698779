import { FC } from 'react';
import * as S from '@/components';

import resolveConfig from 'tailwindcss/resolveConfig'
import twConfig from '@/styles/tailwind'

interface Props {
  name: string;
  description?: string;
  image: string;
  color: string;
  selected: boolean;
  onClick: () => void;
}

const RadioOption: FC<Props> = ({ name, description, selected, image, color, onClick }) => {
  
  const tw = resolveConfig(twConfig) as Record<string, any>

  return (
    <div onClick={onClick} className={'group relative w-full cursor-pointer'}>
      <div
        style={{ borderColor: selected ? color : tw.theme.colors.gray.dark, borderWidth: 2  }}
        className={'absolute inset-0 transition group-hover:border-2'}
      />
      <div
        style={{ opacity: selected ? 0 : 0.5 }}
        className={'absolute inset-0 bg-white transition group-hover:opacity-0'}
      />
      <div className={'flex flex-col items-center justify-center gap-sm py-sm px-sm'}>
        <img className={'h-[55px] w-[55px] object-contain	'} src={image} alt={'Option Logo'} />
        <div className={'flex flex-col items-center gap-y-2xs'}>
          <S.Heading Tag={'h3'} weight={selected ? 'semibold' : 'normal'} size={'lg'}>
            <span style={{ color: color }}>{name}</span>
          </S.Heading>
          {description && (
            <S.Text align={'center'} size={'sm'}>
              {description}
            </S.Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default RadioOption;

import { Element } from '@/components/Webgl/Utils';
import { Group, MathUtils, Vector2 } from 'three';
import { FC, useEffect, useMemo, useRef } from 'react';
import {usePainter} from '@/hooks/beautify';

interface Props {
  height: number;
  length: number;
  solo: boolean;
}

const CLIM: FC<Props> = ({ height, length, solo }) => {
  const groupRef = useRef<Group>(null!);

  const { paint } = usePainter();

  useEffect(() => {
    paint(groupRef, [
      {
        name: 'all',
        options: {
          roughness: 0,
          metalness: 0.5,
          normalScale: new Vector2(0.005, 0.005),
        },
      },
    ]);
  });

  const spacing = {
    length: 0.2,
    height: 0.02,
  };

  const { climHeight, climLength } = useMemo(
    () => ({
      climHeight: () => {
        const trueHeight = height * 2 + 4;
        if (trueHeight <= 26 && trueHeight >= 24) return 7;
        if (trueHeight <= 22 && trueHeight >= 20) return 6;
        if (trueHeight <= 18 && trueHeight >= 16) return 5;
        if (trueHeight <= 14 && trueHeight >= 12) return 4;
        if (trueHeight <= 10 && trueHeight >= 8) return 3;
      },
      climLength: !solo ? Math.floor(length / (2.2 + spacing.length)) : 1,
    }),
    [height, length, spacing.length, solo],
  );

  return (
    <group ref={groupRef}>
      {[...Array(climHeight())].map((e, i) =>
        [...Array(climLength)].map((e, j) => (
          <Element
            url={'/MUB-S/ENCEINTE/CLIM'}
            position={[j * (1.9 + spacing.length), i * (0.92 + spacing.height) - 0.19, 0]}
            rotation={[0, MathUtils.degToRad(180), 0]}
            key={j}
          />
        )),
      )}
      {[...Array(climLength)].map((e, j) => (
        <Element
          url={'/MUB-S/ENCEINTE/CLIM-SUPPORT'}
          key={j}
          position={[j * 2.1, -1, 0]}
          rotation={[0, MathUtils.degToRad(180), 0]}
        />
      ))}
    </group>
  );
};

export default CLIM;

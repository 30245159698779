import { Group, MathUtils } from 'three';
import { Align, Element } from '@/components/Webgl/Utils/';
import { useContext, useRef } from 'react';
import { EtuveContext } from '@/components/Webgl/Showcase/Model/ETUVE/ETUVE';
import {usePainter} from '@/hooks/beautify/';

const CTA_INSIDE = () => {
  const { width, length } = useContext(EtuveContext);
  const ctaInsideRef = useRef<Group>(null!);

  const { paint } = usePainter();

  paint(ctaInsideRef, [
    {
      name: ['Material_23'],
      color: '#233449',
    },
  ]);

  return (
    <group position={[-1.67, 3.06, 1.32]} ref={ctaInsideRef}>
      <Element url={'/MUB-E/ETUVE/INSIDE_TUBE_1'} rotation={[0, MathUtils.degToRad(-90), 0]} />
      <Align alignement={[0, 0, -1]}>
        <Element
          url={'/MUB-E/ETUVE/INSIDE_TUBE_2'}
          position={[0, 0, -0.07]}
          scale={[width + 2.42, 1, 1]}
          rotation={[0, MathUtils.degToRad(-90), 0]}
        />
      </Align>

      <Element
        url={'/MUB-E/ETUVE/INSIDE_TUBE_3'}
        position={[0.03, -1.32, -width - 0.5 - 2.42]}
        rotation={[0, MathUtils.degToRad(-90), 0]}
      />

      <Align alignement={[1, 0, 0]}>
        <Element
          url={'/MUB-E/ETUVE/INSIDE_TUBE_4'}
          position={[0.55, -2.238, -width - 0.7 - 2.42]}
          scale={[1, 1, length - 1]}
          rotation={[0, MathUtils.degToRad(-90), 0]}
        />
      </Align>
    </group>
  );
};

export default CTA_INSIDE;

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useContext, createContext } from 'react';
import { useGLTF, Merged } from '@react-three/drei';

const context = createContext();
export function Instances({ children, ...props }) {
  const { nodes } = useGLTF('/assets/models/MUB-R/800-114.3/R_BOT_B-800-114.3-transformed.glb');
  const instances = useMemo(
    () => ({
      Mesh: nodes.Mesh_101009,
      Mesh1: nodes.Mesh_101009_1,
      Mesh2: nodes.Mesh_101009_2,
      Mesh3: nodes.Mesh_101009_3,
      Mesh4: nodes.Mesh_101009_4,
      Mesh5: nodes.Mesh_101009_5,
      Mesh6: nodes.Mesh_101009_6,
      Mesh7: nodes.Mesh_101009_7,
      Mesh8: nodes.Mesh_101009_8,
    }),
    [nodes],
  );
  return (
    <Merged meshes={instances} {...props}>
      {(instances) => <context.Provider value={instances} children={children} />}
    </Merged>
  );
}

export function Model(props) {
  const instances = useContext(context);
  return (
    <group {...props} dispose={null}>
      <instances.Mesh name="Mesh_101009" />
      <instances.Mesh1 name="Mesh_101009_1" />
      <instances.Mesh2 name="Mesh_101009_2" />
      <instances.Mesh3 name="Mesh_101009_3" />
      <instances.Mesh4 name="Mesh_101009_4" />
      <instances.Mesh5 name="Mesh_101009_5" />
      <instances.Mesh6 name="Mesh_101009_6" />
      <instances.Mesh7 name="Mesh_101009_7" />
      <instances.Mesh8 name="Mesh_101009_8" />
    </group>
  );
}

useGLTF.preload('/assets/models/MUB-R/800-114.3/R_BOT_B-800-114.3-transformed.glb');

import { FC, ReactElement } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  isVisible: boolean;
  children: ReactElement;
  onStart?: (e: any) => void;
  onEnd?: (e: any) => void;
}

const Fade: FC<Props> = ({ isVisible, onStart, onEnd, children }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {isVisible && (
        <motion.div
          onAnimationStart={onStart}
          onAnimationComplete={onEnd}
          initial={{ opacity: 0 }}
          animate={{ name: 'start', opacity: 1 }}
          exit={{ name: 'exit', opacity: 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Fade;

import { FC } from 'react';

const InputCode: FC<JSX.IntrinsicElements['input']> = ({ ...attrs }) => {
  return (
    <input
      className={
        'mb-2xl w-[182px] border-b-[3px] border-primary-base bg-transparent pb-sm text-5xl uppercase italic text-secondary-base outline-none placeholder:text-gray-dark'
      }
      maxLength={6}
      placeholder={'A1BC23'}
      {...attrs}
    />
  );
};

export default InputCode;

import Module from '@/components/Sidebar/Modules/Module';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { setDwellTime, setHasChanged, setLineSpeed, setMubAmount, setMubHeight } from '@/store/config.slice';
import { FormRow } from '@/components/Base/Form';
import { InputUnit } from '@/components/Base/Input';
import { Formik } from 'formik';
import { number, object } from 'yup';
import Icon from '@/components/Base/Icon';
import Popover from '@/components/Base/Popover';
import { useGetTraysAmount } from '@/hooks';
import Select from '@/components/Base/Select';
import * as S from '@/components';
import { useMemo } from 'react';

const PerformanceModule: FC = () => {
  const dispatch = useDispatch();

  const [isValidated] = useState<boolean>(false);

  const traysAmount = useGetTraysAmount().toString();

  const config = useSelector((state: RootState) => state.config);

  const { mubAmount, lineSpeed, mubHeight, dwellTime } = useMemo(() => config, [config]);

  return (
    <Module
      name={'Performance'}
      validated={isValidated}
      options={
        <>
          <div
            className={
              'grid grid-cols-2 flex-wrap gap-y-xs gap-x-[12px] lg:grid-cols-1 lg:flex-col lg:px-10'
            }
          >
            <Formik
              enableReinitialize
              initialValues={{
                lineSpeed,
                mubAmount,
                mubHeight,
                dwellTime,
              }}
              validationSchema={object().shape({
                lineSpeed: number().min(1).max(540).required(),
                mubAmount: number().min(1).max(15).required(),
                mubHeight: number().min(2).max(12).required(),
                dwellTime: number().required(),
              })}
              onSubmit={(values) => {
                dispatch(setHasChanged(false));

                const { lineSpeed, mubAmount, mubHeight, dwellTime } = values;

                if (lineSpeed !== config.lineSpeed) dispatch(setLineSpeed(lineSpeed));
                if (mubAmount !== config.mubAmount) dispatch(setMubAmount(mubAmount));
                if (mubHeight !== config.mubHeight) dispatch(setMubHeight(mubHeight));
                if (dwellTime !== config.dwellTime) dispatch(setDwellTime(dwellTime));
              }}
            >
              {({ values, handleChange, submitForm, errors, validateForm }: any) => (
                <>
                  <FormRow
                    className={'relative flex flex-col gap-x-xs lg:flex-row'}
                    label={
                      <div className={'flex w-[160px] items-center whitespace-nowrap'}>
                        Line speed
                      </div>
                    }
                    error={
                      <>
                        {errors.lineSpeed && (
                          <div
                            className={
                              'absolute -right-2 top-1/2 flex translate-x-full -translate-y-1/2 items-center justify-end text-red-600'
                            }
                          >
                            <Popover
                              customClassName={'bg-red-600 text-white p-sm text-md'}
                              placement={'right'}
                              content={errors.lineSpeed}
                            >
                              <Icon name={'warning'} size={24} />
                            </Popover>
                          </div>
                        )}
                      </>
                    }
                  >
                    <InputUnit
                      customClassName={'bg-gray-base'}
                      name={'lineHeight'}
                      hasError={!!errors.lineSpeed}
                      min={0}
                      max={999}
                      value={values.lineSpeed}
                      step={'auto'}
                      unit={'trays/hour'}
                      onChange={(e) => {
                        handleChange({
                          target: { name: 'lineSpeed', value: e.target.value },
                        });
                        validateForm().then(submitForm);
                      }}
                    />
                  </FormRow>

                  <FormRow
                    className={'relative flex flex-col gap-x-xs  lg:flex-row'}
                    label={
                      <div className={'flex w-[160px] items-center whitespace-nowrap'}>
                        Number of MUB's
                      </div>
                    }
                    error={
                      <>
                        {errors.mubAmount && (
                          <div
                            className={
                              'absolute -right-2 top-1/2 flex translate-x-full -translate-y-1/2 items-center justify-end text-red-600'
                            }
                          >
                            <Popover
                              customClassName={'bg-red-600 text-white p-sm text-md'}
                              placement={'right'}
                              content={errors.mubAmount}
                            >
                              <Icon name={'warning'} size={24} />
                            </Popover>
                          </div>
                        )}
                      </>
                    }
                  >
                    <Select
                      value={parseInt(values.mubAmount)}
                      options={mubAmountOptions}
                      unit={'M-UB'}
                      onChange={(e) => {
                        handleChange({
                          target: { name: 'mubAmount', value: e.value },
                        });
                        validateForm().then(submitForm);
                      }}
                    />
                  </FormRow>

                  <FormRow
                    className={'relative flex flex-col gap-x-xs  lg:flex-row'}
                    label={
                      <div className={'flex w-[160px] items-center whitespace-nowrap'}>
                        MUB height
                      </div>
                    }
                    error={
                      <>
                        {errors.mubHeight && (
                          <div
                            className={
                              'absolute -right-2 top-1/2 flex translate-x-full  -translate-y-1/2 items-center justify-end text-red-600'
                            }
                          >
                            <Popover
                              customClassName={'bg-red-600 text-white p-sm text-md'}
                              placement={'right'}
                              content={errors.mubHeight}
                            >
                              <Icon name={'warning'} size={24} />
                            </Popover>
                          </div>
                        )}
                      </>
                    }
                  >
                    <Select
                      value={parseInt(values.mubHeight)}
                      options={mubHeightOptions}
                      unit={'swing'}
                      onChange={(e) => {
                        handleChange({
                          target: { name: 'mubHeight', value: e.value },
                        });
                        validateForm().then(submitForm);
                      }}
                    />
                  </FormRow>

                  <FormRow
                    className={'relative flex flex-col gap-x-xs lg:flex-row'}
                    label={
                      <div
                        className={
                          'flex w-[160px] items-center whitespace-nowrap text-secondary-base'
                        }
                      >
                        Dwell time
                      </div>
                    }
                    error={
                      <>
                        {errors.dwellTime && (
                          <div
                            className={
                              'absolute -right-2 top-1/2 flex translate-x-full -translate-y-1/2 items-center justify-end text-red-600'
                            }
                          >
                            <Popover
                              customClassName={'bg-red-600 text-white p-sm text-md'}
                              placement={'right'}
                              content={errors.dwellTime}
                            >
                              <Icon name={'warning'} size={24} />
                            </Popover>
                          </div>
                        )}
                      </>
                    }
                  >
                    <InputUnit
                      customClassName={'bg-gray-base text-secondary-base'}
                      name={'lineHeight'}
                      hasError={!!errors.dwellTime}
                      min={0}
                      max={999}
                      disabled={true}
                      value={values.dwellTime}
                      step={'auto'}
                      unit={'minutes'}
                    />
                  </FormRow>

                  <div className={'mt-lg'}>
                    {!!Object.keys(errors).length && (
                      <div className={'text-red-600'}>
                        For special configurations or issues, get in touch with us
                      </div>
                    )}
                  </div>
                </>
              )}
            </Formik>
          </div>
          <div className={'mt-md flex gap-x-sm'}>
            <S.Heading Tag={'h4'} color={'secondary'} size={'lg'} uppercase={false}>
              Amount of trays:
            </S.Heading>
            <S.Heading Tag={'h4'} color={'secondary'} size={'lg'} uppercase={false} weight={'bold'}>
              {traysAmount}
            </S.Heading>
          </div>
        </>
      }
    />
  );
};

const mubHeightOptions = [
  {
    label: '8',
    value: 2,
  },
  {
    label: '10',
    value: 3,
  },
  {
    label: '12',
    value: 4,
  },
  {
    label: '14',
    value: 5,
  },
  {
    label: '16',
    value: 6,
  },
  {
    label: '18',
    value: 7,
  },
  {
    label: '20',
    value: 8,
  },
  {
    label: '22',
    value: 9,
  },
  {
    label: '24',
    value: 10,
  },
  {
    label: '26',
    value: 11,
  },
];

const mubAmountOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '11',
    value: 11,
  },
  {
    label: '12',
    value: 12,
  },
  {
    label: '13',
    value: 13,
  },
  {
    label: '14',
    value: 14,
  },
  {
    label: '15',
    value: 15,
  },
];

export default PerformanceModule;

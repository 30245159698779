import Icon from '@/components/Base/Icon';
import { FC } from 'react';

export interface Props {
  variant?: string;
  border?: boolean;
  content?: string;
  rounded?: boolean;
  split?: boolean;
  icon?: string;
  fill?: boolean;
  full?: boolean;
  shadow?: boolean;
}

const Button: FC<JSX.IntrinsicElements['button'] & Props> = ({
  border = false,
  rounded = false,
  split = true,
  content,
  icon,
  variant,
  full,
  shadow,
  fill = false,
  ...attrs
}) => {
  return (
    <button className={'button'} {...attrs}>
      {icon && (
        <div className={'button__icon'}>
          <Icon name={icon} size={16} />
        </div>
      )}

      {content && (
        <div className={'button__content'}>
          <span className={'baseline-fix'}>{content}</span>
        </div>
      )}
    </button>
  );
};

export default Button;

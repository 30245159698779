import { FC, ReactElement } from 'react';

interface Props {
  children: ReactElement;
  label?: string | ReactElement;
  error?: ReactElement;
}

const FormRow: FC<Props & JSX.IntrinsicElements['div']> = ({
  label,
  children,
  error,
  ...attrs
}) => {
  return (
    <div {...attrs}>
      {label}
      {children}
      {error}
    </div>
  );
};

export default FormRow;

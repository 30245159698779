import { MathUtils } from 'three';
import { Align, Element } from '@/components/Webgl/Utils/';
import { Box } from '@react-three/drei';
import { useContext } from 'react';
import { EtuveContext } from '@/components/Webgl/Showcase/Model/ETUVE/ETUVE';
import { ModelContext } from '@/components/Webgl/Showcase/Model';

const WINDOW_A = () => {
  const { width, height } = useContext(EtuveContext);
  const { space } = useContext(ModelContext);

  return (
    <group position={[-2.2, 2, 0.0501]} rotation={[0, MathUtils.degToRad(90), 0]}>
      <Element url={'/MUB-E/ETUVE/WINDOW'} position={[width / 2 + 0.2, 0.054, -0.01]} />

      <Align alignement={[1, 1, 0]}>
        <Box position={[-1.4, -1.95, 0.001]} args={[0.66, 2.75, 0.1]} />
      </Align>

      <Align alignement={[1, 1, 0]}>
        <Box position={[-1.4, -0.62, 0.004]} args={[space / 2 + 0.2, 1.42, 0.1]} />
      </Align>

      <Align alignement={[-1, 1, 0]}>
        <Box position={[width + 2.1, -1.95, 0.003]} args={[0.95, 1.35, 0.1]} />
      </Align>

      <Align alignement={[-1, 1, 0]}>
        <Box position={[width + 2.1, -0.62, 0.003]} args={[space - (space - 1.2) / 2, 1.42, 0.1]} />
      </Align>

      <Align alignement={[1, 1, 0]}>
        <Box position={[-1.4, 0.79, 0.004]} args={[width + 3.5, height + 0.07, 0.1]} />
      </Align>

      <Align alignement={[1, 1, 0]}>
        <Element
          url={'/MUB-E/ETUVE/TRAY'}
          scale={[width + 1.9, 1, 1.05]}
          position={[-0.755, -1.96, 0.005]}
        />
      </Align>
    </group>
  );
};

export default WINDOW_A;

import * as S from '@/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsModelRendered } from '@/store/ar.slice';
import { setIsEnclosureVisible } from '@/store/misc.slice';
import { RootState } from '@/store';
import { useNavigate } from 'react-router-dom';

const AROverlay = () => {
  const { XR8 } = window as any;
  const isEnclosureVisible = useSelector((state: RootState) => state.misc.isEnclosureVisible);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  return (
    <div className={'pointer-events-none fixed inset-0 z-20'}>
      <div className={'pointer-events-auto absolute top-xl left-xl'}>
        <S.Button
          icon={'back'}
          content={'Back'}
          onClick={() => {
            navigate(`/configurator?code=${code}`);
            window.location.reload();
          }}
        />
      </div>
      <div
        className={'pointer-events-auto absolute bottom-xl left-1/2 flex -translate-x-1/2 gap-x-lg'}
      >
        <S.Button
          content={'Center'}
          onClick={() => {
            XR8.XrController.recenter();
            dispatch(setIsModelRendered(false));
          }}
        />
        <S.Button
          content={'Move'}
          onClick={() => {
            dispatch(setIsModelRendered(false));
          }}
        />
        {isEnclosureVisible ? (
          <S.Button
            onClick={() => dispatch(setIsEnclosureVisible(false))}
            icon={'eye-closed'}
            rounded={true}
          />
        ) : (
          <S.Button
            onClick={() => dispatch(setIsEnclosureVisible(true))}
            icon={'eye'}
            rounded={true}
          />
        )}
      </div>
    </div>
  );
};

export default AROverlay;

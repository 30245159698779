import styled from 'styled-components';
import Heading from '@/components/Base/Heading/Heading';
import { ComponentProps } from 'react';
import tw from 'twin.macro';

const StyledHeading = styled(Heading).attrs({ className: 'baseline-fix' })<
  ComponentProps<typeof Heading>
>`
  ${(props) => sizes[props.size!]}
  ${(props) => colors[props.color!]}
  ${(props) => weights[props.weight!]} 
  ${(props) => aligns[props.align!]}
  ${(props) => (props.uppercase ? tw`uppercase` : '')}
  ${(props) => (props.wide ? tw`tracking-wider` : '')}
`;

const sizes = {
  base: tw`text-base`,
  lg: tw`text-lg`,
  xl: tw`text-xl`,
};

const aligns = {
  left: tw`text-left`,
  center: tw`text-center`,
  right: tw`text-right`,
};

const colors = {
  primary: tw`text-primary-base`,
  secondary: tw`text-secondary-base`,
  yellow: tw`text-misc-yellow-base`,
  blue: tw`text-misc-blue-base`,
  navy: tw`text-misc-blue-dark`,
};

const weights = {
  light: tw`font-light`,
  normal: tw`font-normal`,
  semibold: tw`font-medium`,
  bold: tw`font-bold`,
};

StyledHeading.defaultProps = {
  size: 'base',
  color: 'primary',
  weight: 'normal',
  wide: false,
  uppercase: true,
};

export default StyledHeading;

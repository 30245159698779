import { useEffect, useMemo, useState } from 'react';
import { Align } from '@/components/Webgl/Utils';
import { Box, Text3D } from '@react-three/drei';
import { MathUtils, MeshBasicMaterial, Vector3 } from 'three';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { toFixed, unitConverter } from '@/functions';
import * as font from '@/styles/Neo Sans Std_Regular.json';
import resolveConfig from 'tailwindcss/resolveConfig'
import twConfig from '@/styles/tailwind'

const Dimensions = () => {

  const tw = resolveConfig(twConfig) as Record<string, any>
  const dimensions = useSelector((state: RootState) => state.misc.dimensions);

  const [{ x, y, z }, setSize] = useState<Vector3>(new Vector3(0, 0, 0));
  const { isDimensionsVisible, unit } = useSelector((state: RootState) => state.misc);

  useEffect(() => {
    setTimeout(() => {
      setSize(
        new Vector3(toFixed(dimensions.x, 2), toFixed(dimensions.y, 2), toFixed(dimensions.z, 2)),
      );
    }, 150);
  }, [dimensions, isDimensionsVisible]);

  unitConverter('ft', 'from', x).toFixed(2);

  const text = useMemo(
    () => ({
      x: unitConverter(unit, 'from', x).toFixed(2),
      y: unitConverter(unit, 'from', y).toFixed(2),
      z: unitConverter(unit, 'from', z).toFixed(2),
    }),
    [x, y, z, unit],
  );

  const materials = useMemo(() => ({
    x: new MeshBasicMaterial({ color: tw.theme.colors.misc.yellow.base }),
    y: new MeshBasicMaterial({ color: tw.theme.colors.secondary.base }),
    z: new MeshBasicMaterial({ color: tw.theme.colors.misc.blue.base  }),
    // text: new MeshBasicMaterial({ color: tw.theme.colors.primary.base  }),
  }), [tw])

  return (
    <>
      {isDimensionsVisible && (
        <mesh position={[-0.5, 0, 0.5]}>
          <Align alignement={[1, 1, 1]}>
            <Box
              material={materials.x}
              args={[x + 0.5, 0.05, 0.05]}
            />
          </Align>
          <Align alignement={[1, 1, 1]}>
            <Box material={materials.y} args={[0.05, y, 0.05]} />
          </Align>
          <Align alignement={[1, 1, -1]}>
            <Box material={materials.z} args={[0.05, 0.05, z + 0.5]} />
          </Align>

          <Align alignement={[-1, 0, 0]}>
            <Text3D
              position={[x / 2, 0, 2]}
              scale={[0.8, 0.8, 0.01]}
              rotation={[MathUtils.degToRad(-90), 0, 0]}
              material={materials.x}
              font={(font as any)}
            >
              {`${text.x} ${unit}`}
            </Text3D>
          </Align>

          <Align alignement={[0, -1, 0]}>
            <Text3D
              position={[-0.6, y / 2, 0]}
              scale={[0.8, 0.8, 0.01]}
              rotation={[MathUtils.degToRad(0), MathUtils.degToRad(0), MathUtils.degToRad(90)]}
              material={materials.y}
              font={(font as any)}
            >
              {`${text.y} ${unit}`}
            </Text3D>
          </Align>

          <Align alignement={[0, 0, -1]}>
            <Text3D
              position={[-1.5, 0, -z / 2]}
              scale={[0.8, 0.8, 0.01]}
              rotation={[MathUtils.degToRad(-90), 0, MathUtils.degToRad(-90)]}
              material={materials.z}
              font={(font as any)}
            >
              {`${text.z} ${unit}`}
            </Text3D>
          </Align>
        </mesh>
      )}
    </>
  );
};

export default Dimensions;

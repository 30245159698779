const unitConverter = (unit: string, direction: string, number: number) => {
  const unitScale: { [key: string]: number } = {
    mm: 0.001,
    cm: 0.01,
    m: 1,
    ft: 0.3048,
  };

  if (direction === 'from') return number / unitScale[unit];
  if (direction === 'to') return number * unitScale[unit];
  return number;
};

export default unitConverter;

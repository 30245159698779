import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Popin from '@/components/Popin';
import { Input } from '@/components/Base/Input';
import { Form, Formik } from 'formik';
import YupPassword from 'yup-password';
import * as yup from 'yup';
import { FormRow } from '@/components/Base/Form';
import { setToken } from '@/store/user.slice';
import { useDispatch } from 'react-redux';
import {useApi} from '@/hooks';
import * as S from '@/components';
const cookies = require('js-cookie');

YupPassword(yup);

const Register = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [tokenData, setTokenData] = useState<any>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { register, signIn } = useApi();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}subscription_token_check/${token}`).then((res) => {
      setIsVisible(true);
      setTokenData(res.data);
    });
  }, [token]);

  const navigate = useNavigate();
  return (
    <>
      <Popin isShowing={isVisible} hide={() => setIsVisible(false)}>
        <div className={'flex flex-col gap-y-lg px-6 pb-6 pt-10'}>
          {tokenData.validToken ? (
            <>
              <div
                className={
                  'flex items-center justify-center gap-x-md text-2xl font-bold uppercase text-secondary-base'
                }
              >
                Register
              </div>
              <div className={'flex flex-col gap-y-lg'}>
                <Formik
                  initialValues={{
                    password: '',
                    passwordRepeat: '',
                  }}
                  onSubmit={(e, actions) => {
                    if (e.password === e.passwordRepeat) {
                      register({
                        email: tokenData.email,
                        token: tokenData.token,
                        password: e.password,
                        passwordRepeat: e.passwordRepeat,
                      })
                        .then(() => {
                          signIn({
                            email: tokenData.email,
                            password: e.password,
                          }).then((res) => {
                            dispatch(setToken(res.data.token));
                            cookies.set('token', res.data.token);
                            navigate('/configurator');
                          });
                        })
                        .catch(() => {
                          setTokenData({
                            validToken: false,
                            message: 'An error occurred',
                          });
                        });
                    } else {
                      actions.setFieldError('passwordRepeat', 'passwords should match');
                    }
                  }}
                  validationSchema={yup.object().shape({
                    password: yup.string().password().required(),
                    passwordRepeat: yup.string(),
                  })}
                  validateOnChange={true}
                >
                  {({ values, handleChange, submitForm, errors }: any) => (
                    <Form>
                      <div className={'flex flex-col gap-y-lg'}>
                        <div className={'flex flex-col gap-y-1'}>
                          <FormRow
                            label={'Password'}
                            error={<div className={'text-red-600'}>{errors.password}</div>}
                          >
                            <Input
                              name={'password'}
                              type={'password'}
                              value={values.password}
                              onChange={handleChange}
                              errors={errors.password}
                              placeholder={'••••••••'}
                              autoComplete="current-password"
                            />
                          </FormRow>
                        </div>
                        <div className={'flex flex-col gap-y-1'}>
                          <FormRow
                            label={'Confirm Password'}
                            error={<div className={'text-red-600'}>{errors.passwordRepeat}</div>}
                          >
                            <Input
                              name={'passwordRepeat'}
                              type={'password'}
                              value={values.repeatPassword}
                              onChange={handleChange}
                              errors={errors.passwordRepeat}
                              placeholder={'••••••••'}
                              autoComplete="current-password"
                            />
                          </FormRow>
                        </div>
                        <div className={'flex justify-center'}>
                          <S.Button
                            type={'submit'}
                            variant={'primary'}
                            content={'Submit'}
                            fill={true}
                            onClick={submitForm}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          ) : (
            <div className={'text-red-600 first-letter:uppercase'}>{tokenData.message}</div>
          )}
        </div>
      </Popin>
    </>
  );
};

export default Register;

import Module from '@/components/Sidebar/Modules/Module';
import { RadioOption } from '@/components/Sidebar/Modules/Options';
import { FC, Fragment, useState, useEffect } from 'react';
import { updateConfig } from '@/store/config.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';

const ProcessModule: FC = () => {
  const dispatch = useDispatch();

  const options = [
    [
      {
        name: 'proofing',
        image: '/assets/images/configurator/process/proofing.png',
        color: '#ECC23A',
      },
      {
        name: 'retarding',
        image: '/assets/images/configurator/process/proofing_blue.png',
        color: '#93c9ff',
      },
    ],
    [
      {
        name: 'ambiant cooling',
        image: '/assets/images/configurator/process/cooling.png',
        color: '#3488DD',
      },
      {
        name: 'enclosed cooling',
        image: '/assets/images/configurator/process/cooling.png',
        color: '#3488DD',
      },
    ],
    {
      name: 'freezing',
      image: '/assets/images/configurator/process/freezing.png',
      color: '#0B497C',
    },
  ];

  const [isValidated] = useState<boolean>(false);

  const config = useSelector((state: RootState) => state.config);

  return (
    <Module
      name={'Your process'}
      validated={isValidated}
      options={
        <div className={'flex flex-col gap-y-sm'}>
          {options.map((option, index) => (
            <Fragment key={index}>
              {Array.isArray(option) ? (
                <div className={'flex gap-x-sm'}>
                  {option.map((subOption, subIndex) => (
                    <RadioOption
                      key={subIndex}
                      onClick={() => {
                        dispatch(
                          updateConfig({
                            ...config,
                            process: subOption.name,
                            mubHeight: config.mubHeight,
                          }),
                        );
                      }} 
                      selected={config.process === subOption.name}
                      name={subOption.name}
                      color={subOption.color}
                      image={subOption.image}
                    />
                  ))}
                </div>
              ) : (
              <RadioOption
                onClick={() => {
                  dispatch(
                    updateConfig({
                      ...config,
                      process: option.name,
                      mubHeight: config.mubHeight,
                    }),
                  );
                }}
                selected={config.process === option.name}
                name={option.name}
                color={option.color}
                image={option.image}
              />
            )}
            </Fragment>
          ))}
        </div>
      }
    />
  );
};

export default ProcessModule;

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

import {
  PerformanceModule,
  ProcessModule,
  ProductsModule,
  VolumeModule,
  TraysModule,
} from '@/components/Sidebar/Modules';
import SiderbarDesktop from '@/components/Sidebar/SidebarDesktop';
import SidebarMobile from '@/components/Sidebar/SidebarMobile';

const Sidebar: FC = () => {
  const { demo, token } = useSelector((state: RootState) => state.user);

  const {desktopModules, mobileModules} = useMemo(() => {

    let desktopModules = [<ProcessModule />, <ProductsModule />, <VolumeModule />];
    let mobileModules = [<ProcessModule />, <ProductsModule />, <VolumeModule />];

    if (token || demo) {
      desktopModules = [...desktopModules, <PerformanceModule />, <TraysModule />];
      mobileModules = [...mobileModules, <PerformanceModule />, <TraysModule />];
    }

    return {
      desktopModules,
      mobileModules,
    }
  }, [demo, token])

  return (
    <>
      <SiderbarDesktop modules={desktopModules} />
      <SidebarMobile modules={mobileModules} />
    </>
  );
};

export default Sidebar;

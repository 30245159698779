import { Vector3 } from 'three';

const defaultStore = {
  config: {
    mubAmount: 4,
    lineSpeed: 80,
    mubHeight: 4,
    dwellTime: 2.4,
    process: 'proofing',
    products: '114.3',
    trays: {
      x: 450,
      y: 800,
    },
  },
  volume: {
    isOutOfBounds: false,
    isVisible: false,
    size: new Vector3(8, 10, 6),
  },
};

export default defaultStore;

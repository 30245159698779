import ETUVE from '@/components/Webgl/Showcase/Model/ETUVE/ETUVE';
import WINDOWA from '@/components/Webgl/Showcase/Model/ETUVE/WINDOW-A';
import WINDOWB from '@/components/Webgl/Showcase/Model/ETUVE/WINDOW-B';
import FACEA from '@/components/Webgl/Showcase/Model/ETUVE/FACE-A';
import FACEB from '@/components/Webgl/Showcase/Model/ETUVE/FACE-B';
import TOP from '@/components/Webgl/Showcase/Model/ETUVE/TOP';
import CTAOUTSIDE from '@/components/Webgl/Showcase/Model/ETUVE/CTA-OUTSIDE';
import CTAINSIDE from '@/components/Webgl/Showcase/Model/ETUVE/CTA-INSIDE';
import BRIDGE from '@/components/Webgl/Showcase/Model/ETUVE/BRIDGE';

export { WINDOWA, WINDOWB, FACEA, FACEB, TOP, CTAOUTSIDE, CTAINSIDE, BRIDGE };
export default ETUVE;

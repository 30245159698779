import { MutableRefObject, useEffect } from 'react';
import { Mesh, MeshStandardMaterial, Vector2 } from 'three';
import { useNormalTexture } from '@react-three/drei';

const useBeautify = (meshRef: MutableRefObject<Mesh>) => {
  const [normalMap] = useNormalTexture(42, {
    offset: [0, 0],
  });

  const normalScale = new Vector2(0.005, 0.005)

  useEffect(() => {
    meshRef.current.traverse((child: any) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;

        const { color, name } = child.material;
        child.material = new MeshStandardMaterial({
          name,
          color,
        });

        child.material.roughness = 0;
        child.material.metalness = 0.3;
        child.material.normalMap = normalMap;
        child.material.normalScale = normalScale;
      }
    });
  });
};

export default useBeautify;

import { FC } from 'react';

interface Props {
  Tag?: 'p' | 'div';
  size?: 'base' | 'sm';
  weight?: 'normal' | 'light';
  align?: 'left' | 'center' | 'right' | 'justify';
  color?: 'primary' | 'secondary' | 'gray';
  children: any;
}

const Text: FC<Props> = ({ Tag, children, ...attrs }) => {
  const Element = Tag ? Tag : 'p';

  return <Element {...attrs}>{children}</Element>;
};

export default Text;

import { Context, createContext, FC, useRef } from 'react';
import MUB_E from '@/components/Webgl/Showcase/Model/MUB-E/';
import MUB_R from '@/components/Webgl/Showcase/Model/MUB-R/';

import { RootState } from '@/store';
import { useSelector } from 'react-redux';
import MUB_S from '@/components/Webgl/Showcase/Model/MUB-S/MUB-S';
import VolumeVisualizer from '@/components/Webgl/Showcase/VolumeVisualizer';
import Dimensions from '@/components/Webgl/Showcase/Dimensions';
import { useHelper } from '@react-three/drei';
import { BoxHelper, Mesh } from 'three';
interface ModelContextInterface {
  length: number;
  space: number;
  width: number;
  height: number;
  products: string;
  process: string;
  reference: any | null;
}

export const ModelContext: Context<ModelContextInterface> = createContext<ModelContextInterface>(
  null!,
);

const Model: FC<JSX.IntrinsicElements['group']> = ({ ...attrs }) => {
  const config = useSelector((state: RootState) => state.config);
  const boxRef = useRef<Mesh>(null!);
  useHelper(boxRef, BoxHelper, 'blue');

  return (
    <>
      <ModelContext.Provider
        value={{
          length: config.mubAmount,
          space: 0.4 + config.trays.x / 1000,
          width: config.trays.y,
          height: config.mubHeight,
          products: config.products,
          process: config.process,
          reference: null,
        }}
      >
        <group name={'model'}>
          {config.process === 'proofing' && <MUB_E />}
          {config.process === 'retarding' && <MUB_E />}

          {config.process === 'ambiant cooling' && <MUB_R />}
          {config.process === 'enclosed cooling' && <MUB_R enclosure />}

          {config.process === 'freezing' && <MUB_S />}
        </group>
        <VolumeVisualizer />
        <Dimensions />
      </ModelContext.Provider>
    </>
  );
};

export default Model;

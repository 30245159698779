import { setScale } from '@/store/ar.slice';
import { FC, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Group, MathUtils } from 'three';
import Model from '../Showcase/Model';
import { useGesture } from '@use-gesture/react';
import ARScale from './ARScale';

const PrePosition: FC<JSX.IntrinsicElements['object3D']> = ({ ...attrs }) => {
  const modelRef = useRef<Group>(null!);
  const dispatch = useDispatch();

  document.addEventListener('gesturestart', (e) => e.preventDefault());
  document.addEventListener('gesturechange', (e) => e.preventDefault());

  const bind = useGesture({
    onDrag: ({ direction: [d], velocity: [v] }) => {
      if (d > 0) modelRef.current.rotateY(MathUtils.degToRad(v * 50));
      if (d < 0) modelRef.current.rotateY(-MathUtils.degToRad(v * 50));
    },
    onPinch: ({ offset: [s] }) => {
      modelRef.current.scale.set(s, s, s);
      dispatch(setScale(s));
    },
  });

  return (
    <object3D name="ar-model" {...(bind() as any)} {...attrs} ref={modelRef}>
      <ARScale />
      <Model />
    </object3D>
  );
};

export default PrePosition;

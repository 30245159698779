import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';
import { Box3, Group, Vector3 } from 'three';
import { Box } from '@react-three/drei';
import {usePainter} from '@/hooks/beautify';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import {
  WINDOWA,
  WINDOWB,
  FACEA,
  FACEB,
  TOP,
  CTAOUTSIDE,
  CTAINSIDE,
  BRIDGE,
} from '@/components/Webgl/Showcase/Model/ETUVE';
import {useGlassify} from '@/hooks/beautify';
import { Align } from '@/components/Webgl/Utils';
import {useBeautifyEtuve} from '@/hooks/beautify';
import { setModelDimensions } from '@/store/misc.slice';
import { useThree } from '@react-three/fiber';

export const EtuveContext = createContext<any>(null!);

const ETUVE: FC<any> = ({ bridge, mub, compensation }) => {
  const etuveRef = useRef<Group>(null!);
  const blocRef = useRef<Group>(null!);
  const glassRef = useRef<Group>(null!);

  const dispatch = useDispatch();
  const { paint } = usePainter();

  const modelContext = useContext(ModelContext);

  const [config, setConfig] = useState({
    width: 0,
    height: 0,
    length: 0,
    solo: false,
  });

  const { scene } = useThree();

  const { isEnclosureVisible } = useSelector((state: RootState) => state.misc);

  // Create BoundingBox from MUB

  useEffect(() => {
    const mub = scene.getObjectByName('mub')!;
    const boundsVolume = new Vector3();
    const volumeBoundingBox = new Box3().setFromObject(mub);
    volumeBoundingBox.getSize(boundsVolume);

    setConfig({
      length: boundsVolume.x + 0.18 + compensation.x,
      width: boundsVolume.z + compensation.z,
      height: boundsVolume.y - 1.95 + compensation.y,
      solo: modelContext.length === 1,
    });
  }, [modelContext, mub, compensation, scene]);

  useEffect(() => {
    const dimensions = new Vector3(config.length + 1.45, config.height + 2.8, config.width + 3.5);
    dispatch(setModelDimensions(dimensions));
  }, [config, dispatch]);

  // Beautify

  useBeautifyEtuve(etuveRef);

  paint(etuveRef, [
    {
      name: ['', 'Material_20'],
      color: '#ffffff',
    },
    {
      name: 'Material_19',
      color: '#7d7d7d',
    },
  ]);

  useGlassify(glassRef, isEnclosureVisible);

  return (
    <EtuveContext.Provider value={config}>
      <group ref={etuveRef} position={[0, 0.08, 0]}>
        <group ref={glassRef}>
          <group ref={blocRef}>
            <WINDOWA />
            <WINDOWB />
            <FACEA />
            <FACEB />
            <TOP />
          </group>

          {/* Doors are outside the faces so the volume is computed correctly */}

          <Align alignement={[1, 1, 0]}>
            <Box position={[config.length - 2, 0, 1.93]} args={[0.08, 2.07, 0.9]} />
          </Align>
          <Align alignement={[1, 1, 0]}>
            <Box
              position={[config.length - 1.34, 0, -config.width - 2.5]}
              args={[0.08, 2.07, 0.9]}
            />
          </Align>
        </group>
        <CTAOUTSIDE />
        <CTAINSIDE />
        {(modelContext.height > 4 && bridge) && <BRIDGE />}
      </group>
    </EtuveContext.Provider>
  );
};

export default ETUVE;

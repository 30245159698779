import React from 'react';
import { GlobalContext, Router } from '@/components/Utils/';

const App = () => {
  return (
    <React.StrictMode>
      <GlobalContext>
        <Router />
      </GlobalContext>
    </React.StrictMode>
  );
};

export default App;

import { createSlice } from '@reduxjs/toolkit';

import { Vector3 } from 'three';

interface InitialState {
  isOutOfBounds: boolean;
  isVisible: boolean;
  size: Vector3;
}

const initialState: InitialState = {
  isOutOfBounds: false,
  isVisible: false,
  size: new Vector3(8, 10, 6),
};

const volumeSlice = createSlice({
  name: 'volume',
  initialState,
  reducers: {
    setVolume: (_, action) => {
      return action.payload;
    },
    setVolumeSize: (state, action) => {
      state.size = action.payload;
    },
    setIsVolumeVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setIsVolumeOutOfBounds: (state, action) => {
      state.isOutOfBounds = action.payload;
    },
  },
});

export const { setVolumeSize, setIsVolumeVisible, setIsVolumeOutOfBounds, setVolume } =
  volumeSlice.actions;

export default volumeSlice;

import { FC, ReactElement, useEffect, useState } from 'react';
import {
  useFloating,
  useHover,
  useInteractions,
  autoUpdate,
  offset,
  flip,
  Placement,
  FloatingPortal,
} from '@floating-ui/react-dom-interactions';
import { motion, AnimatePresence } from 'framer-motion';

interface Props {
  delay?: number;
  placement: Placement;
  children: ReactElement;
  content: string | ReactElement;
  customClassName?: string;
  isOpen?: boolean;
}

const Popover: FC<Props> = ({
  delay = 0,
  customClassName = 'bg-primary-base p-xs text-white',
  placement,
  children,
  content,
  isOpen = false,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  const { context, x, y, reference, floating, strategy } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(10), flip()],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {enabled: !isOpen, delay: { open: delay, close: 100 } }),
  ]);

  return (
    <>
      <div className={'cursor-pointer'} {...getReferenceProps({ ref: reference })}>
        {children}
      </div>
      <AnimatePresence>
        {open && (
          <FloatingPortal>
            <motion.div
              className={`w-max rounded-md ${customClassName}`}
              initial={{ opacity: 0, scale: 0.85 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: 'spring', damping: 20, stiffness: 300 }}
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                },
              })}
            >
              {content}
            </motion.div>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  );
};

export default Popover;

import { FC, ReactNode } from 'react';
import Navbar from '@/components/Navbar';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

interface Props {
  children: ReactNode | ReactNode[];
}

const Layout: FC<Props> = ({ children }) => {
  const isFullscreen = useSelector((state: RootState) => state.ui.isFullscreen);

  return (
    <div className={'layout relative flex h-full w-full flex-col overflow-y-hidden'}>
      <div className={'z-[9999]'}>{!isFullscreen && <Navbar />}</div>
      <div className={'relative z-0 min-h-0 flex-1'}>{children}</div>
    </div>
  );
};

export default Layout;

import { FC, useRef, useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  useFloating,
} from '@floating-ui/react-dom-interactions';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Option {
  label: string;
  value: string | number;
}

interface Props {
  value: string | number;
  options: Option[];
  onChange: (option: Option) => void;
  unit?: string;
  small?: boolean;
}

const Select: FC<Props> = ({ value, options, onChange, unit, small = false }) => {
  const [open, setOpen] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(10), flip()],
  });

  const clickOutsideRef = useDetectClickOutside({
    onTriggered: (e) => {
      if (!optionsRef.current?.contains(e.target as HTMLElement)) {
        setOpen(false);
      }
    },
  });

  const selectOption = (option: Option) => {
    onChange(option);
    setOpen(false);
  };

  const optionsRef = useRef<HTMLDivElement>(null!);

  return (
    <>
      <div onClick={() => setOpen(true)} className={'w-full'} ref={reference}>
        <div
          className={`
          flex w-full items-center justify-between gap-x-sm bg-gray-base text-base
          ${small ? 'h-[40px] px-sm font-medium' : 'h-[50px] px-[16px]'}
        `}
          ref={clickOutsideRef}
        >
          <span
            className={`
            ${small ? 'text-base' : 'baseline-fix text-lg font-light'}
          `}
          >
            {options.filter((option) => option.value === value)[0].label}
          </span>
          {unit ? (
            <span className={'font-medium'}>{unit}</span>
          ) : (
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L4 3.29289L6.64645 0.646447C6.84171 0.451184 7.15829 0.451184 7.35355 0.646447C7.54882 0.841709 7.54882 1.15829 7.35355 1.35355L4.35355 4.35355C4.15829 4.54882 3.84171 4.54882 3.64645 4.35355L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
                fill="#141E3F"
              />
            </svg>
          )}
        </div>
      </div>
      <FloatingPortal>
        {open && (
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 1000,
            }}
          >
            <div
              ref={optionsRef}
              className={
                'z-10 flex max-h-[180px] w-[200px] flex-col overflow-y-auto rounded-md border border-gray-base bg-white shadow-md'
              }
            >
              <>
                {options.map((option, index) => (
                  <div
                    className={
                      'cursor-pointer px-2.5 py-2 transition hover:bg-primary-base hover:text-white'
                    }
                    key={index}
                    onClick={() => selectOption(option)}
                  >
                    {option.label}
                  </div>
                ))}
              </>
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default Select;

import { Align } from '@/components/Webgl/Utils/';
import { Box } from '@react-three/drei';
import { FC } from 'react';

interface Props {
  height: number;
  length: number;
}

const SIDE: FC<Props> = ({ height, length }) => {
  return (
    <Align alignement={[1, 1, 0]}>
      <Box args={[length, height, 0.12]} />
    </Align>
  );
};

export default SIDE;

import { Color, ColorRepresentation, Mesh, MeshStandardMaterial, Object3D } from 'three';

const usePainter = () => {
  const paint = (model: any, params: any) =>
    model.current?.traverse((child: Object3D) => {
      if (child instanceof Mesh) {
        params.forEach((param: any) => {
          if (Array.isArray(param.name)) {
            param.name.forEach((n: any) => {
              if (child.material.name === n) {
                if (param.color) {
                  child.material.color = new Color(param.color as ColorRepresentation);
                }
                if (param.material) {
                  child.material = param.material;
                }
              }
            });
          } else {
            if (child.material.name === param.name) {
              if (param.color) {
                child.material.color = new Color(param.color as ColorRepresentation);
              }
              if (param.material) {
                child.material = param.material;
              }
            }
            if (param.name === 'all') {
              if (param.color) {
                child.material.color = new Color(param.color as ColorRepresentation);
              }
              if (param.material) {
                child.material = param.material;
              }
              if (param.options) {
                child.material = new MeshStandardMaterial({
                  color: child.material.color,
                  ...param.options,
                });
              }
            }
          }
        });
      }
    });

  return { paint };
};

export default usePainter;

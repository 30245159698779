import { setIsDemo } from "@/store/user.slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const Demo = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsDemo(true))
  });

  return null;
}

export default Demo;

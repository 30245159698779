import { FC, useMemo } from 'react';
import { takeScreenshot } from '@/functions';
import * as S from '@/components';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useNavigate } from 'react-router-dom';

import Popover from '@/components/Base/Popover';

const Share: FC = () => {
  const { projects, currentProjectId } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const project = projects.find((project: any) => project.id === currentProjectId);
  const link = useMemo(
    () =>
      `${window.location.protocol}//${window.location.host}/configurator/share/${project?.code}`,
    [project],
  );

  return (
    <div className={'flex gap-x-md bg-gray-dark p-md'}>
      <div className={'hidden lg:block'}>
        <Popover placement={'bottom'} content={'Take a screenshot'}>
          <S.Button onClick={() => takeScreenshot('[data-canvas] canvas')} icon={'capture'} />
        </Popover>
      </div>
      <div className={'block lg:hidden'}>
        <Popover delay={500} placement={'left'} content={'Profile'}>
          <S.Button onClick={() => navigate('/configurator/menu')} icon={'profile'} />
        </Popover>
      </div>
      {currentProjectId && (
        <Popover placement={'bottom'} content={'Share'}>
          <S.Button
            icon={'share'}
            onClick={() =>
              navigator.share({
                title: 'MUB Configuration',
                text: 'Check this configuration',
                url: link,
              })
            }
          />
        </Popover>
      )}
    </div>
  );
};

export default Share;

import * as S from '@/components';
import Icon from '@/components/Base/Icon';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
const Prompt: FC = () => {
  const navigate = useNavigate();

  return (
    <div
      className={
        'flex flex-col items-center gap-y-xl max-w-[80%] mx-auto bg-white/80 py-md text-primary-base'
      }
    >
      <div className={'flex items-center'}>
        <Icon name={'lock'} size={28} />
      </div>
      <div className={'flex flex-col gap-y-[10px]'}>
        <S.Heading Tag={'h3'} weight={'light'} wide={true} align={'center'} >
          Other information
        </S.Heading>
        <S.Text align={'center'}>
          Create an account to save your project and to discover all the customization possibilities
        </S.Text>
      </div>
      <div className={'flex items-end'}>
        <S.Button
          fill={true}
          variant={'primary'}
          content={'Sign Up'}
          onClick={() => navigate('/configurator/sign-up')}
        />
      </div>
    </div>
  );
};

export default Prompt;

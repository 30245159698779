import { Box, Html } from '@react-three/drei';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const ARScale = () => {
  const scale = useSelector((state: RootState) => state.ar.scale);
  const dimensions = useSelector((state: RootState) => state.misc.dimensions);
  const scaleText = useMemo(() => `${Math.round(scale * 100)}%`, [scale]);
  const [isVisible, setIsVisible] = useState(false);

  const debounced = useDebouncedCallback((value) => {
    setIsVisible(value);
  }, 300);

  useEffect(() => {
    setIsVisible(true);
    debounced(false);
  }, [scale, debounced]);

  return (
    <Box visible={false} args={[dimensions.x, dimensions.y, dimensions.z]}>
      <Html position={[0, dimensions.y / 2, 0]} center>
        <div
          className={`bg-white px-2 py-1.5 uppercase text-secondary-base ${
            isVisible ? 'block' : 'hidden'
          }`}
        >
          {scaleText}
        </div>
      </Html>
    </Box>
  );
};

export default ARScale;

import { Environment } from '@react-three/drei';
// @ts-ignore
import hdri from '@pmndrs/assets/hdri/warehouse.hdr';

const Presentation = () => {
  return (
    <>
      <color attach="background" args={['white']} />
      <ambientLight intensity={1} />
      <pointLight position={[10, 8, 5]} intensity={3} />
      <Environment files={hdri} />
    </>
  );
};

export default Presentation;

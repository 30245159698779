import { BOT, MID, TOP } from '@/components/Webgl/Showcase/Model/MUB-E';
import ETUVE from '@/components/Webgl/Showcase/Model/ETUVE';
import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { Group, Vector3 } from 'three';
import {usePainter} from '@/hooks/beautify';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { E } from '@/config';
import { RootState } from '@/store';
import { useSelector } from 'react-redux';

const MUB_E: FC = () => {
  const groupRef = useRef<Group>(null!);

  const { paint } = usePainter();

  useEffect(() => {
    paint(groupRef, [
      {
        name: [
          'Material_3.008',
          'Material_3.011',
          'Material_3',
          'Material_3.013',
          'Material_3.010',
          'Material_3.012',
          'Material_8.001',
          'Material_8.002',
          'Material_8.003',
        ],
        color: '#233449',
      },
      {
        name: [
          'Material_8',
          'Material_8.012',
          'Material_8.013',
          'Material_2.003',
          'Material_2.002',
          'Material_2.001',
        ],
        color: '#394043',
      },
      {
        name: 'all',
        options: {
          roughness: 0.1,
          metalness: 1,
        },
      },
    ]);
  });

  const { width, products } = useContext(ModelContext);

  const { offset, offsetProduct, compensation } = useMemo(() => {
    const e = E as any;
    return {
      offset: e[width].offset,
      offsetProduct: e[width][products].offset,
      compensation: e[width][products].compensation,
    };
  }, [width, products]);

  const isARReady = useSelector((state: RootState) => state.misc.ARReady);

  const basePosition = useMemo(
    () => (!isARReady ? new Vector3(2.27, -0.08, -1.48) : new Vector3()),
    [isARReady],
  );

  return (
    <group position={basePosition}>
      <ETUVE bridge compensation={compensation} mub={groupRef} />
      <group
        position={[
          offset.x + offsetProduct.x,
          1.4 + offset.y + offsetProduct.y,
          1 + offset.z + offsetProduct.z,
        ]}
        ref={groupRef}
        name={'mub'}
      >
        <TOP />
        <group position={[0, 1.143, 0]}>
          <MID />
        </group>
        <BOT />
      </group>
    </group>
  );
};

export default MUB_E;

const E = {
  '800': {
    spacing: 2.08,
    offset: { x: -0.05, y: 0, z: 0.025 },
    BRIDGE: {
      LENGTH: {
        BASE: {
          path: '/MUB-E/ETUVE/BRIDGE/LENGTH_800',
          pos: { x: 0, y: 0, z: 0 },
        },
        SM: {
          path: '/MUB-E/ETUVE/BRIDGE/LENGTH_SM_800',
          pos: { x: 0, y: 0, z: 0 },
        },
      },
    },
    '114.3': {
      offset: { x: 0, y: 0, z: -0.5 },
      compensation: { x: 1, y: -0.1, z: -2.46 },
      SIDES: {
        A: { x: 0, y: 0, z: 0 },
        B: { x: 0, y: -0.006, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0.45, z: -0.06 },
          pos2: { x: 0, y: 0.45, z: 0.01 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: -0.07 },
          pos2: { x: 0, y: 0, z: 0.02 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: -0.005, z: 0.0045 },
          posB: { x: 0, y: 0.006, z: 0 },
        },
      },
    },
    '152.4': {
      offset: { x: 0, y: 0, z: -0.5 },
      compensation: { x: 1, y: -0.1, z: -2.46 },
      SIDES: {
        A: { x: 0, y: 0.0005, z: 0 },
        B: { x: 0, y: -0.006, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0.449, z: -0.052 },
          pos2: { x: 0, y: 0.45, z: 0 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: -0.008, z: -0.06 },
          pos2: { x: -0.005, y: -0.008, z: 0.008 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: -0.018, z: 0.02 },
          posB: { x: -0.01, y: -0.018, z: -0.013 },
        },
      },
    },
    '226.6': {
      offset: { x: 0, y: -0.01, z: -0.51 },
      compensation: { x: 1, y: -0.1, z: -2 },
      SIDES: {
        A: { x: 0, y: 0.005, z: 0 },
        B: { x: 0, y: 0, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0.45, z: -0.03 },
          pos2: { x: 0, y: 0.45, z: 0 },
        },
      },
      MID: {
        params: {
          pos1: { x: -0, y: -0.008, z: -0.036 },
          pos2: { x: -0.005, y: -0.008, z: 0.008 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: -0.018, z: 0.019 },
          posB: { x: -0.01, y: -0.018, z: -0.013 },
        },
      },
    },
  },
  '1330': {
    spacing: 3.46,
    offset: { x: 0.6, y: 0.2, z: -0.2 },
    BRIDGE: {
      LENGTH: {
        BASE: {
          path: '/MUB-E/ETUVE/BRIDGE/LENGTH_1330',
          pos: { x: 0.69, y: 0.07, z: 0.045 },
        },
        SM: {
          path: '/MUB-E/ETUVE/BRIDGE/LENGTH_SM_1330',
          pos: { x: 0.695, y: -0.022, z: -0.004 },
        },
      },
    },
    '114.3': {
      offset: { x: 0, y: -0.03, z: -0.33 },
      compensation: { x: 1.425, y: 0.4, z: -2 },
      SIDES: {
        A: { x: 0, y: 0.0045, z: 0.004 },
        B: { x: 0.05, y: 0.005, z: 0.125 },
      },
      TOP: {
        params: {
          pos1: { x: 0.011, y: 0.545, z: 0.0182 },
          pos2: { x: -0.017, y: 0.545, z: -0.0182 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0.005, y: -0.009, z: 0.085 },
          posB: { x: -0.03, y: -0.02, z: -0.033 },
        },
      },
    },
    '152.4': {
      offset: { x: 0, y: 0.01, z: -0.34 },
      compensation: { x: 1.425, y: 0.373, z: -2 },
      SIDES: {
        A: { x: 0, y: 0, z: 0.02 },
        B: { x: 0.05, y: 0, z: 0.12 },
      },
      TOP: {
        params: {
          pos1: { x: 0.011, y: 0.5, z: 0.018 },
          pos2: { x: -0.017, y: 0.5, z: -0.013 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: -0.02, z: 0.08 },
          posB: { x: -0.035, y: -0.02, z: -0.024 },
        },
      },
    },
    '226.6': {
      offset: { x: 0, y: 0, z: -0.3 },
      compensation: { x: 1.425, y: 0.372, z: -3.2 },
      SIDES: {
        A: { x: 0, y: -0.002, z: -0.01 },
        B: { x: 0.05, y: 0, z: -0.05 + 0.12 },
      },
      TOP: {
        params: {
          pos1: { x: 0.011, y: 0.5, z: 0.013 },
          pos2: { x: -0.017, y: 0.5, z: -0.013 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: -0.028, z: 0.081 },
          posB: { x: -0.04, y: -0.03, z: -0.026 },
        },
      },
    },
  },
};

export default E;

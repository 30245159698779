import { FC, useContext, useMemo } from 'react';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { S } from '@/config';
import MUB from '@/components/Webgl/Showcase/Elements';

const BOT: FC = () => {
  const { space, products, width, length } = useContext(ModelContext);

  const { params, A, B, spacing } = useMemo(() => {
    const e = S as any;
    return {
      params: e[width][products].BOT.params,
      A: e[width][products].SIDES.A,
      B: e[width][products].SIDES.B,
      spacing: e[width].spacing,
    };
  }, [width, products]);

  const { BOT_A, BOT_B } = useMemo(() => MUB.S[`${width}-${products}`], [products, width]);

  return (
    <>
      <BOT_A.Instances limit={15} range={15}>
        {[...Array(length)].map((e, j) => (
          <group position={[-j * spacing, 0, 0]} key={j}>
            <group
              position={[params.posA.x + A.x, params.posA.y + A.y, params.posA.z + space + A.z]}
            >
              <BOT_A.Model />
            </group>
          </group>
        ))}
      </BOT_A.Instances>

      <BOT_B.Instances limit={15} range={15}>
        {[...Array(length)].map((e, j) => (
          <group position={[-j * spacing, 0, 0]} key={j}>
            <group position={[params.posB.x + B.x, params.posB.y + B.y, params.posB.z + B.z]}>
              <BOT_B.Model />
            </group>
          </group>
        ))}
      </BOT_B.Instances>
      {/*<Element url={'/Misc/ROD_B'} position={[0, -0.29, 0.05]} scale={[1, 1, space]} />*/}
    </>
  );
};

export default BOT;

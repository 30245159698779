/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useContext, createContext } from 'react';
import { useGLTF, Merged } from '@react-three/drei';

const context = createContext();
export function Instances({ children, ...props }) {
  const { nodes } = useGLTF('/assets/models/MUB-R/800-114.3/R_BOT_A-800-114.3-transformed.glb');
  const instances = useMemo(
    () => ({
      Mesh: nodes.Mesh_39010,
      Mesh1: nodes.Mesh_39010_1,
      Mesh2: nodes.Mesh_39010_2,
      Mesh3: nodes.Mesh_39010_3,
      Mesh4: nodes.Mesh_39010_4,
    }),
    [nodes],
  );
  return (
    <Merged meshes={instances} {...props}>
      {(instances) => <context.Provider value={instances} children={children} />}
    </Merged>
  );
}

export function Model(props) {
  const instances = useContext(context);
  return (
    <group {...props} dispose={null}>
      <group name="Solide1177" rotation={[0, 1.57, 0]}>
        <instances.Mesh name="Mesh_39010" />
        <instances.Mesh1 name="Mesh_39010_1" />
        <instances.Mesh2 name="Mesh_39010_2" />
        <instances.Mesh3 name="Mesh_39010_3" />
        <instances.Mesh4 name="Mesh_39010_4" />
      </group>
    </group>
  );
}

useGLTF.preload('/assets/models/MUB-R/800-114.3/R_BOT_A-800-114.3-transformed.glb');

import { FC, ReactElement } from 'react';

interface Props {
  Tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: 'base' | 'lg' | 'xl';
  align?: 'left' | 'center' | 'right';
  color?: 'primary' | 'secondary';
  weight?: 'light' | 'normal' | 'semibold' | 'bold';
  wide?: boolean;
  uppercase?: boolean;
  children: string | ReactElement | string[];
}

const Heading: FC<Props> = ({ Tag, align, size, weight, uppercase, wide, children, ...attrs }) => {
  const Element = Tag ? Tag : 'h1';

  return <Element {...attrs}>{children}</Element>;
};

export default Heading;

import { useDispatch } from 'react-redux';
import { setConfig } from '@/store/config.slice';
import { setVolume } from '@/store/volume.slice';
import { setCurrentProjectCode, setCurrentProjectId } from '@/store/user.slice'
import { useApi } from '@/hooks';
import { defaultStore } from '@/config';

const useLoadProject = () => {
  const dispatch = useDispatch();
  const { getMe } = useApi();

  const loadProject = (project: any) => {
    dispatch(setConfig(project.parameter.config));
    dispatch(setVolume(project.parameter.volume));
    dispatch(setCurrentProjectCode(project.code));
    getMe().then((res: any) => {
      if (res.data.id === project.createdBy) {
        dispatch(setCurrentProjectId(project.id));
      }
    });
  };

  const newProject = () => {
    dispatch(setConfig(defaultStore.config));
    dispatch(setVolume(defaultStore.volume));
    dispatch(setCurrentProjectId(null));
    dispatch(setCurrentProjectCode(null));
  };

  return { loadProject, newProject };
};

export default useLoadProject;

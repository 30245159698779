import { createSlice } from '@reduxjs/toolkit';

import { Vector3 } from 'three';

interface InitialState {
  isEnclosureVisible: boolean;
  isDimensionsVisible: boolean;
  dimensions: Vector3;
  unit: string;
  ARReady: boolean;
}

const initialState: InitialState = {
  isEnclosureVisible: true,
  isDimensionsVisible: false,
  dimensions: new Vector3(0, 0, 0),
  unit: 'm',
  ARReady: false,
};

const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setIsEnclosureVisible: (state, action) => {
      state.isEnclosureVisible = action.payload;
    },
    setUnit: (state, action) => {
      state.unit = action.payload;
    },
    setModelDimensions: (state, action) => {
      state.dimensions = action.payload;
    },
    setIsDimensionsVisible: (state, action) => {
      state.isDimensionsVisible = action.payload;
    },
    setARReady: (state, action) => {
      state.ARReady = action.payload;
    },
  },
});

export const {
  setIsEnclosureVisible,
  setModelDimensions,
  setUnit,
  setIsDimensionsVisible,
  setARReady,
} = miscSlice.actions;

export default miscSlice;

import { MathUtils } from 'three';
import { Align, Element } from '@/components/Webgl/Utils/';
import { Box } from '@react-three/drei';
import { useContext } from 'react';
import { EtuveContext } from '@/components/Webgl/Showcase/Model/ETUVE/ETUVE';

const FACE_A = () => {
  const { height, length, solo } = useContext(EtuveContext);

  return (
    <group position={[-0.845, 2.003, 1.39]}>
      <Element
        url={'/MUB-E/ETUVE/LOGO'}
        position={[0, height + 0.1, 0.1]}
        rotation={[0, MathUtils.degToRad(-90), 0]}
      />

      <Align alignement={[1, 1, 0]}>
        <Box position={[-1.395, -1.953, 0.01]} args={[length, height + 2.81, 0.1]} />
      </Align>

      <Align alignement={[1, 1, 0]}>
        <Box
          position={[length - 1.4, solo ? -2 : 0.797, 0.009]}
          args={[1.3, solo ? height + 2.857 : height + 0.06, 0.1]}
        />
      </Align>

      <Element
        url={'/MUB-E/ETUVE/DOOR'}
        visible={!solo}
        position={[length - 0.8, -0.775, 0.01]}
        rotation={[0, MathUtils.degToRad(90), 0]}
        scale={[-1, 1, 1]}
      />
    </group>
  );
};

export default FACE_A;

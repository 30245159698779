import { FC, useContext, useMemo } from 'react';
import { MathUtils } from 'three';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { E } from '@/config';
import MUB from '@/components/Webgl/Showcase/Elements';

const TOP: FC = () => {
  const { space, height, products, width, length } = useContext(ModelContext);

  const { params, A, B, spacing } = useMemo(() => {
    const e = E as any;
    return {
      params: e[width][products].TOP.params,
      A: e[width][products].SIDES.A,
      B: e[width][products].SIDES.B,
      spacing: e[width].spacing,
    };
  }, [width, products]);

  const { TOP } = useMemo(() => MUB.E[`${width}-${products}`], [products, width]);

  return (
    <mesh position={[0, height * 0.455 + 1, 0]}>
      <TOP.Instances limit={15} range={15}>
        {[...Array(length)].map((e, i) => (
          <mesh key={i} position={[i * spacing, 0, 0]}>
            <mesh position={[params.pos1.x + A.x, params.pos1.y + A.y, params.pos1.z + A.z]}>
              <TOP.Model />
            </mesh>
          </mesh>
        ))}
      </TOP.Instances>
      <TOP.Instances limit={15} range={15}>
        {[...Array(length)].map((e, i) => (
          <mesh key={i} position={[i * spacing, 0, 0]}>
            <mesh
              position={[params.pos2.x + B.x, params.pos2.y + B.y, params.pos2.z - space + B.z]}
              rotation={[0, MathUtils.degToRad(180), 0]}
            >
              <TOP.Model />
            </mesh>
          </mesh>
        ))}
      </TOP.Instances>
    </mesh>
  );
};

export default TOP;

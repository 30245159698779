import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Configurator from '@/pages/Configurator';
import Register from '@/components/Security/Register';
import SignUp from '@/components/Security/SignUp';
import SignIn from '@/components/Security/SignIn';
import ProjectList from '@/components/Popin/ProjectList';
import AROnboarding from '@/components/Popin/AROnboarding';
import AR from '@/pages/AR';
import { Menu, OpenProject, SaveProject } from '@/components/Popin';
import { Share } from '@/components/Utils/';
import Demo from '@/components/Security/Demo';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/configurator'} element={<Configurator />}>
          <Route path={'menu'} element={<Menu />} />
          <Route path={'register/:token'} element={<Register />} />
          <Route path={'share/:code'} element={<Share />} />
          <Route path={'sign-up'} element={<SignUp />} />
          <Route path={'sign-in'} element={<SignIn />} />
          <Route path={'project-list'} element={<ProjectList />} />
          <Route path={'open-project'} element={<OpenProject />} />
          <Route path={'save-project'} element={<SaveProject />} />
          {/* <Route path={'ar-onboarding'} element={<AROnboarding />} /> */}
          <Route path={'demo'} element={<Demo />} />
        </Route>
        <Route path={'/ar'} element={<AR />} />
        <Route
            path="*"
            element={<Navigate to="/configurator" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import { MathUtils } from 'three';
import { Align, Element } from '@/components/Webgl/Utils/';
import { useContext, useRef } from 'react';
import { EtuveContext } from '@/components/Webgl/Showcase/Model/ETUVE/ETUVE';
import {usePainter} from '@/hooks/beautify';

const CTA_OUTSIDE = () => {
  const { height, length, solo } = useContext(EtuveContext);
  const ctaRef = useRef(null!);

  const { paint } = usePainter();

  paint(ctaRef, [
    {
      name: [
        'Material_9.014',
        'Material_7.013',
        'Material_8.014',
        'Material_6.014',
        'Material_4.013',
      ],
      color: '#7d7d7d',
    },
  ]);

  return (
    <group position={[-0.37, 1.07, 2.3]} rotation={[0, MathUtils.degToRad(-90), 0]}>
      <group ref={ctaRef}>
        <Element url={'/MUB-E/ETUVE/CTA'} />
      </group>

      <Align alignement={[0, 1, 0]}>
        <Element
          url={'/MUB-E/ETUVE/TUBE_1'}
          scale={[1, height - 1.47, 1]}
          position={[0.18, 1.82, -1.185]}
        />
      </Align>

      <Element
        visible={!solo}
        position={[0.185, height + 0.75, -1.547]}
        url={'/MUB-E/ETUVE/TUBE_2'}
      />

      <Align alignement={[0, 0, -1]}>
        <Element
          visible={!solo}
          scale={[1, 1, length - 4.8]}
          position={[0.185, height + 1, -2]}
          url={'/MUB-E/ETUVE/TUBE_3'}
        />
      </Align>

      <Element
        visible={solo}
        rotation={[0, MathUtils.degToRad(90), 0]}
        position={[-0.535, height + 0.9, -1.18]}
        url={'/MUB-E/ETUVE/TUBE_4_SOLO'}
      />

      <Element
        visible={!solo}
        position={[-0.26, height + 1, -length + 2.12]}
        url={'/MUB-E/ETUVE/TUBE_4'}
      />

      <Element
        visible={!solo}
        scale={[1.01, 1.01, 1.01]}
        position={[-0.26, height + 0.655, -2.5]}
        url={'/MUB-E/ETUVE/TUBE_SUPPORT'}
      />

      <Element
        visible={!solo}
        scale={[1.01, 1.01, 1.01]}
        position={[-0.26, height + 0.655, -length + 3]}
        url={'/MUB-E/ETUVE/TUBE_SUPPORT'}
      />
    </group>
  );
};

export default CTA_OUTSIDE;

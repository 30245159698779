const S = {
  '800': {
    spacing: 2.08,
    offset: { x: 0.18, y: 0, z: 0.4 },
    BRIDGE: {
      LENGTH: {
        BASE: {
          path: '/MUB-E/ETUVE/BRIDGE/LENGTH_800',
          pos: { x: 0, y: 0, z: 0 },
        },
        SM: {
          path: '/MUB-E/ETUVE/BRIDGE/LENGTH_SM_800',
          pos: { x: 0, y: 0, z: 0 },
        },
      },
    },
    '114.3': {
      offset: { x: 0.14, y: 0, z: 0 },
      compensation: { x: 0.15, y: 0.1, z: 0.05 },
      SIDES: {
        A: { x: 0, y: 0, z: 0 },
        B: { x: 0, y: -0.006, z: 0 },
      },
      TOP: {
        path: '/MUB-S/800-114.3/TOP',
        params: {
          pos1: { x: 0, y: 0.44, z: -0.057 },
          pos2: { x: 0, y: 0.44, z: 0.007 },
        },
      },
      MID: {
        path: '/MUB-S/800-114.3/MID',
        params: {
          pos1: { x: 0, y: 0, z: -0.07 },
          pos2: { x: 0, y: 0, z: 0.02 },
        },
      },
      BOT: {
        pathA: '/MUB-S/800-114.3/BOT_A',
        pathB: '/MUB-S/800-114.3/BOT_B',
        params: {
          posA: { x: 0, y: -0.015, z: 0.012 },
          posB: { x: 0, y: 0.006, z: 0 },
        },
      },
    },
    '152.4': {
      offset: { x: 0.14, y: 0, z: 0 },
      compensation: { x: 0.15, y: 0.1, z: 0.05 },
      SIDES: {
        A: { x: 0, y: 0, z: 0.004 },
        B: { x: 0, y: -0.01, z: 0 },
      },
      TOP: {
        path: '/MUB-S/800-152.4/TOP',
        params: {
          pos1: { x: 0, y: 0.419, z: -0.051 },
          pos2: { x: -0.005, y: 0.422, z: -0.005 },
        },
      },
      MID: {
        path: '/MUB-S/800-152.4/MID',
        params: {
          pos1: { x: 0, y: -0.008, z: -0.065 },
          pos2: { x: -0.005, y: -0.008, z: 0.008 },
        },
      },
      BOT: {
        pathA: '/MUB-S/800-152.4/BOT_A',
        pathB: '/MUB-S/800-152.4/BOT_B',
        params: {
          posA: { x: 0, y: -0.03, z: 0.022 },
          posB: { x: -0, y: -0.005, z: -0.013 },
        },
      },
    },
    '226.6': {
      offset: { x: 0.14, y: 0, z: 0.02 },
      compensation: { x: 0.14, y: 0.11, z: 0.05 },
      SIDES: {
        A: { x: 0, y: -0.01, z: 0.02 },
        B: { x: 0, y: -0.025, z: 0.003 },
      },
      TOP: {
        path: '/MUB-S/800-226.6/TOP',
        params: {
          pos1: { x: 0, y: 0.421, z: -0.026 },
          pos2: { x: -0.0055, y: 0.43, z: -0.017 },
        },
      },
      MID: {
        path: '/MUB-S/800-226.6/MID',
        params: {
          pos1: { x: -0, y: -0.008, z: -0.036 },
          pos2: { x: -0.005, y: -0, z: -0.008 },
        },
      },
      BOT: {
        pathA: '/MUB-S/800-226.6/BOT_A',
        pathB: '/MUB-S/800-226.6/BOT_B',
        params: {
          posA: { x: -0.01, y: -0.02, z: 0.04 },
          posB: { x: -0.0012, y: 0.02, z: -0.028 },
        },
      },
    },
  },
  '1330': {
    spacing: 3.46,
    offset: { x: 1, y: 0.23, z: 0 },
    BRIDGE: {
      LENGTH: {
        BASE: {
          path: '/MUB-E/ETUVE/BRIDGE/LENGTH_1330',
          pos: { x: 0.69, y: 0.07, z: 0.05 },
        },
        SM: {
          path: '/MUB-E/ETUVE/BRIDGE/LENGTH_SM_1330',
          pos: { x: 0.69, y: -0.022, z: -0.004 },
        },
      },
    },
    '114.3': {
      offset: { x: 0, y: -0.03, z: 0.57 },
      compensation: { x: 0.58, y: 0.53, z: -0.65 },
      SIDES: {
        A: { x: 0, y: 0.025, z: 0.08 },
        B: { x: 0.07, y: 0.005, z: 0.19 },
      },
      TOP: {
        path: '/MUB-S/1330-114.3/TOP',
        params: {
          pos1: { x: 0.008, y: 0.505, z: 0.016 },
          pos2: { x: -0.008, y: 0.505, z: -0.014 },
        },
      },
      MID: {
        path: '/MUB-S/1330-114.3/MID',
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        pathA: '/MUB-S/1330-114.3/BOT_A',
        pathB: '/MUB-S/1330-114.3/BOT_B',
        params: {
          posA: { x: 0.005, y: -0.023, z: 0.064 },
          posB: { x: -0.033, y: -0.02, z: -0.024 },
        },
      },
    },
    '152.4': {
      offset: { x: 0, y: -0.02, z: 0.55 },
      compensation: { x: 0.575, y: 0.54, z: -0.52 },
      SIDES: {
        A: { x: 0, y: 0.01, z: 0.08 },
        B: { x: 0.07, y: -0.01, z: 0.15 },
      },
      TOP: {
        path: '/MUB-S/1330-152.4/TOP',
        params: {
          pos1: { x: 0.008, y: 0.513, z: 0.003 },
          pos2: { x: -0.007, y: 0.51, z: -0.003 },
        },
      },
      MID: {
        path: '/MUB-S/1330-152.4/MID',
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        pathA: '/MUB-S/1330-152.4/BOT_A',
        pathB: '/MUB-S/1330-152.4/BOT_B',
        params: {
          posA: { x: 0.004, y: -0.028, z: 0.063 },
          posB: { x: -0.031, y: -0.029, z: -0.014 },
        },
      },
    },
    '226.6': {
      offset: { x: -0.01, y: -0.01, z: 0.55 },
      compensation: { x: 0.61, y: 0.56, z: 0.23 },
      SIDES: {
        A: { x: 0, y: -0.01, z: 0.09 },
        B: { x: 0.07, y: -0.01, z: 0.15 },
      },
      TOP: {
        path: '/MUB-S/1330-226.6/TOP',
        params: {
          pos1: { x: 0.01, y: 0.52, z: 0 },
          pos2: { x: -0.015, y: 0.5, z: -0.013 },
        },
      },
      MID: {
        path: '/MUB-S/1330-226.6/MID',
        params: {
          pos1: { x: -0.004, y: 0, z: 0 },
          pos2: { x: -0.005, y: -0.02, z: -0.01 },
        },
      },
      BOT: {
        pathA: '/MUB-S/1330-226.6/BOT_A',
        pathB: '/MUB-S/1330-226.6/BOT_B',
        params: {
          posA: { x: 0, y: -0.02, z: 0.068 },
          posB: { x: -0.04, y: -0.021, z: -0.026 },
        },
      },
    },
  },
};

export default S;

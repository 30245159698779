const R = {
  '800': {
    spacing: 2.08,
    offset: { x: -0.15, y: 0, z: 0.02 },
    BRIDGE: {
      LENGTH: {
        path: '/MUB-R/BRIDGE-EXT/LENGTH_800',
        pos: { x: 0.15, y: 0.702, z: 0.125 },
      },
      SUPPORT: {
        offset: { x: 1.2, y: 0, z: 0 },
      },
    },
    GLASS: {
      LENGTH: {
        path: '/MUB-R/GLASS/LENGTH_800',
        pos: { x: 0, y: 0, z: 0 },
      },
    },
    '114.3': {
      offset: { x: 0, y: 0, z: 0.085 },
      compensation: { x: 0.005, y: 0, z: -0.27 },
      SIDES: {
        A: { x: 0, y: 0, z: 0 },
        B: { x: 0, y: 0, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: 0, z: 0 },
          posB: { x: 0, y: 0, z: 0 },
        },
      },
    },
    '152.4': {
      offset: { x: 0, y: 0, z: 0.09 },
      compensation: { x: 0.005, y: 0, z: -0.27 },
      SIDES: {
        A: { x: 0, y: 0, z: 0 },
        B: { x: 0, y: 0, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: 0, z: 0 },
          posB: { x: 0, y: 0, z: 0 },
        },
      },
    },
    '226.6': {
      offset: { x: 0, y: -0.01, z: -0.49 },
      compensation: { x: 0, y: 0, z: 0.2 },
      SIDES: {
        A: { x: 0, y: 0, z: 0 },
        B: { x: 0, y: 0, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: 0, z: 0 },
          posB: { x: 0, y: 0, z: 0 },
        },
      },
    },
  },
  '1330': {
    spacing: 3.46,
    offset: { x: 0.65, y: 0.2, z: -0.52 },
    BRIDGE: {
      LENGTH: {
        path: '/MUB-R/BRIDGE-EXT/LENGTH_1330',
        pos: { x: 0.83, y: 0.702, z: 0.125 },
      },
      SUPPORT: {
        offset: { x: 1.2, y: 0, z: 0 },
      },
    },
    GLASS: {
      LENGTH: {
        path: '/MUB-R/GLASS/LENGTH_1330',
        pos: { x: -6.553, y: 0.126, z: 0.32 },
      },
    },
    '114.3': {
      offset: { x: 0, y: 0, z: 0.008 },
      compensation: { x: 0.415, y: -0.005, z: 0.14 },
      SIDES: {
        A: { x: 0, y: 0, z: 0 },
        B: { x: 0, y: 0, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: 0, z: 0 },
          posB: { x: 0, y: 0, z: 0 },
        },
      },
    },
    '152.4': {
      offset: { x: 0, y: 0.05, z: 0 },
      compensation: { x: 0.42, y: 0.01, z: 0.15 },
      SIDES: {
        A: { x: 0, y: 0, z: 0 },
        B: { x: 0, y: 0, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: 0, z: 0 },
          posB: { x: 0, y: 0, z: 0 },
        },
      },
    },
    '226.6': {
      offset: { x: 0, y: 0.04, z: 1.07 },
      compensation: { x: 0.415, y: 0.01, z: -0.77 },
      SIDES: {
        A: { x: 0, y: 0, z: 0 },
        B: { x: 0, y: 0, z: 0 },
      },
      TOP: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      MID: {
        params: {
          pos1: { x: 0, y: 0, z: 0 },
          pos2: { x: 0, y: 0, z: 0 },
        },
      },
      BOT: {
        params: {
          posA: { x: 0, y: 0, z: 0 },
          posB: { x: 0, y: 0, z: 0 },
        },
      },
    },
  },
};

export default R;

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useContext, createContext } from 'react';
import { useGLTF, Merged, useAnimations } from '@react-three/drei';

const context = createContext();
export function Instances({ children, ...props }) {
  const { nodes } = useGLTF('/assets/models/MUB-E/800-114.3/E_BOT_B-800-114.3-transformed.glb');
  const instances = useMemo(
    () => ({
      Mesh: nodes.Mesh_40001,
      Mesh1: nodes.Mesh_40001_1,
      Mesh2: nodes.Mesh_40001_2,
      Mesh3: nodes.Mesh_40001_3,
      Mesh4: nodes.Mesh_40001_4,
    }),
    [nodes],
  );
  return (
    <Merged meshes={instances} {...props}>
      {(instances) => <context.Provider value={instances} children={children} />}
    </Merged>
  );
}

export function Model(props) {
  const instances = useContext(context);
  const group = useRef();

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="BOT_B" rotation={[0, 1.57, 0]}>
          <instances.Mesh name="Mesh_40001" />
          <instances.Mesh1 name="Mesh_40001_1" />
          <instances.Mesh2 name="Mesh_40001_2" />
          <instances.Mesh3 name="Mesh_40001_3" />
          <instances.Mesh4 name="Mesh_40001_4" />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/assets/models/MUB-E/800-114.3/E_BOT_B-800-114.3-transformed.glb');

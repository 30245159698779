import { FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsVolumeOutOfBounds } from '@/store/volume.slice';
import { unitConverter } from '@/functions';
import { RootState } from '@/store';

import { Box3, BoxGeometry, Mesh, Vector3 } from 'three';
import { Align } from '@/components/Webgl/Utils/';
import { Shadow } from '@react-three/drei';

const VolumeVisualizer: FC = () => {
  const dispatch = useDispatch();
  const shadowRef = useRef<Mesh>(null!);

  // Rerender volume visualizer when config changes

  useSelector((state: RootState) => state.config);

  const { isVisible, isOutOfBounds, size } = useSelector((state: RootState) => state.volume);
  const unit = useSelector((state: RootState) => state.misc.unit);
  const isLoading = useSelector((state: RootState) => state.ui.isLoading);
  const dimensions = useSelector((state: RootState) => state.misc.dimensions);

  const volumeVisualizerRef = useRef<Mesh>(null!);

  const { x, y, z } = useMemo(
    () => ({
      x: unitConverter(unit, 'to', size.x),
      y: unitConverter(unit, 'to', size.y),
      z: unitConverter(unit, 'to', size.z),
    }),
    [size, unit],
  );

  const cube = new BoxGeometry(x, y, z);

  useEffect(() => {
    
    // Timeout to prevent clipping effect

    setTimeout(() => {
      const boundsVolume = new Vector3();
      const volumeBoundingBox = new Box3().setFromObject(volumeVisualizerRef.current);
      volumeBoundingBox.getSize(boundsVolume);

      shadowRef.current.scale.set(dimensions.x * 1.2, dimensions.y, dimensions.z);
      shadowRef.current.position.set(dimensions.x / 2, 0, -dimensions.z / 2);

      dispatch(
        setIsVolumeOutOfBounds(
          boundsVolume.x < dimensions.x ||
            boundsVolume.y < dimensions.y ||
            boundsVolume.z < dimensions.z,
        ),
      );
    }, 200);
  }, [size, dimensions, isLoading, isVisible, dispatch]);

  return (
    <mesh position={[0, 0.05, 0]}>
      <Align alignement={[1, 1, -1]}>
        <mesh ref={volumeVisualizerRef} visible={isVisible}>
          <boxGeometry args={[x, y, z]} />
          <meshStandardMaterial
            transparent={true}
            color={isOutOfBounds ? 'red' : 'black'}
            opacity={isOutOfBounds ? 0.4 : 0.1}
            depthWrite={false}
          />
          <lineSegments onUpdate={(line) => line.computeLineDistances()}>
            <edgesGeometry attach="geometry" args={[cube]} />
            <lineBasicMaterial linewidth={5} color={'black'} />
          </lineSegments>
        </mesh>
      </Align>
      <Shadow opacity={0.15} ref={shadowRef} />
    </mesh>
  );
};

export default VolumeVisualizer;

import { FC, useState } from 'react';
import { Input } from '@/components/Base/Input';
import Icon from '@/components/Base/Icon';
import * as S from '@/components';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Popin from '@/components/Popin';
import {useApi} from '@/hooks';

const SignUp: FC = () => {
  const [message, setMessage] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const { signUp } = useApi();

  return (
    <Popin
      isShowing={isVisible}
      hide={() => setIsVisible(false)}
      onHide={() => navigate('/configurator')}
    >
      <div className={`flex flex-col gap-y-lg px-6 ${message ? 'pb-4 pt-4' : 'pb-6 pt-10'}`}>
        {!message ? (
          <>
            <div className={'flex items-center justify-center gap-x-md'}>
              <Icon name={'mail'} size={28} />
              <S.Heading Tag={'h3'} uppercase={false} size={'xl'} weight={'bold'}>
                Enter your Email
              </S.Heading>
            </div>
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={(e, actions) => {
                signUp(e)
                  .then((res) => {
                    setMessage(res.data.message);
                  })
                  .catch(() => {
                    actions.setFieldError('email', 'Email already in use');
                  });
              }}
              validationSchema={object().shape({
                email: string().email().required(),
              })}
              validateOnChange={false}
            >
              {({ values, handleChange, submitForm, errors }: any) => (
                <>
                  <div className={'flex flex-col gap-y-1'}>
                    <Input
                      name={'email'}
                      value={values.email}
                      onChange={handleChange}
                      errors={errors.email}
                      placeholder={'email@example.com'}
                    />
                    <div className={'text-red-600'}>{errors.email}</div>
                  </div>
                  <div className={'flex justify-center'}>
                    <S.Button
                      variant={'primary'}
                      content={'Submit'}
                      fill={true}
                      onClick={submitForm}
                    />
                  </div>
                </>
              )}
            </Formik>
            <div className={'flex justify-center gap-x-xs'}>
              <S.Text>Already have an account ?</S.Text>
              <Link to={'/configurator/sign-in'}>
                <S.Text color={'secondary'}>Sign In</S.Text>
              </Link>
            </div>
          </>
        ) : (
          <div className={'text-center text-lg first-letter:uppercase'}>{message}</div>
        )}
      </div>
    </Popin>
  );
};

export default SignUp;

import { cloneElement, FC, Fragment, ReactElement, useState } from 'react';
import { RootState } from '@/store';
import { useSelector } from 'react-redux';

import Prompt from '@/components/Sidebar/Prompt';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInstance } from 'swiper/types';
import Steps from '@/components/Sidebar/Steps';

interface Props {
  modules: (ReactElement | ReactElement[])[];
}

const SiderbarDesktop: FC<Props> = ({ modules }) => {
  const { demo, token } = useSelector((state: RootState) => state.user);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [SwiperInstance, setSwiperInstance] = useState<SwiperInstance>();

  return (
    <div
      className={
        'pointer-events-auto relative hidden h-full w-full overflow-y-auto bg-white shadow-base lg:block'
      }
    >
      <div className={'flex flex-col gap-y-xl pt-xl items-center h-full'}>

        <Steps 
          currentStep={currentStep} 
          modules={modules} 
          onClick={(index) => {
            setCurrentStep(index);
            SwiperInstance?.slideTo(index);
          }
        } />

        <Swiper
          className={'h-full w-full'}
          modules={[Navigation]}
          navigation
          threshold={10}
          onInit={(swiper) => {
            setSwiperInstance(swiper);
          }}
          onTransitionStart={(swiper) => {
            setCurrentStep(swiper.activeIndex);
          }}
        >

          {modules.map((module, index) => (
            <SwiperSlide key={index} className={'px-2xl overflow-y-auto'}>
              <div className="flex flex-col gap-y-lg" key={index}>
                {Array.isArray(module) ? (
                  <>
                    {module.map((subModule, index) => (
                      <Fragment key={index}>
                        {cloneElement(subModule)}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {cloneElement(module)}
                  </>
                )}
              </div>
            </SwiperSlide>
          ))}

          {(!token?.length && !demo) && (
            <SwiperSlide className={'px-2xl'}>
              <div className={'h-full flex items-center'}>
                <Prompt />
              </div>
            </SwiperSlide>
          )}

        </Swiper>

        <div className={'h-[60px] w-full'} />
      </div>
    </div>
  );
};

export default SiderbarDesktop;

import { FC, ReactElement, useEffect, useState } from 'react';

interface Props {
  children: ReactElement | ReactElement[];
}

const XR8: FC<Props> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const load = () => {
      setIsLoaded(true);
    };

    window.addEventListener('xrloaded', load);

    return () => {
      window.removeEventListener('xrloaded', load);
    };
  });

  return <>{isLoaded && children}</>;
};

export default XR8;

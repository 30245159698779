import { FC, useContext } from 'react';
import { Align, Element } from '@/components/Webgl/Utils';
import { Box } from '@react-three/drei';
import { ModelContext } from '@/components/Webgl/Showcase/Model';

interface Props {
  width: number;
  height: number;
}

const FACE_A: FC<Props> = ({ width, height }) => {
  const { space } = useContext(ModelContext);

  return (
    <group>
      <Element url={'/MUB-S/ENCEINTE/DOOR'} />
      <Align alignement={[0, 1, 0]}>
        <Box position={[-0.0101, -1.05, -0.49]} args={[0.12, height, 0.18]} />
      </Align>
      <Align alignement={[0, 1, 0]}>
        <Box position={[-0.0102, 1.05, 0]} args={[0.12, height - 2.1, 1]} />
      </Align>

      <Align alignement={[0, 1, -1]}>
        <Box position={[-0.0103, -1.05, width - 0.8]} args={[0.12, height, 1.3]} />
      </Align>

      <Align alignement={[0, 1, 1]}>
        <Box position={[-0.0104, -1.05, 0.4]} args={[0.12, height, width - 2.2 - space]} />
      </Align>

      <Align alignement={[0, 1, 1]}>
        <Box position={[-0.0105, 0.15, 2.7]} args={[0.12, height - 1.23, space + 0.6]} />
      </Align>

      <Align alignement={[0, 1, 1]}>
        <Box position={[-0.0106, -1.055, 2.7]} args={[0.12, 0.89, space + 0.6]} />
      </Align>
      <Element url={'/MUB-E/ETUVE/LOGO'} position={[0.06, 1.8, 0.8]} />
    </group>
  );
};

export default FACE_A;

import { FC, useContext, useEffect, useRef } from 'react';
import { Align, Element } from '@/components/Webgl/Utils';
import { MathUtils, Mesh } from 'three';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { R } from '@/config';

const GLASS: FC<any> = ({ width, length }) => {
  const modelContext = useContext(ModelContext);
  const modelRef = useRef(null!);

  const partLength: any = {
    800: 2.08,
    1330: 3.458,
  };

  const { LENGTH } = (R as any)[modelContext.width].GLASS;

  useEffect(() => {
    (modelRef.current as any).traverse((child: any) => {
      if (child instanceof Mesh) {
        child.material.roughness = 0.5;
        child.material.metalness = 1;
      }
    });
  });

  return (
    <group ref={modelRef} position={[-0.14, -2.15, 0]}>
      <Element url={'/MUB-R/GLASS/DOOR'} position={[length - 0.13, -0.21, -width - 0.98]} />
      <Element position={[length + 0.266, -0.112, 0]} url={'/MUB-R/GLASS/CORNER'} />
      <Element
        url={'/MUB-R/GLASS/CORNER'}
        rotation={[0, MathUtils.degToRad(180), 0]}
        position={[-2.089, -0.112, 0]}
      />
      <Element
        url={'/MUB-R/GLASS/CORNER'}
        rotation={[0, MathUtils.degToRad(180), 0]}
        position={[-2.089, -0.112, -width - 0.7]}
      />

      <Align alignement={[0, 0, -1]}>
        <Element
          url={'/MUB-R/GLASS/WIDTH'}
          position={[length + 0.344, -0.16, 0]}
          scale={[1, 1, width + 0.7]}
        />
        <Element
          url={'/MUB-R/GLASS/WIDTH'}
          position={[-2.15, -0.16, 0]}
          scale={[1, 1, width + 0.7]}
        />
      </Align>

      <group position={[LENGTH.pos.x, LENGTH.pos.y, LENGTH.pos.z]}>
        {[...Array(modelContext.length)].map((e, i) => (
          <Element
            url={LENGTH.path}
            key={i}
            position={[i * partLength[modelContext.width], 0, 0]}
          />
        ))}
      </group>

      <group position={[LENGTH.pos.x, LENGTH.pos.y, LENGTH.pos.z]}>
        {[...Array(modelContext.length)].map((e, i) => (
          <Element
            url={LENGTH.path}
            key={i}
            position={[i * partLength[modelContext.width], 0, -width - 0.7]}
          />
        ))}
      </group>
    </group>
  );
};

export default GLASS;

import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isFullscreen: boolean;
  isSidebarOpen: boolean;
  isLoading: boolean;
}

const initialState: InitialState = {
  isSidebarOpen: false,
  isLoading: false,
  isFullscreen: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
    toggleFullscreen: (state) => {
      state.isFullscreen = !state.isFullscreen;
    },
  },
});

export const { setIsSidebarOpen, setIsLoading, toggleFullscreen } = uiSlice.actions;

export default uiSlice;

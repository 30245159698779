import { Button } from '@/components/Base/Button';
import tw from 'twin.macro';
import { Props } from '@/components/Base/Button/Button';
import styled from 'styled-components';

const StyledButton = styled(Button)<Props>`
  ${tw`flex h-[40px] w-max cursor-pointer text-md uppercase`}
  
  ${(props) => props.full && tw`!w-full`};

  ${(props) => (props.icon && props.content && props.shadow) && tw`shadow-sm shadow-black/10`}

  .button__icon {
    ${tw`flex h-full w-[40px] items-center justify-center transition text-primary-base bg-white`};
    
    ${(props) => props.rounded && tw`rounded-[10px]`};
      
    ${(props) => {
      switch (props.variant) {
        case 'primary':
          return tw`bg-primary-base text-white`;
        case 'secondary':
          return tw`bg-secondary-base text-white`;
        case 'gray':
          return tw`bg-gray-base text-primary-base bg-gray-base text-primary-base`;
        case 'danger':
          return tw`bg-red-600 text-white`;
      }
    }}

    ${(props) => (!props.content && props.shadow) && tw`shadow-sm shadow-black/10`};
  }

  .button__content {
    ${tw`flex h-full items-center bg-white font-medium transition text-primary-base`}
    
    ${(props) => {
      if (!props.icon) {
        if (props.shadow) {
          return tw`px-md shadow-sm shadow-black/10`;
        } else {
          return tw`px-md`;
        }
      }
      return tw`pr-md`;
    }}

    ${(props) => props.full && tw`!w-full justify-center`};

    ${(props) => props.split && tw`pl-md`};
    
    ${(props) => props.border && tw`border`};
    
    
    ${(props) => {
      switch (props.variant) {
        case 'primary':
          if (props.fill) {
            return tw`border-primary-base bg-primary-base text-white`;
          }
          return tw`group-hover:bg-primary-base border-primary-base text-primary-base`;
        case 'secondary':
          if (props.fill) {
            return tw`border-secondary-base bg-secondary-base text-white`;
          }
          return tw`border-secondary-base text-secondary-base`;
        case 'gray':
          return tw`border-secondary-base bg-gray-base text-primary-base`;
      }
    }}}
    
  &:hover {
    .button__icon {
      ${tw`bg-primary-base text-white`}
      
      ${(props) => {
        switch (props.variant) {
          case 'secondary':
            return tw`bg-secondary-base`;
          case 'gray':
            return tw`bg-gray-dark text-primary-base`;
        }
      }}
    }
    .button__content {
      ${tw`bg-primary-base text-white`}
      
      ${(props) => {
        switch (props.variant) {
          case 'primary':
            if (props.fill) {
              return tw`bg-primary-base/50 `;
            }
            return tw`bg-primary-base text-white`;
          case 'secondary':
            if (props.fill) {
              return tw`bg-secondary-base/50`;
            }
            return tw`bg-secondary-base`;
          case 'gray':
            return tw`bg-gray-dark text-primary-base`;
        }
      }}
`;

export default StyledButton;

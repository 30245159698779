import { FC, ReactElement, useEffect, useRef } from 'react';
import { Group } from 'three';
import { betterBox3 } from '@/functions';

interface Props {
  alignement: any;
  children: ReactElement | ReactElement[];
}

const Align: FC<Props & JSX.IntrinsicElements['group']> = ({ alignement, children, ...attrs }) => {
  const groupRef = useRef<Group>(null!);

  useEffect(() => {
    const boundsVolume = betterBox3(groupRef.current);

    const translation = {
      x: 0,
      y: 0,
      z: 0,
    };

    if (alignement[0] === 1) translation.x = boundsVolume.x / 2;
    if (alignement[0] === -1) translation.x = -boundsVolume.x / 2;
    if (alignement[1] === 1) translation.y = boundsVolume.y / 2;
    if (alignement[1] === -1) translation.y = -boundsVolume.y / 2;
    if (alignement[2] === 1) translation.z = boundsVolume.z / 2;
    if (alignement[2] === -1) translation.z = -boundsVolume.z / 2;

    groupRef.current.position.set(translation.x, translation.y, translation.z);
  });

  return (
    <>
      <group {...attrs} ref={groupRef}>
        {children}
      </group>
    </>
  );
};

export default Align;

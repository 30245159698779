import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Box3, MathUtils, Vector3 } from 'three';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import CLIM from './CLIM';
import SUPPORT from '@/components/Webgl/Showcase/Model/MUB-S/ENCEINTE/SUPPORT';
import SIDE from '@/components/Webgl/Showcase/Model/MUB-S/ENCEINTE/SIDE';
import { S } from '@/config';
import TOP from '@/components/Webgl/Showcase/Model/MUB-S/ENCEINTE/TOP';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import FACE_A from '@/components/Webgl/Showcase/Model/MUB-S/ENCEINTE/FACE-A';
import FACE_B from '@/components/Webgl/Showcase/Model/MUB-S/ENCEINTE/FACE-B';
import BRIDGE from '@/components/Webgl/Showcase/Model/MUB-S/ENCEINTE/BRIDGE';
import {useGlassify} from '@/hooks/beautify';
import CHASSIS from '@/components/Webgl/Showcase/Model/MUB-S/ENCEINTE/CHASSIS';
import { useBeautifyEnceinte } from '@/hooks/beautify';
import { setModelDimensions } from '@/store/misc.slice';

const ENCEINTE: FC<any> = ({ mub }) => {
  const modelContext = useContext(ModelContext);
  const { compensation } = (S as any)[modelContext.width][modelContext.products];
  const isEnclosureVisible = useSelector((state: RootState) => state.misc.isEnclosureVisible);
  const dispatch = useDispatch();

  const meshRef = useRef<any>(null!);
  const blocRef = useRef<any>(null!);

  const [config, setConfig] = useState<any>({
    width: 0,
    height: 0,
    length: 0,
    solo: false,
  });

  useEffect(() => {
    const boundsVolume = new Vector3();
    const volumeBoundingBox = new Box3().setFromObject(mub.current);
    volumeBoundingBox.getSize(boundsVolume);

    setConfig({
      length: boundsVolume.x + 2.5 + compensation.x,
      width: boundsVolume.z + 3.13 + compensation.z,
      height: boundsVolume.y + 0.62 + compensation.y,
      solo: modelContext['length'] === 1,
    });
  }, [mub, modelContext, compensation]);

  useEffect(() => {
    const dimensions = new Vector3(config.length, config.height + 0.6, config.width);
    dispatch(setModelDimensions(dimensions));
  }, [config, dispatch]);

  useBeautifyEnceinte(blocRef);

  // Glassmaker

  useGlassify(blocRef, isEnclosureVisible);

  return (
    <>
      <mesh ref={meshRef} position={[0, 0, 0.4]}>
        {modelContext.height > 4 && <BRIDGE config={config} />}

        <mesh ref={blocRef}>
          <mesh position={[-2, 0.1, 3.94]}>
            <SIDE height={config.height} length={config.length} />
          </mesh>
          <mesh position={[-2, 0.1, -config.width + 4.06]}>
            <SIDE height={config.height} length={config.length} />
          </mesh>

          <mesh position={[-2, config.height + 0.12, 4]}>
            <TOP width={config.width} length={config.length} />
          </mesh>

          <mesh position={[-1.95, 1.15, 3.3]} rotation={[0, MathUtils.degToRad(180), 0]}>
            <FACE_A width={config.width} height={config.height} />
          </mesh>

          <mesh
            position={[config.length - 2.07, 1.15, 3.3]}
            rotation={[0, MathUtils.degToRad(180), 0]}
          >
            <FACE_B width={config.width} height={config.height} />
          </mesh>
        </mesh>

        <mesh position={[-2, 0.04, 4]}>
          <SUPPORT width={config.width} length={config.length} />
        </mesh>

        <mesh rotation={[0, MathUtils.degToRad(180), 0]} position={[-1.5, 0.69, 0.53]}>
          <CHASSIS space={modelContext.space - 0.3} />
        </mesh>

        <mesh position={[config.length - 2.5, 0.83, 0.84 - modelContext.space]}>
          <CHASSIS space={modelContext.space - 0.3} />
        </mesh>

        <mesh position={[0.28, 1.5, 2.2]}>
          <CLIM length={config.length} height={modelContext.height} solo={config.solo} />
        </mesh>
      </mesh>
    </>
  );
};

export default ENCEINTE;

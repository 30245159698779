import SceneControls from '@/components/Webgl/Showcase/SceneControls';
import Model from '@/components/Webgl/Showcase/Model';
import { FC } from 'react';
import Presentation from '@/components/Webgl/Showcase/Presentation';
import { Performance } from '@/components/Webgl/Utils';

const Showcase: FC = () => {
  return (
    <>
      <Performance />
      <Presentation />
      <SceneControls />
      <Model />
      {/* <Reflector /> */}
    </>
  );
};

export default Showcase;

import { MutableRefObject, useEffect } from 'react';
import { Group } from 'three';

const useGlassify = (mesh: MutableRefObject<Group>, isVisible: boolean) => {
  useEffect(() => {
    if (mesh.current) {
      mesh.current.traverse((child: any) => {
        if (child.isMesh) {
          if (!isVisible) {
            child.renderOrder = 0;
            child.material.colorWrite = false;
            child.material.opacity = 0.15;
            child.material.transparent = true;
          }
        }
      });

      if (!isVisible) {
        mesh.current.traverse((child: any) => {
          if (child.isMesh) {
            child.renderOrder = 1;
            child.material.colorWrite = true;
          }
        });
      }
    }
  });
};

export default useGlassify;

import { configureStore } from '@reduxjs/toolkit';

import uiSlice from '@/store/ui.slice';
import miscSlice from '@/store/misc.slice';
import configSlice from '@/store/config.slice';
import volumeSlice from '@/store/volume.slice';
import userSlice from '@/store/user.slice';
import { persistStore } from 'redux-persist';
import arSlice from '@/store/ar.slice';

export const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    misc: miscSlice.reducer,
    config: configSlice.reducer,
    volume: volumeSlice.reducer,
    user: userSlice.reducer,
    ar: arSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { FC, useState } from 'react';
import Popin from '@/components/Popin/Popin';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import {useLoadProject} from '@/hooks';
import {useApi} from '@/hooks';
import * as S from '@/components';

const ProjectList: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const projects = useSelector((state: RootState) => state.user.projects);
  const { loadProject, newProject } = useLoadProject();
  const { deleteProject } = useApi();

  return (
    <Popin
      width={1000}
      isShowing={isVisible}
      hide={() => setIsVisible(false)}
      onHide={() => navigate('/configurator')}
    >
      <div className={'flex h-screen h-[600px] flex-col gap-y-lg px-6 py-14 lg:w-full'}>
        <div className={'flex items-center justify-center gap-x-md'}>
          <S.Heading Tag={'h3'} size={'xl'} weight={'bold'} color={'secondary'}>
            My Projects
          </S.Heading>
        </div>
        <div className={'h-full overflow-auto '}>
          {projects.length ? (
            projects.map((project: any) => (
              <div
                key={project.code}
                className={'flex justify-between py-4 odd:bg-white even:bg-slate-50'}
              >
                <div className="flex select-text items-center whitespace-nowrap pl-4 pr-3 sm:pl-6">
                  <S.Heading Tag={'h4'} color={'secondary'} weight={'bold'} size={'lg'}>
                    {project.code}
                  </S.Heading>
                </div>
                <div className={'flex w-full items-center px-xl'}>
                  <S.Text align={'justify'}>{project.description}</S.Text>
                </div>
                <div className={'flex items-center gap-x-sm pr-4 sm:pr-6'}>
                  <S.Button
                    variant={'primary'}
                    fill={true}
                    content={'Load'}
                    onClick={() => {
                      loadProject(project);
                      setIsVisible(false);
                    }}
                  />
                  <S.Button
                    variant={'secondary'}
                    fill={true}
                    content={'Delete'}
                    onClick={() => {
                      deleteProject({ id: project.id });
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className={'flex h-full items-center justify-center'}>
              <S.Heading Tag={'h4'} weight={'light'} size={'lg'}>
                You don't have any projects yet
              </S.Heading>
            </div>
          )}
        </div>
        <div className={'flex justify-center gap-x-lg'}>
          <S.Button
            variant={'primary'}
            fill={true}
            content={'Start new project'}
            onClick={() => {
              newProject();
              navigate('/configurator');
            }}
          />
          <S.Button
            variant={'gray'}
            fill={true}
            content={'Open with a code'}
            onClick={() => navigate('/configurator/open-project')}
          />
        </div>
      </div>
    </Popin>
  );
};

export default ProjectList;

import styled from 'styled-components';
import Text from './';
import { ComponentProps } from 'react';
import tw from 'twin.macro';

const StyledText = styled(Text).attrs({ className: 'baseline-fix' })<ComponentProps<typeof Text>>`
  ${(props) => sizes[props.size!]}
  ${(props) => colors[props.color!]} 
  ${(props) => weights[props.weight!]}
  ${(props) => aligns[props.align!]}
  
  ul {
    li {
      ${() => tw`list-disc`}
      ${() => tw`ml-6`}
    }
  }
`;

const sizes = {
  base: tw`text-md`,
  sm: tw`text-sm`,
  xs: tw`text-xs`,
};

const weights = {
  normal: tw`font-normal`,
  light: tw`font-light`,
};

const aligns = {
  left: tw`text-left`,
  center: tw`text-center`,
  right: tw`text-right`,
  justify: tw`text-justify`,
};

const colors = {
  primary: tw`text-primary-base`,
  secondary: tw`text-secondary-base`,
  gray: tw`text-gray-base`,
};

StyledText.defaultProps = {};

export default StyledText;

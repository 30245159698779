import { FC, useContext, useEffect, useRef } from 'react';
import { Group, MathUtils, Vector2 } from 'three';
import { R } from '@/config';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { Align, Element } from '@/components/Webgl/Utils/';
import {usePainter} from '@/hooks/beautify';
import { Box } from '@react-three/drei';

const BRIDGE_EXT: FC<any> = ({ length, height, width }) => {
  const modelContext = useContext(ModelContext);
  const groupRef = useRef<Group>(null!);

  const partLength: Record<string, number> = {
    800: 2.08,
    1330: 3.458,
  };

  const { paint } = usePainter();

  const { LENGTH, SUPPORT } = (R as any)[modelContext.width].BRIDGE;

  useEffect(() => {
    paint(groupRef, [
      {
        name: 'all',
        options: {
          roughness: 0,
          metalness: 0.8,
          normalScale: new Vector2(0.005, 0.005),
        },
      },
    ]);
  });

  return (
    <group ref={groupRef}>
      <group position={[-0.14, height - 6.9, 0.55]}>
        <group position={[0, 0, 0]}>
          {[...Array(modelContext.length)].map((e, i) => (
            <group
              key={i}
              position={[
                partLength[modelContext.width] * i + LENGTH.pos.x,
                LENGTH.pos.y + 0.01,
                -0.845 + LENGTH.pos.z,
              ]}
              scale={[1, 1, -1]}
            >
              <Element url={LENGTH.path} />
            </group>
          ))}
          <group position={[length - 0.05, 0.66, -0.802]}>
            <Element url={'/MUB-R/BRIDGE-EXT/CORNER'} />
            <group position={[-0.33, 0, -0.15]}>
              <Align alignement={[0, -1, 0]}>
                <Box args={[0.1, height - 2.9, 0.1]} />
              </Align>
              <Element url={'/Misc/FEET'} position={[0, -height + 2.8, 0]} />
            </group>
          </group>

          <group position={[length - 0.05, 0.66, -width - 0.94]}>
            <Element scale={[1, 1, -1]} url={'/MUB-R/BRIDGE-EXT/CORNER'} />
            <group position={[-0.33, 0, 0.15]}>
              <Align alignement={[0, -1, 0]}>
                <Box args={[0.1, height - 2.9, 0.1]} />
              </Align>
              <Element url={'/Misc/FEET'} position={[0, -height + 2.8, 0]} />
            </group>
          </group>

          <group position={[-1.475, 0.66, -width - 0.94]}>
            <Element rotation={[0, MathUtils.degToRad(180), 0]} url={'/MUB-R/BRIDGE-EXT/CORNER'} />
            <group position={[0.33, 0, 0.15]}>
              <Align alignement={[0, -1, 0]}>
                <Box args={[0.1, height - 2.9, 0.1]} />
              </Align>
              <Element url={'/Misc/FEET'} position={[0, -height + 2.8, 0]} />
            </group>
          </group>

          <group position={[-1.49, 0.72, -0.73]}>
            <Element url={'/MUB-R/BRIDGE-EXT/LADDER'} />
            <group position={[0.35, 0, -0.22]}>
              <Align alignement={[0, -1, 0]}>
                <Box args={[0.1, height - 2.9, 0.1]} />
              </Align>
              <Element url={'/Misc/FEET'} position={[0, -height + 2.8, 0]} />
            </group>
          </group>
        </group>

        {[...Array(modelContext.length - 1)].map((e, i) => (
          <group
            key={i}
            position={[
              i * partLength[modelContext.width] + SUPPORT.offset.x,
              -1.58 + SUPPORT.offset.y,
              -0.85 + SUPPORT.offset.z,
            ]}
          >
            <group position={[0, 0, 0.1]}>
              <Align alignement={[0, 0, -1]}>
                <group position={[-0, 2.145, -0.5]} scale={[1, 1, width]}>
                  <Element url={'/MUB-R/BRIDGE-EXT/SUPPORT_H'} />
                </group>
              </Align>
            </group>
          </group>
        ))}

        <group position={[0, -0.11, -width - 2.14]}>
          {[...Array(modelContext.length)].map((e, i) => (
            <group
              key={i}
              position={[
                partLength[modelContext.width] * i + LENGTH.pos.x,
                0.118 + LENGTH.pos.y,
                1 + LENGTH.pos.z,
              ]}
            >
              <group rotation={[0, MathUtils.degToRad(180), 0]}>
                <Element url={LENGTH.path} />
              </group>
            </group>
          ))}
        </group>

        <Align alignement={[0, 0, -1]}>
          <group position={[length - 0.045, 0.685, -1.17]} scale={[1, 1, width - 0.6]}>
            <group rotation={[0, MathUtils.degToRad(180), 0]}>
              <Element url={'/MUB-R/BRIDGE-EXT/WIDTH'} />
            </group>
          </group>
        </Align>

        <Align alignement={[0, 0, -1]}>
          <group position={[-1.48, 0.682, -1.17]} scale={[1, 1, width - 0.6]}>
            <Element url={'/MUB-R/BRIDGE-EXT/WIDTH'} />
          </group>
        </Align>
      </group>

      <group position={[-1.57, height - 4, 0.75]} rotation={[0, MathUtils.degToRad(90), 0]}>
        <group scale={[-1, 1, 1]} position={[0.136, -2.48, -0.2]}>
          {[...Array(Math.abs(Math.floor((height > 2.7 ? height - 2.7 : 1) / 0.457) - 4))].map(
            (e, i) => (
              <Element key={i} url={'/MUB-E/ETUVE/BRIDGE/ECHELLE_MID'} position={[0, -i * 0.457, 0]} />
            ),
          )}
        </group>
        <Element
          url={'/MUB-E/ETUVE/BRIDGE/ECHELLE_BOT'}
          scale={[-1, 1, 1]}
          position={[0.41, -height + 1.7, -0.2]}
        />
      </group>
    </group>
  );
};

export default BRIDGE_EXT;

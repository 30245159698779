import { useApi, useInjectScripts, useLoadProject } from '@/hooks'
import { useContextBridge } from '@react-three/drei';
import { ControlsContext } from '@/components/Utils/GlobalContext';
import { ReactReduxContext } from 'react-redux';

import { Canvas } from '@react-three/fiber';
import { Suspense, useEffect } from 'react'
import XR8 from '@/components/Webgl/AR/XR8';
import XR3F from '@/components/Webgl/AR/XR3F';
import VideoFeed from '@/components/Webgl/AR/VideoFeed';
import ARScene from '@/components/Webgl/AR/ARScene';
import AROverlay from '@/components/Webgl/AR/AROverlay';
import Loader from '@/components/ShowcaseOverlay/Loader';
import { useLoadConfigFromUrlQuery } from '@/hooks/useLoadConfigFromUrlQuery'

const AR = () => {
  const ContextBridge = useContextBridge(ControlsContext, ReactReduxContext);

  useInjectScripts();
  useLoadConfigFromUrlQuery();
  return (
    <XR8>
      <AROverlay />
      <VideoFeed />
      <Suspense fallback={<Loader />}>
        <Canvas
          shadows={true}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: '0px',
            top: '0px',
            zIndex: 10,
            touchAction: 'none',
          }}
        >
          <ContextBridge>
            <XR3F>
              <ARScene />
            </XR3F>
          </ContextBridge>
        </Canvas>
      </Suspense>
    </XR8>
  );
};

export default AR;

import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { Group, MathUtils, Vector2 } from 'three';
import { S } from '@/config';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { Align, Element } from '@/components/Webgl/Utils/';
import { Box } from '@react-three/drei';
import {usePainter} from '@/hooks/beautify';

const BRIDGE: FC<any> = ({ config }) => {
  const modelContext = useContext(ModelContext);
  const groupRef = useRef<Group>(null!);

  const { paint } = usePainter();

  const partLength: any = {
    800: 2.08,
    1330: 3.458,
  };

  const { SM, BASE } = (S as any)[modelContext.width].BRIDGE.LENGTH;

  const { height, width, length } = useMemo(
    () => ({
      height: config.height - 2.1,
      width: config.width - 5.715,
      length: config.length - 1.5,
    }),
    [config],
  );

  useEffect(() => {
    paint(groupRef, [
      {
        name: 'all',
        options: {
          roughness: 0.2,
          metalness: 1,
          normalScale: new Vector2(0.005, 0.005),
        },
      },
    ]);
  });

  return (
    <group ref={groupRef} position={[0.3, height - 0.5, 0.2]}>
      <group position={[0, 0, -width - 0.545]}>
        <group position={[-1.59, -0.035, -0.75]}>
          <Element url={'/MUB-E/ETUVE/BRIDGE/CORNER'} rotation={[0, MathUtils.degToRad(180), 0]} />
          <Align alignement={[0, -1, 0]}>
            <Box args={[0.1, height - 0.75, 0.1]} position={[0.42, 0, 0.243]} />
          </Align>
          <Element url={'/Misc/FEET'} position={[0.42, -height + 0.67, 0.24]} />
        </group>

        <group position={[length - 1.44, 0.032, -0.69]}>
          <Element
            url={'/MUB-E/ETUVE/BRIDGE/ECHELLE_TOP'}
            scale={[-1, 1, 1]}
            rotation={[0, MathUtils.degToRad(0), 0]}
          />
          <group scale={[-1, 1, 1]} position={[0.136, -0.5, -0.2]}>
            {[...Array(Math.floor((height > 2.7 ? height - 2.7 : 1) / 0.457))].map((e, i) => (
              <Element
                url={'/MUB-E/ETUVE/BRIDGE/ECHELLE_MID'}
                position={[0, -i * 0.457, 0]}
                key={i}
              />
            ))}
          </group>
          <Element
            url={'/MUB-E/ETUVE/BRIDGE/ECHELLE_BOT'}
            scale={[-1, 1, 1]}
            position={[0.41, -height + 1.7, -0.2]}
            rotation={[0, MathUtils.degToRad(0), 0]}
          />
          <Align alignement={[0, -1, 0]}>
            <Box args={[0.1, height - 0.75, 0.1]} position={[-0.4, 0, 0.212]} />
          </Align>
          <Element url={'/Misc/FEET'} position={[-0.4, -height + 0.67, 0.21]} />
        </group>

        {[...Array(modelContext.length)].map((e, i) => (
          <Element
            url={BASE.path}
            key={i}
            position={[
              partLength[modelContext.width] * i + BASE.pos.x,
              BASE.pos.y + 0.003,
              -0.88 + BASE.pos.z,
            ]}
            scale={[1, 1, -1]}
          />
        ))}
      </group>

      <group position={[-1.59, -0.04, 0.922]}>
        <Element
          url={'/MUB-E/ETUVE/BRIDGE/CORNER_SM'}
          rotation={[0, MathUtils.degToRad(-180), 0]}
        />
        <Align alignement={[0, -1, 0]}>
          <Box args={[0.1, height - 0.75, 0.1]} position={[0.43, 0, -0.1]} />
        </Align>
        <Element url={'/Misc/FEET'} position={[0.44, -height + 0.67, -0.1]} />
      </group>

      <group position={[length - 1.405, -0.04, 0.92]}>
        <Element url={'/MUB-E/ETUVE/BRIDGE/CORNER_SM'} scale={[1, 1, -1]} />
        <Align alignement={[0, -1, 0]}>
          <Box args={[0.1, height - 0.75, 0.1]} position={[-0.43, 0, -0.1]} />
        </Align>
        <Element url={'/Misc/FEET'} position={[-0.44, -height + 0.67, -0.1]} />
      </group>

      {[...Array(modelContext.length)].map((e, i) => (
        <Element
          url={SM.path}
          key={i}
          position={[partLength[modelContext.width] * i + SM.pos.x, 0.118 + SM.pos.y, 1 + SM.pos.z]}
          rotation={[0, MathUtils.degToRad(180), 0]}
        />
      ))}

      <Align alignement={[0, 0, -1]}>
        <Element
          url={'/MUB-E/ETUVE/BRIDGE/WIDTH'}
          rotation={[0, MathUtils.degToRad(180), 0]}
          position={[length - 1.435, 0.05, 0.502]}
          scale={[1, 1, modelContext.space + 0.07]}
        />
      </Align>

      <Align alignement={[0, 0, -1]}>
        <Element
          url={'/MUB-E/ETUVE/BRIDGE/WIDTH'}
          position={[-1.564, 0.05, 0.502]}
          scale={[1, 1, modelContext.space + 0.07]}
        />
      </Align>
    </group>
  );
};

export default BRIDGE;

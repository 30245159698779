import { useParams } from 'react-router-dom';
import {useApi} from '@/hooks';
import {useLoadProject} from '@/hooks';

const Share = () => {
  const { code } = useParams();
  const { getProjectByCode } = useApi();
  const { loadProject } = useLoadProject();

  getProjectByCode({ code }).then((res) => {
    loadProject(res.data);
  });

  return null;
};

export default Share;

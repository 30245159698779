import { FC, useEffect } from 'react';
import { useProgress } from '@react-three/drei';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '@/store/ui.slice';

const Loader: FC = () => {
  const dispatch = useDispatch();
  const { progress, item, loaded, total } = useProgress();

  useEffect(() => {
    dispatch(setIsLoading(true));
    return () => {
      dispatch(setIsLoading(false));
    };
  });

  return (
    <AnimatePresence>
      <motion.div
        className={'absolute inset-0 z-[9999] h-full w-full'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', damping: 20, stiffness: 300 }}
      >
        <div
          className={
            'absolute top-1/2 left-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 transform flex-col items-center'
          }
        >
          <img className={'w-[100px]'} src={'/assets/images/loader.gif'} alt={'Loader'} />
          <div className={'flex flex-col items-center gap-y-lg'}>
            <div className={'w-[200px]'}>
              <div
                style={{ width: progress ? `${(100 / total) * loaded}%` : 0 }}
                className={'h-[6px] bg-secondary-base transition-width'}
              />
            </div>
            <span className={'text-gray-darker'}>
              {item} ({loaded} / {total})
            </span>
          </div>
        </div>
        <div className={'absolute inset-0 bg-white'} />
      </motion.div>
    </AnimatePresence>
  );
};

export default Loader;

import { FC, useRef } from 'react';
import { Box, Cylinder } from '@react-three/drei';
import {usePainter} from '@/hooks/beautify';
import { Group } from 'three';
import { Align, Element } from '@/components/Webgl/Utils';

interface Props {
  space: number;
}

const CHASSIS: FC<Props> = ({ space }) => {
  const groupRef = useRef<Group>(null!);
  const feetRef = useRef<Group>(null!);

  const { paint } = usePainter();

  paint(groupRef, [
    {
      name: 'all',
      options: {
        color: '#808080',
        roughness: 0,
        metalness: 0.3,
      },
    },
  ]);

  paint(feetRef, [
    {
      name: 'all',
      options: {
        roughness: 0,
        metalness: 0.3,
      },
    },
  ]);

  return (
    <group>
      <group ref={groupRef}>
        {/* BOTTOM */}

        <Box args={[0.783, 1.34, 0.05]} />
        <Box position={[0, 0, space]} args={[0.783, 1.34, 0.05]} />

        <Align alignement={[0, 0, 1]}>
          <Box position={[0.28, -0.66, 0]} args={[0.05, 0.02, space]} />
          <Box position={[-0.28, -0.66, 0]} args={[0.05, 0.02, space]} />
          <Box position={[0, 0.66, -0.025]} args={[0.783, 0.05, space + 0.05]} />
        </Align>

        <Align alignement={[0, 1, 1]}>
          <Box position={[-0.38, -0.67, 0]} args={[0.02, 0.85, space]} />
        </Align>

        <group position={[0, -0.1, 0]}>
          <Box position={[-0.38, 0.325, 0.19]} args={[0.02, 0.15, 0.37]} />
          <Box position={[-0.38, 0.325, space - 0.195]} args={[0.02, 0.15, 0.37]} />
        </group>

        {/* TOP */}

        <Align alignement={[-1, 0, 1]}>
          <Box position={[0.3, 1.01, 0]} args={[0.4, 0.02, space]} />
        </Align>
        <Align alignement={[-1, 1, 1]}>
          <Box position={[-0.08, 1, 0]} args={[0.02, 0.3, space]} />
        </Align>
        <Align alignement={[-1, 1, 1]}>
          <Box position={[0.3, 1, 0]} args={[0.02, 0.3, space]} />
        </Align>
        <Align alignement={[-1, 1, 1]}>
          <Box position={[0.3, 1, 0]} args={[0.4, 0.3, 0.02]} />
        </Align>
        <Align alignement={[-1, 1, 1]}>
          <Box position={[0.3, 1, space]} args={[0.4, 0.3, 0.02]} />
        </Align>

        {space > 1.2 && (
          <>
            <Cylinder position={[0.1, 0.85, 0.3]} args={[0.15, 0.15, 0.35]} />
            <Cylinder position={[0.1, 0.85, space - 0.3]} args={[0.15, 0.15, 0.35]} />
          </>
        )}
        <Cylinder position={[0.1, 0.85, space / 2]} args={[0.15, 0.15, 0.35]} />

        <group position={[-0.08, -0.13, 0]}>
          <Align alignement={[0, 0, 1]}>
            <Box position={[0.55, 0.72, 0]} args={[0.15, 0.05, space]} />
          </Align>

          {space > 0.75 && (
            <Align alignement={[0, 0, 1]}>
              <Box position={[0.55, 0.45, 0.32]} args={[0.15, 0.05, space - 0.64]} />
            </Align>
          )}

          <Box position={[0.55, 0.57, 0]} args={[0.15, 0.35, 0.05]} />
          <Box position={[0.55, 0.57, space]} args={[0.15, 0.35, 0.05]} />
          <Box position={[0.55, 0.4, space - 0.175]} args={[0.15, 0.05, 0.4]} />
          <Box position={[0.55, 0.4, 0.175]} args={[0.15, 0.05, 0.4]} />
        </group>
      </group>

      <group ref={feetRef}>
        <Element url={'/Misc/FEET'} position={[0.28, -0.7, 0]} />
        <Element url={'/Misc/FEET'} position={[-0.28, -0.7, 0]} />
        <Element url={'/Misc/FEET'} position={[0.28, -0.7, space]} />
        <Element url={'/Misc/FEET'} position={[-0.28, -0.7, space]} />
      </group>
    </group>
  );
};

export default CHASSIS;

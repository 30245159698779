import { FC } from 'react';

interface Props {
  errors?: boolean;
}

const UnitInput: FC<Props & JSX.IntrinsicElements['input']> = ({ errors, ...attrs }) => {
  return (
    <input
      className={`border p-2 ${
        errors ? 'border-red-600 text-red-600' : 'border-primary-base'
      } font-secondary w-full text-lg`}
      {...attrs}
    />
  );
};

export default UnitInput;

import { Environment } from '@react-three/drei';
import PrePosition from '@/components/Webgl/AR/PrePosition';
import ModelOverlay from '@/components/Webgl/AR/ModelOverlay';
import { useDispatch } from 'react-redux';
import { setARReady } from '@/store/misc.slice';
// @ts-ignore
import hdri from '@pmndrs/assets/hdri/warehouse.hdr';

const ARScene = () => {
  const dispatch = useDispatch();

  dispatch(setARReady(true));

  return (
    <>
      <ambientLight intensity={1} />
      <pointLight position={[10, 8, 5]} intensity={3} />
      <Environment files={hdri} />

      <PrePosition />
      <ModelOverlay />
    </>
  );
};

export default ARScene;

import { RootState } from "@/store";
import { FC, Fragment, ReactElement } from "react";
import { useSelector } from "react-redux";
import Icon from '@/components/Base/Icon'

interface Props {
  currentStep: number;
  modules: (ReactElement | ReactElement[])[];
  onClick: (event: any) => any;
}

const Steps: FC<Props> = ({currentStep, modules, onClick}) => {

  const { demo, token } = useSelector((state: RootState) => state.user);

  return (
    <div className="flex px-2xl flex-row justify-between w-full items-center">
      {modules.map((_, index) => (
        <Fragment key={index}>
          <div
            className={`
              w-[24px] h-[24px] rounded-full border-[2px] border-primary-base cursor-pointer transition relative
              ${index === currentStep ? 'bg-primary-base' : 'bg-white'}
            `}
            key={index}
            onClick={() => onClick(index)}
          >
            <span className={`
              transition absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
              ${index === currentStep ? 'text-white' : 'text-primary-base'}
            `}>{index + 1}</span>
          </div>

          {index !== modules.length - 1 && (
            <div className={`
              flex-1 h-0 border-b-2 border-primary-base
            `}></div>
          )}

        </Fragment>
      ))}

      {(!token?.length && !demo) && (
        <>
          <div className="border-primary-base border-b-2 flex-1 h-0"></div>
          <div
            className={`
              w-[24px] h-[24px] rounded-full border-[2px] border-primary-base cursor-pointer transition relative
              ${currentStep === modules.length ? 'bg-primary-base' : 'bg-white'}
            `}
            onClick={() => onClick(modules.length + 1)}
          >
            <span className={`
              transition absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
              ${currentStep === modules.length ? 'text-white' : 'text-primary-base'}
            `}><Icon size={12} name={'lock'} /></span>
          </div>
          <div className="border-primary-light border-b-2 border-dashed flex-1 h-0"></div>
        </>
      )}

    </div>
  )
}

export default Steps;

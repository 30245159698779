import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isModelRendered: boolean;
  mode: string;
  snapshot: any;
  scale: number;
}

const initialState: InitialState = {
  isModelRendered: false,
  mode: 'responsive',
  snapshot: null,
  scale: 1,
};

const arSlice = createSlice({
  name: 'ar',
  initialState,
  reducers: {
    setIsModelRendered: (state, action) => {
      state.isModelRendered = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setScale: (state, action) => {
      state.scale = action.payload;
    },
  },
});

export const { setIsModelRendered, setMode, setScale } = arSlice.actions;

export default arSlice;

import ReactDOM from 'react-dom/client';

import '@/styles';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(window as any).createImageBitmap = undefined;

root.render(<App />);

import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUnit } from '@/store/misc.slice';
import { setVolumeSize } from '@/store/volume.slice';
import { RootState } from '@/store';
import { useGetTraysSurface } from '@/hooks';
import { defaultStore } from '@/config';
import { unitConverter } from '@/functions';
import * as S from '@/components';
import { useFitToVolume } from '@/hooks';
import { number, object } from 'yup';

import Module from './Module';
import { InputUnit } from '@/components/Base/Input';
import { Formik } from 'formik';
import Popover from '@/components/Base/Popover';
import Select from '@/components/Base/Select';

const ProcessModule: FC = () => {
  const dispatch = useDispatch();

  const [isValidated] = useState<boolean>(false);
  const defaultVolume = defaultStore.volume.size;

  const traysSurface = useGetTraysSurface();

  const { unit } = useSelector((state: RootState) => state.misc);
  const { size } = useSelector((state: RootState) => state.volume);
  const { process } = useSelector((state: RootState) => state.config);

  const { fitToVolume } = useFitToVolume();

  const [isAutoScaled, setIsAutoScaled] = useState(true);

  const handleResize = () => {
    if ((window as Window).innerWidth >= 1024) {
      setIsAutoScaled(true);
    } else {
      setIsAutoScaled(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize, true);

    return () => {
      window.removeEventListener('resize', handleResize, true);
    };
  });

  return (
    <Module
      name={'Available volume'}
      validated={isValidated}
      contextual={
        <>
          <div className={'flex gap-x-1.5'}>
            <Popover delay={500} placement={'top'} content={'Reset volume'}>
              <S.Button
                variant={'gray'}
                content={'reset'}
                icon={'reset'}
                split={false}
                onClick={() =>
                  dispatch(
                    setVolumeSize({
                      x: unitConverter(unit, 'from', defaultVolume.x).toFixed(2),
                      y: unitConverter(unit, 'from', defaultVolume.y).toFixed(2),
                      z: unitConverter(unit, 'from', defaultVolume.z).toFixed(2),
                    }),
                  )
                }
              />
            </Popover>

            <Popover delay={500} placement={'top'} content={'Fit config to volume'}>
              <S.Button
                variant={'gray'}
                content={'fit'}
                icon={'fit'}
                split={false}
                onClick={fitToVolume}
              />
            </Popover>

            <Popover delay={500} placement={'top'} content={'Volume unit'}>
              <Select
                value={unit}
                small
                options={[
                  {
                    label: 'millimeter',
                    value: 'mm',
                  },
                  {
                    label: 'centimeter',
                    value: 'cm',
                  },
                  {
                    label: 'meter',
                    value: 'm',
                  },
                  {
                    label: 'feet',
                    value: 'ft',
                  },
                ]}
                onChange={(option) => {
                  dispatch(setUnit(option.value));
                  const standardSize = {
                    x: unitConverter(unit, 'to', size.x),
                    y: unitConverter(unit, 'to', size.y),
                    z: unitConverter(unit, 'to', size.z),
                  };

                  dispatch(
                    setVolumeSize({
                      x: parseFloat(
                        unitConverter(option.value as string, 'from', standardSize.x).toFixed(2),
                      ),
                      y: parseFloat(
                        unitConverter(option.value as string, 'from', standardSize.y).toFixed(2),
                      ),
                      z: parseFloat(
                        unitConverter(option.value as string, 'from', standardSize.z).toFixed(2),
                      ),
                    }),
                  );
                }}
              />
            </Popover>
          </div>
        </>
      }
      options={
        <>
          <Formik
            enableReinitialize
            initialValues={{
              x: size.x,
              y: size.y,
              z: size.z,
            }}
            validationSchema={object().shape({
              x: number().min(0).required(),
              y: number().min(0).required(),
              z: number().min(0).required(),
            })}
            onSubmit={(values) => {
              dispatch(
                setVolumeSize({
                  x: values.x,
                  y: values.y,
                  z: values.z,
                }),
              );
            }}
          >
            {({ values, handleChange, submitForm, errors, validateForm }: any) => (
              <>
                <div className={'flex flex-col gap-y-sm'}>
									<div className="flex flex-col gap-y-0.5">
										<span className="inline-block lg:hidden">
											<S.Heading Tag={'h3'} weight={'light'} uppercase={false} wide={true}>
												Length
											</S.Heading>
										</span>
										<div className={'flex lg:justify-center'}>
											<InputUnit
												customClassName={`bg-misc-yellow-light ${
													errors.x ? 'outline outline-red-600 text-red-600' : ''
												}`}
												value={values.x}
												unit={unit}
												min={0}
												max={200}
												step={'any'}
												small
												autoscaling={isAutoScaled}
												onChange={(e) => {
													handleChange({
														target: { name: 'x', value: parseFloat(e.target.value) },
													});
													validateForm().then(submitForm);
												}}
											/>
										</div>
									</div>
                  <div className={'flex flex-col justify-center gap-x-sm gap-y-sm lg:flex-row'}>
										<div className="flex flex-col gap-y-0.5">
											<span className="inline-block lg:hidden">
												<S.Heading Tag={'h3'} weight={'light'} uppercase={false} wide={true}>
													Height
												</S.Heading>
											</span>
											<div className={'flex items-center'}>
												<InputUnit
													customClassName={`bg-misc-red-light ${
														errors.y ? 'outline outline-red-600 text-red-600' : ''
													}`}
													value={values.y}
													unit={unit}
													min={0}
													max={200}
													small
													step={'any'}
													autoscaling={isAutoScaled}
													onChange={(e) => {
														handleChange({
															target: { name: 'y', value: parseFloat(e.target.value) },
														});
														validateForm().then(submitForm);
													}}
												/>
											</div>
										</div>

                    <img
                      className={'hidden lg:block'}
                      src={'/assets/images/configurator/volume/schema.svg'}
                      alt={'Configurator volume schema'}
                    />

										<div className="flex flex-col gap-y-0.5">
											<span className="inline-block lg:hidden">
												<S.Heading Tag={'h3'} weight={'light'} uppercase={false} wide={true}>
													Width
												</S.Heading>
											</span>
											<div className={'flex items-start'}>
												<InputUnit
													customClassName={`bg-gray-base ${
														errors.z ? 'outline outline-red-600 text-red-600' : ''
													}`}
													value={values.z}
													unit={unit}
													min={0}
													max={200}
													small
													step={'any'}
													autoscaling={isAutoScaled}
													onChange={(e) => {
														handleChange({
															target: { name: 'z', value: parseFloat(e.target.value) },
														});
														validateForm().then(submitForm);
													}}
												/>
											</div>
										</div>
                  </div>
                </div>
              </>
            )}
          </Formik>
          <div className="flex gap-y-xs flex-col">
            <div className={'mt-md flex gap-x-sm'}>
              <S.Heading Tag={'h4'} color={'secondary'} size={'lg'} uppercase={false}>
                Footprint:
              </S.Heading>
              <S.Heading Tag={'h4'} color={'secondary'} size={'lg'} uppercase={false} weight={'bold'}>
                {`${(size.x * size.z).toFixed(2)}`}
                {unit}²
              </S.Heading>
            </div>
            <div className={'flex gap-x-sm'}>
              <S.Heading Tag={'h4'} color={'secondary'} size={'lg'} uppercase={false}>
                Max {process} surface:
              </S.Heading>
              <S.Heading Tag={'h4'} color={'secondary'} size={'lg'} uppercase={false} weight={'bold'}>
                {unitConverter(unit, 'from', parseFloat(traysSurface)).toFixed(2)}
                {unit}²
              </S.Heading>
            </div>
          </div>
        </>
      }
    />
  );
};

export default ProcessModule;

const takeScreenshot = (selector: string) => {
  const gl = document.querySelector(selector) as HTMLCanvasElement;
  gl.toBlob(
    (blob: Blob | null) => {
      if (blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `capture-${new Date().toISOString().slice(0, 10)}.jpg`;
        link.click();
      }
    },
    'image/jpg',
    1.0,
  );
};

export default takeScreenshot;

// Make TW accessible in TSX components

const config = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    fontFamily: {
      sans: ['Neo Sans'],
    },
    extend: {
      transitionProperty: {
        height: 'height',
        width: 'width',
        rounded: 'rounded',
        spacing: 'spacing',
      },
      spacing: {
        '2xl': '42px',
        xl: '30px',
        lg: '24px',
        md: '20px',
        sm: '12px',
        xs: '8px',
        '2xs': '4px',
      },
      fontSize: {
        xl: '20px',
        lg: '17px',
        md: '13px',
        base: '12px',
        sm: '10px',
        xs: '8px',
      },
      colors: {
        primary: {
          base: '#1B2140',
        },
        secondary: {
          base: '#BD1C25',
        },
        gray: {
          base: '#F6F7F9',
          dark: '#EAEBED',
          darker: '#9EABBC',
        },
        misc: {
          yellow: {
            base: '#ECC23A',
            light: '#FEF9EC',
          },
          red: {
            light: '#F9E9EA',
          },
          blue: {
            base: '#3488DD',
            dark: '#0b497c',
          },
        },
      },
      boxShadow: {
        base: '10px 7px 30px 5px rgba(0, 0, 0, 0.05)',
      },
    },
  },
  plugins: [],
}

export default config;

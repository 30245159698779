import BOT from './BOT';
import TOP from './TOP';
import MID from './MID';

import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { Group, MathUtils, Mesh, MeshStandardMaterial } from 'three';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { S } from '@/config';
import {usePainter} from '@/hooks/beautify';
import ENCEINTE from '@/components/Webgl/Showcase/Model/MUB-S/ENCEINTE/ENCEINTE';

const MUB_E: FC = () => {
  const groupRef = useRef<Group>(null!);

  const { width, products } = useContext(ModelContext);

  const { paint } = usePainter();

  const { offset, offsetProduct } = useMemo(() => {
    const e = S as any;
    return {
      offset: e[width].offset,
      offsetProduct: e[width][products].offset,
    };
  }, [width, products]);

  useEffect(() => {
    groupRef.current?.traverse((child) => {
      if (child instanceof Mesh) {
        child.material.roughness = 0.2;
        child.material.metalness = 1;
      }
    });
  });

  useEffect(() => {
    paint(groupRef, [
      {
        name: [
          'Material_3.002',
          'Material_3',
          'Material_3.007',
          'Material_8.002',
          'Material_8.004',
          'Material_8.008',
          'Material_3.001',
          'Material_3.003',
          'Material_3.008',
          'Material_8',
          'Material_8.005',
          'Material_8.006',
          'Material_8.009',
          'Material_3.005',
          'Material_3.004',
          'Material_3.006',
        ],
        material: new MeshStandardMaterial({
          color: '#233449',
          roughness: 0.2,
          metalness: 1,
        }),
      },
      {
        name: [
          'Material_8.001',
          'Material_8.003',
          'Material_8.007',
          'Material_2.009',
          'Material_2.010',
          'Material_2.011',
        ],
        material: new MeshStandardMaterial({
          color: '#233449',
          roughness: 0.2,
          metalness: 1,
        }),
      },
      {
        name: 'all',
        options: {
          roughness: 0.1,
          metalness: 1,
        },
      },
    ]);
  });

  return (
    <group position={[2.01, 0.48, -4.41]}>
      <ENCEINTE mub={groupRef} />
      <group
        rotation={[0, MathUtils.degToRad(180), 0]}
        position={[
          offset.x + offsetProduct.x,
          1.36 + offset.y + offsetProduct.y,
          0.65 + offset.z + offsetProduct.z,
        ]}
        ref={groupRef}
      >
        <TOP />

        <group position={[0, 1.143, 0]}>
          <MID />
        </group>
        <BOT />
      </group>
    </group>
  );
};

export default MUB_E;

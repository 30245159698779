import { FC, Fragment, useContext, useMemo } from 'react';
import { MathUtils } from 'three';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { S } from '@/config';
import MUB from '@/components/Webgl/Showcase/Elements';

const MID: FC = () => {
  const { space, height, products, width, length } = useContext(ModelContext);

  const { params, A, B, spacing } = useMemo(() => {
    const e = S as any;
    return {
      params: e[width][products].MID.params,
      A: e[width][products].SIDES.A,
      B: e[width][products].SIDES.B,
      spacing: e[width].spacing,
    };
  }, [width, products]);

  const { MID } = useMemo(() => MUB.S[`${width}-${products}`], [products, width]);

  return (
    <>
      <MID.Instances limit={360} range={360}>
        {[...Array(height)].map((e, i) => (
          <Fragment key={i}>
            {[...Array(length)].map((e, j) => (
              <group position={[-j * spacing, 0, 0]} key={j}>
                <group position={[0, i * 0.455, 0]}>
                  <group
                    position={[
                      params.pos1.x + A.x,
                      params.pos1.y + A.y,
                      params.pos1.z + space + A.z,
                    ]}
                  >
                    <MID.Model />
                  </group>
                  <group
                    position={[params.pos2.x + B.x, params.pos2.y + B.y, params.pos2.z + B.z]}
                    rotation={[0, MathUtils.degToRad(180), 0]}
                  >
                    <MID.Model />
                  </group>

                  {/*{i % 3 === 0 && (*/}
                  {/*  <Element url={'/Misc/ROD_A'} position={[0, -0.29, 0.05]} scale={[1, 1, space]} />*/}
                  {/*)}*/}
                </group>
              </group>
            ))}
          </Fragment>
        ))}
      </MID.Instances>
    </>
  );
};

export default MID;

import { Group, MeshStandardMaterial, Vector2 } from 'three';
import { MutableRefObject } from 'react';
import { useNormalTexture } from '@react-three/drei';

const useBeautify = (etuveRef: MutableRefObject<Group>) => {
  const [normalMap] = useNormalTexture(42, {
    offset: [0, 0],
  });

  etuveRef?.current?.traverse((child: any) => {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;

      const { color, name } = child.material;
      child.material = new MeshStandardMaterial({
        name,
        color,
      });

      child.material.roughness = 0;
      child.material.metalness = 0.3;
      child.material.normalMap = normalMap;
      child.material.normalScale = new Vector2(0.005, 0.005);
    }
  });
};

export default useBeautify;

import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

const VideoFeed: FC = () => {
  const { XR8, XRExtras, CoachingOverlay } = window as any;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement>(null!);
  const mode = useSelector((state: RootState) => state.ar.mode);

  useEffect(() => {
    if (!isLoaded) {
      CoachingOverlay['configure']({
        animationColor: '#BD1C25',
        promptText: 'To generate scale push your phone forward and then pull back',
      });
      XR8.XrController['configure']({
        scale: mode,
      });
      const modules = [
        XR8.XrController.pipelineModule(),
        XR8.GlTextureRenderer.pipelineModule(),
        XRExtras.AlmostThere.pipelineModule(),
        XRExtras.FullWindowCanvas.pipelineModule(),
        XRExtras.Loading.pipelineModule(),
        XRExtras.RuntimeError.pipelineModule(),
        CoachingOverlay.pipelineModule(),
      ];

      modules.forEach((module) => XR8.addCameraPipelineModule(module));

      XR8.run({ canvas: canvasRef.current });
      setIsLoaded(true);
    }
    XRExtras.Loading.showLoading({ isLoaded });
    const loadImage: Partial<HTMLImageElement> = document.getElementById('loadImage')!;
    if (loadImage) {
      loadImage.src = '/assets/images/loader.gif';
    }
  }, [isLoaded, XR8, XRExtras, CoachingOverlay, mode]);

  return <canvas className="touch-none" ref={canvasRef} />;
};

export default VideoFeed;

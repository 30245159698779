import { useEffect } from 'react';

const useInjectScripts = () => {
  useEffect(() => {
    const urls = [
      '//cdn.8thwall.com/web/xrextras/xrextras.js',
      '//apps.8thwall.com/xrweb?appKey=yIG3zsi9hlDG99fz5DcZTwr4g58dqT1iV6lMX4rkk7I2QAk0Ifqjb7yNEXVJdgle6G2B7V',
      'https://cdn.8thwall.com/web/landing-page/landing-page.js',
      'https://cdnjs.cloudflare.com/ajax/libs/three.js/r134/three.min.js',
      'https://cdn.8thwall.com/web/coaching-overlay/coaching-overlay.js',
    ];

    urls.forEach((url) => {
      const script = document.createElement('script');
      script.setAttribute('src', url);
      document.body.appendChild(script);
    });
  });
};

export default useInjectScripts;

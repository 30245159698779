import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useMemo } from 'react';
import { unitConverter } from '@/functions';
import { setHasChanged, setMubAmount, setMubHeight, setTrays } from '@/store/config.slice';

const useFitToVolume = () => {
  const dispatch = useDispatch();

  const size = useSelector((state: RootState) => state.volume.size);
  const unit = useSelector((state: RootState) => state.misc.unit);

  const { trays, process } = useSelector((state: RootState) => state.config);

  const part: any = useMemo(
    () => ({
      800: {
        length: 2.08,
        height: {
          top: 1.31,
          mid: 0.53,
          bot: 2.15,
        },
      },
      1330: {
        length: 3.458,
        height: {
          top: 1.52,
          mid: 0.545,
          bot: 2.38,
        },
      },
    }),
    [],
  );

  const outside: any = useMemo(
    () => ({
      proofing: {
        length: 2.42,
        height: 0,
        width: 2.61,
      },
      retarding: {
        length: 2.42,
        height: 0,
        width: 2.61,
      },
      'ambiant cooling': {
        length: 2.59,
        height: 0,
        width: 2.414,
      },
      'enclosed cooling': {
        length: 2.42,
        height: 0,
        width: 2.61,
      },
      freezing: {
        length: 2.5,
        height: 0.5,
        width: 5,
      },
    }),
    [],
  );

  const height: any = useMemo(
    () => ({
      proofing: {
        800: 5.17,
        1330: 5.96,
      },
      retarding: {
        800: 5.17,
        1330: 5.96,
      },
      'ambiant cooling': {
        800: 4.67,
        1330: 5.56,
      },
      'enclosed cooling': {
        800: 5.17,
        1330: 5.96,
      },
      freezing: {
        800: 5.53,
        1330: 6.52,
      },
    }),
    [],
  );

  const standardSize = useMemo(
    () => ({
      x: parseFloat(unitConverter(unit, 'to', size.x).toFixed(2)),
      y: parseFloat(unitConverter(unit, 'to', size.y).toFixed(2)),
      z: parseFloat(unitConverter(unit, 'to', size.z).toFixed(2)),
    }),
    [size, unit],
  );

  const newModelSpecs = useMemo(() => {
    const getModelHeight = () => {
      const empty = standardSize.y - height[process][trays.y];
      const amount = Math.floor(empty / part[trays.y].height.mid);
      if (amount > 8) return 11;
      if (amount <= 0) return 2;
      return amount + 6 / 2;
    };

    const getModelLength = () => {
      const unmovableSpace = standardSize.x - outside[process].length;
      const length = Math.floor(unmovableSpace / part[trays.y].length);
      if (length < 1) return 1;
      if (length > 15) return 15;
      return length;
    };

    const getModelWidth = () => {
      const unmovableSpace = (standardSize.z - outside[process].width) * 1000;
      if (unmovableSpace > 2400) return 2400;
      if (unmovableSpace < 450) return 450;
      return Math.floor(unmovableSpace);
    };

    return {
      length: getModelLength(),
      height: getModelHeight(),
      width: getModelWidth(),
    };
  }, [outside, process, standardSize, part, trays, height]);

  const fitToVolume = () => {
    dispatch(setMubAmount(newModelSpecs.length));
    dispatch(setMubHeight(newModelSpecs.height));
    dispatch(setTrays({ ...trays, x: newModelSpecs.width }));
    dispatch(setHasChanged(true));
  };

  return { fitToVolume };
};

export default useFitToVolume;

import * as E_BOT_A_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-E/800-114.3/E_BOT_A-800-114.3';
import * as E_BOT_B_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-E/800-114.3/E_BOT_B-800-114.3';
import * as E_MID_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-E/800-114.3/E_MID-800-114.3';
import * as E_TOP_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-E/800-114.3/E_TOP-800-114.3';

import * as E_BOT_A_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-E/800-152.4/E_BOT_A-800-152.4';
import * as E_BOT_B_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-E/800-152.4/E_BOT_B-800-152.4';
import * as E_MID_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-E/800-152.4/E_MID-800-152.4';
import * as E_TOP_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-E/800-152.4/E_TOP-800-152.4';

import * as E_BOT_A_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-E/800-226.6/E_BOT_A-800-226.6';
import * as E_BOT_B_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-E/800-226.6/E_BOT_B-800-226.6';
import * as E_MID_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-E/800-226.6/E_MID-800-226.6';
import * as E_TOP_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-E/800-226.6/E_TOP-800-226.6';

import * as E_BOT_A_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-114.3/E_BOT_A-1330-114.3';
import * as E_BOT_B_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-114.3/E_BOT_B-1330-114.3';
import * as E_MID_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-114.3/E_MID-1330-114.3';
import * as E_TOP_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-114.3/E_TOP-1330-114.3';

import * as E_BOT_A_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-152.4/E_BOT_A-1330-152.4';
import * as E_BOT_B_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-152.4/E_BOT_B-1330-152.4';
import * as E_MID_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-152.4/E_MID-1330-152.4';
import * as E_TOP_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-152.4/E_TOP-1330-152.4';

import * as E_BOT_A_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-226.6/E_BOT_A-1330-226.6';
import * as E_BOT_B_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-226.6/E_BOT_B-1330-226.6';
import * as E_MID_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-226.6/E_MID-1330-226.6';
import * as E_TOP_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-E/1330-226.6/E_TOP-1330-226.6';

import * as R_BOT_A_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-R/800-114.3/R_BOT_A-800-114.3';
import * as R_BOT_B_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-R/800-114.3/R_BOT_B-800-114.3';
import * as R_MID_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-R/800-114.3/R_MID-800-114.3';
import * as R_TOP_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-R/800-114.3/R_TOP-800-114.3';

import * as R_BOT_A_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-R/800-152.4/R_BOT_A-800-152.4';
import * as R_BOT_B_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-R/800-152.4/R_BOT_B-800-152.4';
import * as R_MID_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-R/800-152.4/R_MID-800-152.4';
import * as R_TOP_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-R/800-152.4/R_TOP-800-152.4';

import * as R_BOT_A_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-R/800-226.6/R_BOT_A-800-226.6';
import * as R_BOT_B_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-R/800-226.6/R_BOT_B-800-226.6';
import * as R_MID_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-R/800-226.6/R_MID-800-226.6';
import * as R_TOP_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-R/800-226.6/R_TOP-800-226.6';

import * as R_BOT_A_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-114.3/R_BOT_A-1330-114.3';
import * as R_BOT_B_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-114.3/R_BOT_B-1330-114.3';
import * as R_MID_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-114.3/R_MID-1330-114.3';
import * as R_TOP_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-114.3/R_TOP-1330-114.3';

import * as R_BOT_A_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-152.4/R_BOT_A-1330-152.4';
import * as R_BOT_B_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-152.4/R_BOT_B-1330-152.4';
import * as R_MID_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-152.4/R_MID-1330-152.4';
import * as R_TOP_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-152.4/R_TOP-1330-152.4';

import * as R_BOT_A_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-226.6/R_BOT_A-1330-226.6';
import * as R_BOT_B_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-226.6/R_BOT_B-1330-226.6';
import * as R_MID_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-226.6/R_MID-1330-226.6';
import * as R_TOP_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-R/1330-226.6/R_TOP-1330-226.6';

import * as S_BOT_A_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-S/800-114.3/S_BOT_A-800-114.3';
import * as S_BOT_B_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-S/800-114.3/S_BOT_B-800-114.3';
import * as S_MID_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-S/800-114.3/S_MID-800-114.3';
import * as S_TOP_800_114_3 from '@/components/Webgl/Showcase/Elements/MUB-S/800-114.3/S_TOP-800-114.3';

import * as S_BOT_A_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-S/800-152.4/S_BOT_A-800-152.4';
import * as S_BOT_B_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-S/800-152.4/S_BOT_B-800-152.4';
import * as S_MID_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-S/800-152.4/S_MID-800-152.4';
import * as S_TOP_800_152_4 from '@/components/Webgl/Showcase/Elements/MUB-S/800-152.4/S_TOP-800-152.4';

import * as S_BOT_A_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-S/800-226.6/S_BOT_A-800-226.6';
import * as S_BOT_B_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-S/800-226.6/S_BOT_B-800-226.6';
import * as S_MID_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-S/800-226.6/S_MID-800-226.6';
import * as S_TOP_800_226_6 from '@/components/Webgl/Showcase/Elements/MUB-S/800-226.6/S_TOP-800-226.6';

import * as S_BOT_A_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-114.3/S_BOT_A-1330-114.3';
import * as S_BOT_B_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-114.3/S_BOT_B-1330-114.3';
import * as S_MID_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-114.3/S_MID-1330-114.3';
import * as S_TOP_1330_114_3 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-114.3/S_TOP-1330-114.3';

import * as S_BOT_A_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-152.4/S_BOT_A-1330-152.4';
import * as S_BOT_B_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-152.4/S_BOT_B-1330-152.4';
import * as S_MID_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-152.4/S_MID-1330-152.4';
import * as S_TOP_1330_152_4 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-152.4/S_TOP-1330-152.4';

import * as S_BOT_A_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-226.6/S_BOT_A-1330-226.6';
import * as S_BOT_B_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-226.6/S_BOT_B-1330-226.6';
import * as S_MID_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-226.6/S_MID-1330-226.6';
import * as S_TOP_1330_226_6 from '@/components/Webgl/Showcase/Elements/MUB-S/1330-226.6/S_TOP-1330-226.6';

const MUB: any = {
  E: {
    '800-114.3': {
      BOT_A: E_BOT_A_800_114_3,
      BOT_B: E_BOT_B_800_114_3,
      MID: E_MID_800_114_3,
      TOP: E_TOP_800_114_3,
    },
    '800-152.4': {
      BOT_A: E_BOT_A_800_152_4,
      BOT_B: E_BOT_B_800_152_4,
      MID: E_MID_800_152_4,
      TOP: E_TOP_800_152_4,
    },
    '800-226.6': {
      BOT_A: E_BOT_A_800_226_6,
      BOT_B: E_BOT_B_800_226_6,
      MID: E_MID_800_226_6,
      TOP: E_TOP_800_226_6,
    },
    '1330-114.3': {
      BOT_A: E_BOT_A_1330_114_3,
      BOT_B: E_BOT_B_1330_114_3,
      MID: E_MID_1330_114_3,
      TOP: E_TOP_1330_114_3,
    },
    '1330-152.4': {
      BOT_A: E_BOT_A_1330_152_4,
      BOT_B: E_BOT_B_1330_152_4,
      MID: E_MID_1330_152_4,
      TOP: E_TOP_1330_152_4,
    },
    '1330-226.6': {
      BOT_A: E_BOT_A_1330_226_6,
      BOT_B: E_BOT_B_1330_226_6,
      MID: E_MID_1330_226_6,
      TOP: E_TOP_1330_226_6,
    },
  },
  R: {
    '800-114.3': {
      BOT_A: R_BOT_A_800_114_3,
      BOT_B: R_BOT_B_800_114_3,
      MID: R_MID_800_114_3,
      TOP: R_TOP_800_114_3,
    },
    '800-152.4': {
      BOT_A: R_BOT_A_800_152_4,
      BOT_B: R_BOT_B_800_152_4,
      MID: R_MID_800_152_4,
      TOP: R_TOP_800_152_4,
    },
    '800-226.6': {
      BOT_A: R_BOT_A_800_226_6,
      BOT_B: R_BOT_B_800_226_6,
      MID: R_MID_800_226_6,
      TOP: R_TOP_800_226_6,
    },
    '1330-114.3': {
      BOT_A: R_BOT_A_1330_114_3,
      BOT_B: R_BOT_B_1330_114_3,
      MID: R_MID_1330_114_3,
      TOP: R_TOP_1330_114_3,
    },
    '1330-152.4': {
      BOT_A: R_BOT_A_1330_152_4,
      BOT_B: R_BOT_B_1330_152_4,
      MID: R_MID_1330_152_4,
      TOP: R_TOP_1330_152_4,
    },
    '1330-226.6': {
      BOT_A: R_BOT_A_1330_226_6,
      BOT_B: R_BOT_B_1330_226_6,
      MID: R_MID_1330_226_6,
      TOP: R_TOP_1330_226_6,
    },
  },
  S: {
    '800-114.3': {
      BOT_A: S_BOT_A_800_114_3,
      BOT_B: S_BOT_B_800_114_3,
      MID: S_MID_800_114_3,
      TOP: S_TOP_800_114_3,
    },
    '800-152.4': {
      BOT_A: S_BOT_A_800_152_4,
      BOT_B: S_BOT_B_800_152_4,
      MID: S_MID_800_152_4,
      TOP: S_TOP_800_152_4,
    },
    '800-226.6': {
      BOT_A: S_BOT_A_800_226_6,
      BOT_B: S_BOT_B_800_226_6,
      MID: S_MID_800_226_6,
      TOP: S_TOP_800_226_6,
    },
    '1330-114.3': {
      BOT_A: S_BOT_A_1330_114_3,
      BOT_B: S_BOT_B_1330_114_3,
      MID: S_MID_1330_114_3,
      TOP: S_TOP_1330_114_3,
    },
    '1330-152.4': {
      BOT_A: S_BOT_A_1330_152_4,
      BOT_B: S_BOT_B_1330_152_4,
      MID: S_MID_1330_152_4,
      TOP: S_TOP_1330_152_4,
    },
    '1330-226.6': {
      BOT_A: S_BOT_A_1330_226_6,
      BOT_B: S_BOT_B_1330_226_6,
      MID: S_MID_1330_226_6,
      TOP: S_TOP_1330_226_6,
    },
  },
};

export default MUB;

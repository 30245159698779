import { Box3, Object3D, Vector3 } from 'three';

const betterBox3 = (object: Object3D): Vector3 => {
  const clone = object.clone(true);
  const boundsVolume = new Vector3();
  const volumeBoundingBox = new Box3().setFromObject(clone);
  volumeBoundingBox.getSize(boundsVolume);
  return boundsVolume;
};

export default betterBox3;

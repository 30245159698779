import { FC, useContext } from 'react';
import { Align } from '@/components/Webgl/Utils';
import { Box } from '@react-three/drei';
import { ModelContext } from '@/components/Webgl/Showcase/Model';

interface Props {
  width: number;
  height: number;
}

const FACE_B: FC<Props> = ({ width, height }) => {
  const { space } = useContext(ModelContext);

  return (
    <group>
      <Align alignement={[0, 1, -1]}>
        <Box position={[-0.0101, -1.05, width - 0.8]} args={[0.12, height, 1.3]} />
      </Align>

      <Align alignement={[0, 1, 1]}>
        <Box position={[-0.0102, -1.05, -0.7]} args={[0.12, height, width - 1.1 - space]} />
      </Align>

      <Align alignement={[0, 1, 1]}>
        <Box position={[-0.0103, 0.3, 2.7]} args={[0.12, height - 1.39, space + 0.6]} />
      </Align>

      <Align alignement={[0, 1, 1]}>
        <Box position={[-0.0104, -1.055, 2.7]} args={[0.12, 1.02, space + 0.6]} />
      </Align>
    </group>
  );
};

export default FACE_B;

import { FC, useContext, useEffect, useRef } from 'react';
import { OrbitControls as OrbitControlsType } from 'three-stdlib';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { ControlsContext } from '@/components/Utils/GlobalContext';
import { Camera } from 'three';
import { useThree } from '@react-three/fiber';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

const SceneControls: FC = () => {
  const orbitControlsRef = useRef<OrbitControlsType>(null!);
  const cameraRef = useRef<Camera>(null!);
  const controlsContext = useContext(ControlsContext);
  const { isDimensionsVisible } = useSelector((state: RootState) => state.misc);

  const regress = useThree((state) => state.performance.regress);
  useEffect(() => {
    orbitControlsRef.current?.addEventListener('change', regress);
  });

  useEffect(() => {
    controlsContext.controls = orbitControlsRef;
    controlsContext.savedCamera = {
      position: cameraRef.current.position.clone(),
      rotation: cameraRef.current.rotation.clone(),
      target: orbitControlsRef.current.target.clone(),
    };
    controlsContext.resetCamera = () => {
      const { position, rotation, target } = controlsContext.savedCamera;
      cameraRef.current.position.set(position.x, position.y, position.z);
      cameraRef.current.rotation.set(rotation.x, rotation.y, rotation.z);
      orbitControlsRef.current.target.set(target.x, target.y, target.z);
      orbitControlsRef.current.update();
    };
  }, [controlsContext]);

  useEffect(() => {
    cameraRef.current.position.set(1.5, 5, 12);
    cameraRef.current.rotation.set(0, 0, 0);
  }, [isDimensionsVisible]);

  return (
    <>
      <PerspectiveCamera ref={cameraRef} makeDefault position={[-5, 0, 5]} />
      <OrbitControls
        ref={orbitControlsRef}
        minDistance={8}
        maxDistance={30}
        enableZoom={true}
        target={[4, 3.5, -3]}
        dampingFactor={0.15}
        maxPolarAngle={Math.PI / 2 - 0.05}
        screenSpacePanning={true}
      />
    </>
  );
};

export default SceneControls;

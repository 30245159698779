import { FC, useState } from 'react';
import { Input } from '@/components/Base/Input';
import * as S from '@/components';
import { Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import Popin from '@/components/Popin';
import YupPassword from 'yup-password';
import * as yup from 'yup';
import { setToken } from '@/store/user.slice';
import { useDispatch } from 'react-redux';
import {useApi} from '@/hooks';
const cookies = require('js-cookie');

YupPassword(yup);

const SignIn: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signIn } = useApi();

  return (
    <Popin
      isShowing={isVisible}
      hide={() => setIsVisible(false)}
      onHide={() => navigate('/configurator')}
    >
      <div className={'flex flex-col gap-y-lg px-6 pb-6 pt-10'}>
        <div className={'flex items-center justify-center gap-x-md'}>
          <S.Heading Tag={'h3'} color={'secondary'} size={'xl'} weight={'bold'}>
            Sign In
          </S.Heading>
        </div>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(e, actions) => {
            signIn(e)
              .then((res) => {
                dispatch(setToken(res.data.token));
                cookies.set('token', res.data.token);
                navigate('/configurator');
              })
              .catch(() => {
                actions.setErrors({
                  email: 'Invalid credentials',
                  password: 'Invalid credentials',
                });
              });
          }}
          validationSchema={yup.object().shape({
            email: yup.string().email().required(),
            password: yup.string().password().required(),
          })}
          validateOnChange={false}
        >
          {({ values, handleChange, submitForm, errors  }: any) => (
            <>
              <div className={'flex flex-col gap-y-1'}>
                <Input
                  name={'email'}
                  value={values.email}
                  onChange={handleChange}
                  errors={errors.email}
                  placeholder={'email@example.com'}
                />
                <div className={'text-red-600'}>{errors.email}</div>
              </div>
              <div className={'flex flex-col gap-y-1'}>
                <Input
                  name={'password'}
                  type={'password'}
                  value={values.password}
                  onChange={handleChange}
                  errors={errors.password}
                  placeholder={'●●●●●●●●'}
                />
                <div className={'text-red-600'}>{errors.password}</div>
              </div>
              <div className={'flex justify-center'}>
                <S.Button variant={'primary'} content={'Submit'} fill={true} onClick={submitForm} />
              </div>
            </>
          )}
        </Formik>
        <div className={'flex justify-center gap-x-xs'}>
          <S.Text>Don't have an account ?</S.Text>
          <Link to={'/configurator/sign-up'}>
            <S.Text color={'secondary'}>Sign Up</S.Text>
          </Link>
        </div>
      </div>
    </Popin>
  );
};

export default SignIn;

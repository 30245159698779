import { useEffect, FC, useState, ReactElement } from 'react';
import { useFrame, useThree } from '@react-three/fiber';

interface Props {
  children: ReactElement;
}

const XR3F: FC<Props> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [renderScene, setRenderScene] = useState<boolean>(false);
  const { XR8 } = window as any;
  const { scene, gl, camera } = useThree();

  useFrame(({ gl, scene, camera }) => {
    gl.clearDepth();
    gl.render(scene, camera);
  }, 1);

  useEffect(() => {
    const onStart = () => {
      // camera.position.setY(3);

      XR8.XrController.updateCameraProjectionMatrix({
        origin: camera.position,
        facing: camera.quaternion,
      });

      setRenderScene(true);
    };

    const onUpdate = ({ processCpuResult }: any) => {
      const realitySource = processCpuResult.reality;

      if (!realitySource) {
        return;
      }

      const { rotation, position, intrinsics } = realitySource;

      for (let i = 0; i < 16; i++) {
        camera.projectionMatrix.elements[i] = intrinsics[i];
      }

      if (camera.projectionMatrixInverse) {
        camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
      }

      if (rotation) {
        camera.setRotationFromQuaternion(rotation);
      }
      if (position) {
        camera.position.set(position.x, position.y, position.z);
      }
    };

    if (!isLoaded) {
      XR8.addCameraPipelineModule({
        name: 'three-scene',
        onStart,
        onUpdate,
        xrScene: {
          scene,
          renderer: gl,
          camera,
        },
      });
      setIsLoaded(true);
    }
  }, [scene, gl, camera, isLoaded, XR8]);

  return <>{renderScene && children}</>;
};

export default XR3F;

import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { FC } from 'react';

const ProjectButton: FC<JSX.IntrinsicElements['button']> = ({ ...attrs }) => {
  const projects = useSelector((state: RootState) => state.user.projects);

  return (
    <button
      className={
        'flex h-[40px] items-center gap-x-sm bg-primary-base pl-sm pr-lg font-medium uppercase text-white'
      }
      {...attrs}
    >
      <div className={'flex items-center bg-white px-[10px] py-1 text-primary-base'}>
        <span className={'baseline-fix'}>{projects.length}</span>
      </div>
      <span className={'baseline-fix'}>My projects</span>
    </button>
  );
};

export default ProjectButton;

import Module from '@/components/Sidebar/Modules/Module';
import { RadioOption } from '@/components/Sidebar/Modules/Options';
import { FC, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { updateConfig } from '@/store/config.slice';

const ProductsModule: FC = () => {
  const dispatch = useDispatch();

  const options = [
    {
      name: 'small',
      value: '114.3',
      description: 'Available pitch 114.3mm',
      image: '/assets/images/configurator/products/small.png',
      color: '#1B2140',
    },
    {
      name: 'medium',
      value: '152.4',
      description: 'Available pitch 152.4mm',
      image: '/assets/images/configurator/products/medium.png',
      color: '#1B2140',
    },
    {
      name: 'big',
      value: '226.6',
      description: 'Available pitch 228.6mm',
      image: '/assets/images/configurator/products/big.png',
      color: '#1B2140',
    },
  ];

  const [isValidated] = useState(false);

  const config = useSelector((state: RootState) => state.config);

  return (
    <Module
      name={'Your products'}
      validated={isValidated}
      options={
        <div className={'flex gap-x-sm'}>
          {options.map((option, index) => (
            <Fragment key={index}>
              <RadioOption
                onClick={() => dispatch(updateConfig({ ...config, products: option.value }))}
                selected={config.products === option.value}
                name={option.name}
                description={option.description}
                color={option.color}
                image={option.image}
              />
            </Fragment>
          ))}
        </div>
      }
    />
  );
};

export default ProductsModule;

import { FC, useState } from 'react';
import { InputCode } from '@/components/Base/Input';
import Icon from '@/components/Base/Icon';
import Popin from '@/components/Popin/Popin';
import { useNavigate } from 'react-router-dom';
import {useApi} from '@/hooks';
import {useLoadProject} from '@/hooks';
import * as S from '@/components';

const OpenProject: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const [code, setCode] = useState<string>('');
  const { getProjectByCode } = useApi();
  const { loadProject } = useLoadProject();

  return (
    <Popin
      width={400}
      isShowing={isVisible}
      hide={() => setIsVisible(false)}
      onHide={() => navigate('/configurator')}
    >
      <div className={'mt-xl flex flex-col items-center px-[80px] py-[70px]'}>
        <div className={'mb-lg flex gap-x-md text-md font-medium uppercase'}>
          <Icon name={'folder'} size={24} />
          <S.Heading Tag={'h2'} size={'lg'} weight={'bold'}>
            Open my project
          </S.Heading>
        </div>

        <div className={'mb-lg'}>
          <S.Text align={'center'}>
            Please enter the code you received by email to find your last project
          </S.Text>
        </div>

        <InputCode onChange={(e) => setCode(e.target.value)} />

        <div className={'flex items-center gap-x-md'}>
          <S.Button
            fill={true}
            variant={'primary'}
            content={'Open'}
            onClick={() =>
              getProjectByCode({ code }).then((res) => {
                loadProject(res.data);
                setIsVisible(false);
              })
            }
          />
          <S.Text>or</S.Text>
          <S.Button
            fill={true}
            variant={'primary'}
            content={'Sign in'}
            onClick={() => navigate('/configurator/sign-in')}
          />
        </div>
      </div>
    </Popin>
  );
};

export default OpenProject;

import { ProjectButton } from '@/components/Base/Button';
import * as S from '@/components';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

const Navbar: FC = () => {
  const navigate = useNavigate();
  const {token, demo} = useSelector((state: RootState) => state.user);
  const Cookies = require('js-cookie');

  return (
    <>
      <div
        className={
          'hidden h-[92px] w-full items-center justify-between bg-white px-xl shadow-base lg:flex'
        }
      >
        <div className={'flex gap-x-2xl'}>
          <img className={'h-[54px] w-[135px]'} src={'/assets/images/logo.svg'} alt={'Mecatherm'} />
          <span className={'h-[54px] w-px bg-gray-base'} />
          <div className={'flex flex-col justify-center'}>
            <S.Heading Tag={'h2'} weight={'light'}>
              Configurator
            </S.Heading>
            <div className={'flex gap-x-md'}>
              <S.Heading
                Tag={'h2'}
                uppercase={false}
                size={'xl'}
                color={'secondary'}
                weight={'bold'}
              >
                M-UB
              </S.Heading>
              <S.Heading Tag={'h2'} uppercase={false} size={'xl'}>
                Continuous handling system
              </S.Heading>
            </div>
          </div>
        </div>
        {!demo && (
          <div className={'flex gap-x-lg'}>
            {token ? (
              <>
                <S.Button
                  variant={'gray'}
                  fill={true}
                  content={'logout'}
                  onClick={() => {
                    Cookies.set('token', '');
                    window.location.reload();
                  }}
                />
                <ProjectButton onClick={() => navigate('/configurator/project-list')} />
              </>
            ) : (
              <>
                <S.Button
                  content={'Sign Up'}
                  variant={'primary'}
                  border={true}
                  onClick={() => navigate('/configurator/sign-up')}
                />
                <S.Button
                  content={'Open a project'}
                  variant={'primary'}
                  border={true}
                  onClick={() => navigate('/configurator/open-project')}
                />
              </>
            )}
            <S.Button
              content={'Save my project'}
              icon={'save'}
              variant={'secondary'}
              border={true}
              split={true}
              onClick={() => navigate(`/configurator/${token ? 'save-project' : 'sign-in'}`)}
            />
          </div>
        )}
     </div>
    </>
  );
};

export default Navbar;

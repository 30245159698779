import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  token: string | null;
  demo: boolean;
  projects: any;
  currentProjectId: string | null;
  currentProjectCode: string | null;
}

const initialState: InitialState = {
  token: '',
  demo: false,
  projects: [],
  currentProjectId: null,
  currentProjectCode: null,
};

const miscSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsDemo: (state, action) => {
      state.demo = action.payload;
    },
    setCurrentProjectId: (state, action) => {
      state.currentProjectId = action.payload;
    },
    setCurrentProjectCode: (state, action) => {
      state.currentProjectCode = action.payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
  },
});

export const { setToken, setProjects, setCurrentProjectId, setCurrentProjectCode, setIsDemo } = miscSlice.actions;

export default miscSlice;

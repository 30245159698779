import { cloneElement, FC, Fragment, ReactElement, useEffect, useRef, useState } from 'react';
import { motion, PanInfo, useAnimationControls, useDragControls } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { setIsSidebarOpen } from '@/store/ui.slice';
import Icon from '@/components/Base/Icon';
import Prompt from '@/components/Sidebar/Prompt';

interface Props {
  modules: ReactElement[];
}

const SidebarMobile: FC<Props> = ({ modules }) => {
  const dispatch = useDispatch();

  const paginationRef = useRef<HTMLDivElement>(null!);

  const isSidebarOpen = useSelector((state: RootState) => state.ui.isSidebarOpen);

  const isLoading = useSelector((state: RootState) => state.ui.isLoading);
  const { demo, token } = useSelector((state: RootState) => state.user);

  const controls = useAnimationControls();
  const dragControls = useDragControls();

  const [isPortrait, setIsPortrait] = useState<boolean>(false);

  const handleResize = () => {
    if (window.innerHeight > window.innerWidth) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }
  };

  useEffect(() => {
    if (!isSidebarOpen) {
      controls.start(isPortrait ? { y: '100%', x: 0 } : { x: '-100%', y: 0 });
    } else {
      controls.start(isPortrait ? { y: '0%', x: 0 } : { x: '0', y: 0 });
    }

    handleResize();
    window.addEventListener('resize', handleResize, true);

    return () => {
      window.removeEventListener('resize', handleResize, true);
    };
  });

  const startDrag = (event: any) => {
    dragControls.start(event);
  };

  const onDragEnd = (_: PointerEvent, info: PanInfo) => {
    if (isPortrait) {
      if (info.velocity.y > 200) {
        dispatch(setIsSidebarOpen(false));
        controls.start({ y: '100%' });
      } else {
        controls.start({ y: 0 });
      }
    } else {
      if (info.velocity.x < -100) {
        dispatch(setIsSidebarOpen(false));
        controls.start({ x: '-100%' });
      } else {
        controls.start({ x: 0 });
      }
    }
  };

  return (
    <motion.div
      className={`
        touch-none pointer-events-auto relative block bg-white p-lg shadow-base lg:hidden
        ${!isLoading ? 'visible' : 'invisible'}
        ${!isPortrait ? 'h-screen w-1/2 min-w-[300px]' : ''}
      `}
      drag={isPortrait ? 'y' : 'x'}
      dragDirectionLock
      onDragEnd={onDragEnd}
      dragMomentum={false}
      dragControls={dragControls}
      transition={{
        y: { type: 'spring', mass: 0.5, stiffness: 500, damping: 50 },
        x: { type: 'spring', mass: 0.5, stiffness: 500, damping: 50 },
      }}
      dragConstraints={{ right: 0, top: 0 }}
      animate={controls}
			dragListener={false}
    >
			<div onPointerDown={startDrag} className={`bg-transparent touch-none absolute ${isPortrait ? 'inset-x-0 top-0 translate-y-1/2 h-[60px]' : 'inset-y-0 right-0 translate-x-1/2 w-[60px]'}`}></div>
      <div className={!isPortrait ? 'hidden' : ''}>
        <Swiper
          preventClicksPropagation={true}
          touchMoveStopPropagation={true}
          spaceBetween={50}
          modules={[Pagination]}
          pagination={{
            el: paginationRef.current,
            renderBullet: (_, className) => {
              return `<span class="${className}"></span>`;
            },
          }}
        >
          {modules.map((module, i) => (
            <SwiperSlide className={"!h-auto"} key={i}>{cloneElement(module)}</SwiperSlide>
          ))}
          {(!token?.length && !demo) && (
            <SwiperSlide className={"!h-auto"}>
              <div className={'flex h-full items-center'}>
                <Prompt />
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <div className={'mt-md flex justify-center gap-x-[10px]'} ref={paginationRef} />
        <div className={'absolute bottom-0 left-0 h-screen w-screen translate-y-full bg-white'} />
      </div>

      <div className={isPortrait ? 'hidden' : 'h-full overflow-y-auto pr-sm'}>
        <div className={'flex flex-col gap-y-xl pl-md'}>
          {modules.map((module, index) => (
            <Fragment key={index}>{cloneElement(module)}</Fragment>
          ))}
        </div>
        <div className={'h-[60px]'} />

        {(!token || demo) && (
					<div className={'sticky bottom-0 left-0 right-0 z-[300]'}>
						<Prompt />
					</div>
        )}

        <div className={'absolute inset-0 -translate-x-full bg-white'} />
      </div>

      <div
        onClick={() => dispatch(setIsSidebarOpen(false))}
        className={`
            absolute top-0 right-0 h-[48px] w-[48px] items-center justify-center bg-gray-darker text-white
            ${!isPortrait ? 'translate-x-full' : '-translate-y-full'}
            ${isSidebarOpen ? 'flex' : 'hidden'}
          `}
      >
        <Icon name={'close'} size={32} />
      </div>
    </motion.div>
  );
};

export default SidebarMobile;

import { Stats } from '@react-three/drei';
import { FC, useEffect, useState } from 'react';

const Performance: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const checkShortcut = (e: KeyboardEvent) => {
    if (e.key === 'P' && e.shiftKey) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', checkShortcut);
    return () => {
      document.removeEventListener('keypress', checkShortcut);
    };
  });

  return <>{isOpen && <Stats />}</>;
};

export default Performance;

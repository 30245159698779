import { MathUtils } from 'three';
import { Align, Element } from '@/components/Webgl/Utils/';
import { Box } from '@react-three/drei';
import { useContext } from 'react';
import { EtuveContext } from '@/components/Webgl/Showcase/Model/ETUVE/ETUVE';

const FACE_B = () => {
  const { height, width, length } = useContext(EtuveContext);

  return (
    <group position={[-0.845, 2, -2.5 - width]}>
      <Element
        url={'/MUB-E/ETUVE/LOGO'}
        position={[0, height + 0.1, 0.42]}
        rotation={[0, MathUtils.degToRad(90), 0]}
      />

      <Align alignement={[1, 1, 0]}>
        <Box position={[-1.397, -1.95, 0.5]} args={[length - 0.017, height + 2.81, 0.1]} />
      </Align>

      <Align alignement={[1, 1, 0]}>
        <Box position={[length - 1.414, 0.797, 0.5]} args={[1.28, height + 0.063, 0.1]} />
      </Align>

      <Element
        position={[length - 0.742, -0.774, 0.5]}
        rotation={[0, MathUtils.degToRad(-90), 0]}
        scale={[-1, 1, 1]}
        url={'/MUB-E/ETUVE/DOOR'}
      />
    </group>
  );
};

export default FACE_B;

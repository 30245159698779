import { Align } from '@/components/Webgl/Utils/';
import { Box } from '@react-three/drei';
import { useContext } from 'react';
import { EtuveContext } from '@/components/Webgl/Showcase/Model/ETUVE/ETUVE';

const TOP = () => {
  const { height, width, length } = useContext(EtuveContext);

  return (
    <Align alignement={[1, 1, -1]}>
      <Box position={[-2.15, height + 2.76, 1.357]} args={[length + 1.2, 0.1, 3.32 + width]} />
    </Align>
  );
};

export default TOP;

import { RootState } from '@/store';
import { useSelector } from 'react-redux';
import {traysAmount} from '@/config';

const useGetTraysAmount = (): number => {
  const { products, mubAmount, mubHeight } = useSelector((state: RootState) => state.config);
  return traysAmount[products] * (mubHeight * 2 + 4) * mubAmount;
};

const useGetTraysSurface = () => {
  const { products, mubAmount, mubHeight, trays } = useSelector((state: RootState) => state.config);
  return (
    traysAmount[products] *
    (mubHeight * 2 + 4) *
    mubAmount *
    ((trays.x / 1000) * (trays.y / 1000))
  ).toFixed(2);
};

export { useGetTraysSurface, useGetTraysAmount };

import { FC } from 'react';

interface Props {
  unit: string;
  small?: boolean;
  autoscaling?: boolean;
  customClassName?: string;
  hasError?: boolean;
}

const InputUnit: FC<Props & JSX.IntrinsicElements['input']> = ({
  unit,
  small = false,
  autoscaling = false,
  hasError = false,
  customClassName,
  ...attrs
}) => {
  return (
    <div
      style={{
        width: autoscaling
          ? `${attrs.value ? attrs.value.toString().length * 7 + (42 + unit.length * 10) : 7}px`
          : '100%',
      }}
      className={`relative min-w-[80px]
       ${hasError ? 'text-red-600 outline outline-[1px] outline-red-600' : ''}
       ${customClassName}`}
    >
      <input
        className={`
        flex-items-center w-full bg-transparent pl-4 font-light
        ${small ? 'h-[38px] text-base' : 'h-[50px] text-lg '}
      `}
        {...attrs}
        type={'number'}
      />
      <div className={'absolute top-1/2 right-4 -translate-y-1/2 font-bold'}>{unit}</div>
    </div>
  );
};

export default InputUnit;

import { FC, useContext, useMemo, useRef } from 'react';
import { Group, MathUtils } from 'three';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import { S } from '@/config';
import MUB from '@/components/Webgl/Showcase/Elements';

const TOP: FC = () => {
  const { space, height, products, width, length } = useContext(ModelContext);

  const { params, A, B, spacing } = useMemo(() => {
    const e = S as any;
    return {
      params: e[width][products].TOP.params,
      A: e[width][products].SIDES.A,
      B: e[width][products].SIDES.B,
      spacing: e[width].spacing,
    };
  }, [width, products]);

  const { TOP } = useMemo(() => MUB.S[`${width}-${products}`], [products, width]);

  const groupRef = useRef<Group>(null!);

  return (
    <group ref={groupRef} position={[0, height * 0.455 + 1, 0]}>
      <TOP.Instances limit={15} range={15}>
        {[...Array(length)].map((e, i) => (
          <group position={[-i * spacing, 0, 0]} key={i}>
            <group
              position={[params.pos1.x + A.x, params.pos1.y + A.y, params.pos1.z + space + A.z]}
            >
              <TOP.Model />
            </group>
          </group>
        ))}
      </TOP.Instances>
      <TOP.Instances limit={15} range={15}>
        {[...Array(length)].map((e, i) => (
          <group position={[-i * spacing, 0, 0]} key={i}>
            <group
              position={[params.pos2.x + B.x, params.pos2.y + B.y, params.pos2.z + B.z]}
              rotation={[0, MathUtils.degToRad(180), 0]}
            >
              <TOP.Model />
            </group>
          </group>
        ))}
      </TOP.Instances>
    </group>
  );
};

export default TOP;

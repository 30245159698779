import { useContextBridge } from '@react-three/drei';
import { ReactReduxContext, useSelector } from 'react-redux';
import { FC, Suspense, useEffect, useRef } from 'react';
import { RootState } from '@/store';
import { Outlet } from 'react-router-dom';
import { useApi } from '@/hooks';

import Showcase from '@/components/Webgl/Showcase/Showcase';
import Layout from '@/components/Layout';
import Sidebar from '@/components/Sidebar';
import ShowcaseOverlay from '@/components/ShowcaseOverlay';
import Loader from '@/components/ShowcaseOverlay/Loader';
import { ControlsContext } from '@/components/Utils';
import { Canvas } from '@react-three/fiber';
import { useLoadConfigFromUrlQuery } from '@/hooks/useLoadConfigFromUrlQuery'

const Configurator: FC = () => {
  if (document.querySelector('#qrcode')) {
    window.location.reload();
  }
  const { fetchProjects, loginFromCookies } = useApi();

  const ContextBridge = useContextBridge(ControlsContext, ReactReduxContext);
  const token = useSelector((state: RootState) => state.user.token);

  const sidebarRef = useRef<HTMLDivElement>(null!);
  const showcaseRef = useRef<HTMLDivElement>(null!);
  const canvasRef = useRef<HTMLCanvasElement>(null!);

  useLoadConfigFromUrlQuery();
  const updateShowcaseSize = () => {
    if ((window as Window).innerWidth >= 1024) {
      showcaseRef.current.style.width = `${
        (window as Window).innerWidth - sidebarRef.current.getBoundingClientRect().width
      }px`;
    } else {
      showcaseRef.current.style.width = '100%';
    }
    (document.querySelector('.layout') as HTMLDivElement).style.height = `${window.innerHeight}px`;
  };

  useEffect(() => {
    window.addEventListener('resize', updateShowcaseSize);
    updateShowcaseSize();
    fetchProjects();
    if (!token) {
      loginFromCookies();
    }

    return () => {
      window.removeEventListener('resize', updateShowcaseSize);
    };
  });

  const isFullscreen = useSelector((state: RootState) => state.ui.isFullscreen);

  return (
    <Layout>
      <div className={'relative z-0 flex h-full flex-col-reverse lg:flex-row'}>
        <div
          ref={sidebarRef}
          className={
            'pointer-events-none absolute bottom-0 left-0 z-20 w-full lg:relative lg:h-auto lg:w-[520px]'
          }
        >
          <Sidebar />
        </div>

        <div
          ref={showcaseRef}
          className={`relative h-full bg-gradient-to-r from-[#FEFEFE] to-[#E8E8E8] lg:h-auto ${
            isFullscreen ? '!fixed !inset-0 !z-[9999] !h-screen !w-full' : ''
          }`}
        >
          <Suspense fallback={<Loader />}>
            <div className={'pointer-events-none absolute inset-0 z-10'}>
              <ShowcaseOverlay />
            </div>
            <div className={'absolute inset-0'}>
              <Canvas
                data-canvas
                gl={{ preserveDrawingBuffer: true }}
                ref={canvasRef}
                shadows={false}
              >
                <ContextBridge>
                  <Showcase />
                </ContextBridge>
              </Canvas>
            </div>
          </Suspense>
        </div>
      </div>

      <Outlet />
    </Layout>
  );
};

export default Configurator;

import { Plane } from '@react-three/drei';
import { MathUtils, Mesh, Raycaster, ShadowMaterial, Vector2 } from 'three';
import { FC, useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { setIsModelRendered } from '@/store/ar.slice';

const PrePosition: FC = () => {
  const { camera, scene } = useThree();
  const dispatch = useDispatch();

  const boxRef = useRef<Mesh>(null!);
  const surfaceRef = useRef<any>(null!);

  const shadow = new ShadowMaterial({ opacity: 0.5 });
  const cameraRaycaster = new Raycaster();
  const vector = new Vector2();

  const { isModelRendered } = useSelector((state: RootState) => state.ar);

  useEffect(() => {
    const model = scene.getObjectByName('model');
    if (model) {
      model.visible = isModelRendered;
    }
  }, [isModelRendered, scene]);

  useFrame(() => {
    cameraRaycaster.setFromCamera(vector, camera);
    const intersect = cameraRaycaster.intersectObject(surfaceRef.current);

    if (intersect?.length) {
      boxRef.current.position.x = intersect[0].point.x;
      boxRef.current.position.z = intersect[0].point.z;
      boxRef.current.position.y = 0.1;
    }
  });

  return (
    <>
      <Plane
        ref={surfaceRef}
        args={[100, 100]}
        position={[0, 0, 0]}
        receiveShadow={true}
        rotation={[MathUtils.degToRad(-90), 0, 0]}
        material={shadow}
      />
      <mesh
        rotation={[MathUtils.degToRad(-90), 0, 0]}
        onClick={() => {
          const model = scene.getObjectByName('ar-model');
          model!.position.x = boxRef.current.position.x;
          model!.position.z = boxRef.current.position.z;
          dispatch(setIsModelRendered(true));
        }}
        ref={boxRef}
      >
        <planeGeometry args={[1, 1]} />
        <meshStandardMaterial transparent={true} depthWrite={false} color={'gray'} opacity={0.7} />
      </mesh>
    </>
  );
};

export default PrePosition;

import { FC, ReactNode } from 'react';
import Icon from '@/components/Base/Icon';
import * as S from '@/components';

interface Props {
  name: string;
  validated: boolean;
  contextual?: ReactNode;
  options?: ReactNode;
}

const Module: FC<Props> = ({ name, validated, contextual, options }) => {
  return (
    <div className={'flex flex-col gap-y-md lg:gap-y-xl'}>
      <div className={'flex flex-col justify-between gap-y-md lg:flex-row'}>
        <div className={`flex items-center gap-x-sm`}>
          <S.Heading Tag={'h2'} weight={'light'} wide={true}>
            {name}
          </S.Heading>
          {validated && <Icon name={'check'} size={16} />}
        </div>
        {contextual}
      </div>
      {options}
    </div>
  );
};

export default Module;

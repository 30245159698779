import ReactDOM from 'react-dom';
import { FC, ReactElement } from 'react';
import { Fade } from '@/components/Utils/';

interface Props {
  isShowing: boolean;
  hide?: () => void;
  children: ReactElement;
  width?: number;
  onHide?: () => void;
  onShow?: () => void;
}

const Popin: FC<Props> = ({ isShowing, hide, onHide, onShow, width = 600, children }) => {
  const onFadeEnd = (e: any) => {
    if (e.name === 'exit' && onHide) {
      onHide();
    }
  };

  const onFadeStart = (e: any) => {
    if (e.name === 'start' && onShow) {
      onShow();
    }
  };

  return (
    <>
      {ReactDOM.createPortal(
        <div className={'fixed inset-0 z-[9999]'}>
          <Fade onEnd={onFadeEnd} onStart={onFadeStart} isVisible={isShowing}>
            <div className={'absolute inset-0 flex items-center justify-center bg-black/50'}>
              <div
                style={{ maxWidth: width }}
                className={'relative z-10 mx-md w-full rounded-md bg-white'}
              >
                <div onClick={hide} className={'absolute right-md top-md z-10 cursor-pointer'}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.616117 0.616117C1.10427 0.127961 1.89573 0.127961 2.38388 0.616117L9 7.23223L15.6161 0.616117C16.1043 0.127961 16.8957 0.127961 17.3839 0.616117C17.872 1.10427 17.872 1.89573 17.3839 2.38388L10.7678 9L17.3839 15.6161C17.872 16.1043 17.872 16.8957 17.3839 17.3839C16.8957 17.872 16.1043 17.872 15.6161 17.3839L9 10.7678L2.38388 17.3839C1.89573 17.872 1.10427 17.872 0.616117 17.3839C0.127961 16.8957 0.127961 16.1043 0.616117 15.6161L7.23223 9L0.616117 2.38388C0.127961 1.89573 0.127961 1.10427 0.616117 0.616117Z"
                      fill="#1B2140"
                    />
                  </svg>
                </div>

                {children}
              </div>

              <div className={'absolute inset-0 bg-transparent'} onClick={hide} />
            </div>
          </Fade>
        </div>,
        document.body,
      )}
    </>
  );
};

export default Popin;

import { R, E } from '@/config';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ModelContext } from '@/components/Webgl/Showcase/Model';
import {usePainter} from '@/hooks/beautify';
import { Align } from '@/components/Webgl/Utils';
import BRIDGE_EXT from './BRIDGE-EXT';
import { Box3, Group, Mesh, Vector3 } from 'three';
import GLASS from '@/components/Webgl/Showcase/Model/MUB-R/GLASS';
import { BOT, MID, TOP } from '@/components/Webgl/Showcase/Model/MUB-R';
import { setModelDimensions } from '@/store/misc.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useThree } from '@react-three/fiber';
import { RootState } from '@/store';
import ETUVE from '@/components/Webgl/Showcase/Model/ETUVE';

type Props = {
  enclosure?: boolean;
}

const MUB_R: FC<Props> = ({enclosure}) => {
  const dispatch = useDispatch();
  const modelContext = useContext(ModelContext);
  const { scene } = useThree();
  const { paint } = usePainter();
  const isARReady = useSelector((state: RootState) => state.misc.ARReady);

  const { products, width } = useMemo(() => modelContext, [modelContext]);

  const { offset, offsetProduct, compensation, eCompensation } = useMemo(() => {
    const r = R as any;
    const e = E as any;
    return {
      offset: r[width].offset,
      offsetProduct: r[width][products].offset,
      compensation: r[width][products].compensation,
      eCompensation: e[width][products].compensation,
    };
  }, [width, products]);

  const meshRef = useRef<Group>(null!);
  const mubRef = useRef(null!);

  useEffect(() => {
    paint(mubRef, [
      {
        name: [
          'Material_3.008',
          'Material_3.011',
          'Material_3',
          'Material_3.013',
          'Material_3.010',
          'Material_3.012',
          'Material_8.001',
          'Material_8.002',
          'Material_8.003',
        ],
        color: '#233449',
      },
      {
        name: [
          'Material_8',
          'Material_8.012',
          'Material_8.013',
          'Material_2.003',
          'Material_2.002',
          'Material_2.001',
        ],
        color: '#394043',
      },
      {
        name: 'all',
        options: {
          roughness: 0.1,
          metalness: 1,
        },
      },
    ]);
  });

  const [config, setConfig] = useState({
    width: 0,
    height: 0,
    length: 0,
  });

  useEffect(() => {
    const mub = scene.getObjectByName('mub')!;
    const boundsVolume = new Vector3();
    const volumeBoundingBox = new Box3().setFromObject(mub);
    volumeBoundingBox.getSize(boundsVolume);

    setConfig({
      length: boundsVolume.x + compensation.x,
      width: boundsVolume.z + compensation.z,
      height: boundsVolume.y + 1.4 + compensation.y,
    });
  }, [modelContext, compensation, scene]);

  useEffect(() => {
    (mubRef.current as any).traverse((child: any) => {
      if (child instanceof Mesh) {
        child.geometry.computeVertexNormals();
      }
    });
  });

  useEffect(() => {
    if (!enclosure) {
      const dimensions = new Vector3(config.length + 2.55, config.height - 1.1, config.width + 0.65);
      dispatch(setModelDimensions(dimensions));
    };
  }, [config, dispatch, enclosure]);

  const basePosition = useMemo(
    () => (!isARReady ? new Vector3(2.1, 3.56, -0.22) : new Vector3()),
    [isARReady],
  );

  return (
    <group ref={meshRef} position={basePosition}>

      {enclosure && (
        <group position={[0.15, -3.65, -1.25]}>
          <ETUVE bridge compensation={eCompensation} mub={mubRef} />
        </group>
      )}

      {(modelContext.height > 4 && !enclosure) && (
        <BRIDGE_EXT length={config.length} height={config.height} width={config.width} />
      )}

      {!enclosure && (
        <GLASS length={config.length} width={config.width} />
      )}

      <group ref={mubRef} position={[0, -3.65, -0.85]}>
        <Align alignement={[0, 0, -1]}>
          <group
            position={[
              offset.x + offsetProduct.x,
              1.4 + offset.y + offsetProduct.y,
              0.92 + offset.z + offsetProduct.z,
            ]}
            name={'mub'}
          >
            <TOP />
            <group position={[0, 1.143, 0]}>
              <MID />
            </group>
            <BOT />
          </group>
        </Align>
      </group>
    </group>
  );
};

export default MUB_R;

import { Clone, useGLTF } from '@react-three/drei';
import { ComponentProps, FC } from 'react';

interface Props {
  url: string;
}

const Element: FC<Props & Omit<ComponentProps<typeof Clone>, 'object'>> = ({ url, ...attrs }) => {
  const model = useGLTF(`/assets/models${url}.glb`);
  return <Clone object={model.scene} {...attrs} />;
};

export default Element;

import { FC } from 'react';
import { Align } from '@/components/Webgl/Utils';
import { Box } from '@react-three/drei';

interface Props {
  length: number;
  width: number;
}

const TOP: FC<Props> = ({ length, width }) => {
  return (
    <Align alignement={[1, 0, -1]}>
      <Box args={[length, 0.12, width]} />
    </Align>
  );
};

export default TOP;

import { createSlice } from '@reduxjs/toolkit';
import {traysAmount} from '@/config';

interface InitialState {
  hasChanged: boolean;
  mubAmount: number;
  mubHeight: number;
  lineSpeed: number;
  dwellTime: number;
  process: string;
  products: string;
  trays: {
    x: number;
    y: number;
  };
}

const initialState: InitialState = {
  hasChanged: false,
  mubAmount: 1,
  lineSpeed: 80,
  mubHeight: 4,
  dwellTime: 144,
  process: 'proofing',
  products: '114.3',
  trays: {
    x: 450,
    y: 800,
  },
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (_, action) => {
      return {
        ...action.payload,
        dwellTime: Math.floor(
          (traysAmount[action.payload.products] *
            (action.payload.mubHeight * 2 + 4) *
            action.payload.mubAmount *
            60) /
            action.payload.lineSpeed,
        ),
      };
    },
    setMubAmount: (state, action) => {
      state.dwellTime = Math.floor(
        (traysAmount[state.products] * (state.mubHeight * 2 + 4) * action.payload * 60) /
          state.lineSpeed,
      );
      state.mubAmount = action.payload;
    },
    setTrays: (state, action) => {
      state.trays = action.payload;
    },
    setMubHeight: (state, action) => {
      state.dwellTime = Math.floor(
        (traysAmount[state.products] * (action.payload * 2 + 4) * state.mubAmount * 60) /
          state.lineSpeed,
      );
      state.mubHeight = action.payload;
    },
    setLineSpeed: (state, action) => {
      state.dwellTime = Math.floor(
        (traysAmount[state.products] * (state.mubHeight * 2 + 4) * state.mubAmount * 60) /
          action.payload,
      );
      state.lineSpeed = action.payload;
    },
    setDwellTime: (state, action) => {
      state.dwellTime = action.payload;
    },
    setHasChanged: (state, action) => {
      state.hasChanged = action.payload;
    },
    updateConfig: (_, action) => {
      return {
        ...action.payload,
        dwellTime: Math.floor(
          (traysAmount[action.payload.products] *
            (action.payload.mubHeight * 2 + 4) *
            action.payload.mubAmount *
            60) /
            action.payload.lineSpeed,
        ),
      };
    },
  },
});

export const {
  setHasChanged,
  setMubAmount,
  setMubHeight,
  setLineSpeed,
  setDwellTime,
  updateConfig,
  setConfig,
  setTrays,
} = configSlice.actions;

export default configSlice;

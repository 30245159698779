import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import axios from 'axios';
import { setProjects, setToken } from '@/store/user.slice';
const Cookies = require('js-cookie');

const useApi = () => {
  const token = useSelector((state: RootState) => state.user.token);

  const dispatch = useDispatch();

  const fetchProjects = () => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}setup`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(setProjects(res.data));
        })
        .catch((res) => {
          Cookies.set('token', '');
        });
    }
  };

  const signIn = ({ email, password }: any) =>
    axios.post(`${process.env.REACT_APP_API_URL}login_check`, {
      username: email,
      password: password,
    });

  const register = ({ email, token, password, passwordRepeat }: any) =>
    axios.post(`${process.env.REACT_APP_API_URL}register`, {
      email: email,
      token: token,
      password: password,
      passwordConfirmation: passwordRepeat,
    });

  const signUp = ({ email }: any) =>
    axios.post(`${process.env.REACT_APP_API_URL}subscription`, {
      email,
    });

  const loginFromCookies = () => {
    if (Cookies.get('token')) {
      dispatch(setToken(Cookies.get('token')));
      fetchProjects();
    }
  };

  const createProject = ({ description, parameters }: any) =>
    axios.post(
      `${process.env.REACT_APP_API_URL}setup`,
      {
        description,
        parameter: parameters,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

  const saveProject = ({ description, id, parameters }: any) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}setup/${id}`,
        {
          description,
          parameter: parameters,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => fetchProjects());
  };

  const deleteProject = ({ id }: any) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}setup/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => fetchProjects());
  };

  const getMe = () =>
    axios.get(`${process.env.REACT_APP_API_URL}me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  const getProjectByCode = async ({ code }: any) =>
    await axios.get(`${process.env.REACT_APP_API_URL}setup/code/${code}`);

  return {
    fetchProjects,
    signIn,
    register,
    signUp,
    loginFromCookies,
    createProject,
    deleteProject,
    getProjectByCode,
    saveProject,
    getMe,
  };
};

export default useApi;

import { FC, useState } from 'react';
import * as S from '@/components';
import { useNavigate } from 'react-router-dom';
import Popin from '@/components/Popin';
import { ProjectButton } from '@/components/Base/Button';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

const SignUp: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.user.token);
  const Cookies = require('js-cookie');

  return (
    <Popin
      width={300}
      isShowing={isVisible}
      hide={() => setIsVisible(false)}
      onHide={() => navigate('/configurator')}
    >
      <div className={`flex flex-col items-center gap-y-lg px-6 pb-6 pt-10 `}>
        <div className={'flex flex-col gap-y-sm'}>
          {token ? (
            <>
              <S.Button
                variant={'gray'}
                full={true}
                fill={true}
                content={'logout'}
                onClick={() => {
                  Cookies.set('token', '');
                  window.location.reload();
                }}
              />
              <ProjectButton onClick={() => navigate('/configurator/project-list')} />
            </>
          ) : (
            <>
              <S.Button
                content={'Sign Up'}
                full={true}
                variant={'primary'}
                border={true}
                onClick={() => navigate('/configurator/sign-up')}
              />
              <S.Button
                content={'Open a project'}
                full={true}
                variant={'primary'}
                border={true}
                onClick={() => navigate('/configurator/open-project')}
              />
            </>
          )}
          <S.Button
            content={'Save my project'}
            full={true}
            icon={'save'}
            variant={'secondary'}
            border={true}
            split={true}
            onClick={() => navigate(`/configurator/${token ? 'save-project' : 'sign-in'}`)}
          />
        </div>
      </div>
    </Popin>
  );
};

export default SignUp;
